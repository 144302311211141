import React from 'react';
import FilterRadio from './filterRadio';
import './filterItems.scss';
import { FormGroup, Grid } from '@material-ui/core';
function IntermediatRadio(props) {
  const setRadioDefault = (value, filterID) => {
    let defaultChecked;

    let tempHolder = props.queryFilter.find((x) => x.id === filterID);

    if (tempHolder) {
      defaultChecked = false;

      let arrRes = tempHolder.alternatives.find(
        (x) => x.toString() === value.toString()
      );
      if (arrRes === value.toString()) {
        defaultChecked = true;
      } else {
        defaultChecked = false;
      }
    } else {
      defaultChecked = false;
    }
    return defaultChecked;
  };

  return (
    <React.Fragment key={props.i}>
      <h1 className="header-fix">{props.filter.name}</h1>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        {props.filter.filter_info}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} className="checkBox-filter">
          <div style={{ margin: '1em auto auto auto' }}>
            <FormGroup row>
              {props.filter.alternatives.map((value, j) => (
                <FilterRadio
                  key={j}
                  filter={props.filter}
                  value={value}
                  default={setRadioDefault(value, props.filter.id)}
                  onChange={props.checkboxRadioChange}
                  queryFilter={props.queryFilter}
                  handleFilterRadioCheckboxValueChanges={
                    props.handleFilterRadioCheckboxValueChanges
                  }
                />
              ))}
            </FormGroup>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={11}></Grid>
        <Grid item xs={1}></Grid>
        <>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} key={props.filter.category_id + 123}>
            {props.filter.help_text}
          </Grid>
          <Grid item xs={1}></Grid>
        </>
      </Grid>
    </React.Fragment>
  );
}

export default IntermediatRadio;
