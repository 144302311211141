import React from 'react';
import '../pages/indexPage/main-container.scss';
import FiltersDisplay from './filtersDisplay';
import WithLoading from './withLoading';
import FilterTagDisplay from './filterTagDisplay';
import WhiteShell from './containers/whiteshell';
import SearchField from './searchField';
import debounce from 'lodash/debounce';
import * as filterService from '../services/FilterService';
import * as utilityClass from '../utilityClass';

import CSSTransition from 'react-transition-group/CSSTransition';

const FiltersWithLoading = WithLoading(FiltersDisplay);

class MainFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtericons: [],
      filtersLoading: true,
      dropdownBox: false,
      showFilterValues: false,
      filtersForQuery: this.props.filtersForQuery,
      activeFilterCategories: this.props.activeFilterCategories,
      clickedCategory: [],
      showSearchFilters: this.props.filterCategories,
      activeBrand: this.props.activeBrand ? this.props.activeBrand : [],
      filterCategories: [],
    };
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProp) {
    if (this.props.filtersForQuery !== prevProp.filtersForQuery) {
      this.setState({
        filtersForQuery: this.props.filtersForQuery,
      });
    }
    if (this.props.activeBrand !== prevProp.activeBrand) {
      this.setState({
        activeBrand: this.props.activeBrand,
      });
    }
  }

  async getData() {
    await this.getFilters();
  }

  getFilters = async () => {
    let filterCategories = [];
    let filters = await filterService.getFilters();
    filterCategories = await this.getFiltersPerCategory(filters);
    let sortedFilterCategories = [];
    if (Array.isArray(filterCategories)) {
      sortedFilterCategories = utilityClass.sortByMostPopular(filterCategories);
    }
    this.setState({
      showSearchFilters: filterCategories,
      filtersLoading: false,
      filterCategories: filterCategories,
    });
    if (this.state.activeFilterCategories.length === 0) {
      this.setState({
        activeFilterCategories: sortedFilterCategories.slice(0, 5),
      });
    }

    return filters;
  };

  getFiltersPerCategory = async (filters) => {
    let filterCategories = [];
    filters.forEach((filter) => {
      //Checks if cat name contains hidden of so not include it (Temp fix until can be done in DB call)
      if (
        filterCategories[filter.category_id - 1] === undefined &&
        !filter.category_name.includes('hidden')
      ) {
        filterCategories[filter.category_id - 1] = {};
        filterCategories[filter.category_id - 1].category_id =
          filter.category_id;
        filterCategories[filter.category_id - 1].category_name =
          filter.category_name;
        filterCategories[filter.category_id - 1].total_times_selected =
          filter.total_times_selected;
        filterCategories[filter.category_id - 1].icon_name = filter.icon_name;
        if (!filterCategories[filter.category_id - 1].filters) {
          filterCategories[filter.category_id - 1].filters = [];
        }
      }
      filter.alternatives = filter.alternatives.split(';');
      //Checks if cat name contains hidden of so not include it (Temp fix until can be done in DB call)
      if (!filter.category_name.includes('hidden')) {
        filterCategories[filter.category_id - 1].filters.push(filter);
      }
    });
    return filterCategories;
  };

  handleSearchChange = (characters) => {
    let searchFilter = this.state.filterCategories.filter(
      (filterCategory) =>
        filterCategory.category_name
          .toLowerCase()
          .indexOf(characters.toLowerCase()) > -1
    );
    this.setState({
      showSearchFilters: searchFilter,
    });
  };

  handleKillClick = (e, category) => {
    e.stopPropagation();

    const dataLayer = window.dataLayer || [];
    dataLayer.push({ 'event': 'kriterieRemoved', 'kriterie': category.category_name });

    if (category.category_id === this.state.clickedCategory.category_id) {
      this.removeActiveFilter(category.category_id);
      this.setState({ showFilterValues: false, clickedCategory: [] });
    } else {
      this.removeActiveFilter(category.category_id);
    }
    let ids = [];
    category.filters.forEach((filter) => ids.push(filter.id));
    this.RemovefiltersFromForQuery(ids);
  };

  RemovefiltersFromForQuery(ids) {
    let copyOfFiltersForQuery = [...this.state.filtersForQuery];

    for (let i = 0; i < ids.length; i++) {
      let tempFilter = copyOfFiltersForQuery.find((x) => x.id === ids[i]);

      let index = copyOfFiltersForQuery.findIndex((x) => x.id === ids[i]);
      if (tempFilter !== undefined) {
        if (index !== -1) {
          copyOfFiltersForQuery.splice(index, 1);
        }
      }
    }

    this.setState({ filtersForQuery: copyOfFiltersForQuery });
  }

  mappingIds(ids) { }

  handleAddFilterBoxClick = () => {
    this.setState(
      {
        dropdownBox: !this.state.dropdownBox,
      },
      () => {
        this.state.dropdownBox &&
          this.setState({ showSearchFilters: this.state.filterCategories });
      }
    );

    if (this.state.showFilterValues) {
      this.setState({ showFilterValues: false });
    }
  };

  // For the "indivdual filter" buttons(Needs "e" to function even when not used)
  handleClickFilterBox = (e, filterCategory) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ 'event': 'kriterieAdded', 'kriterie': filterCategory.category_name });

    if (this.state.dropdownBox) {
      this.setState({ dropdownBox: false });
    }

    if (this.state.clickedCategory.category_id === filterCategory.category_id) {
      this.setState({ showFilterValues: !this.state.showFilterValues });
    } else if (
      this.state.clickedCategory.category_id !== filterCategory.category_id
    ) {
      this.setState(
        { showFilterValues: false, clickedCategory: filterCategory },
        () => this.setState({ showFilterValues: true })
      );
    }
  };

  handleActiveBrands = (label, id, category_id) => {
    let res = this.state.activeBrand.filter((labelA) =>
      this.brandEquals(labelA, label)
    );
    if (res.length === 0) {
      this.setState({ activeBrand: [...this.state.activeBrand, label] }, () => {
        this.handleFilterRadioCheckboxValueChanges(
          id,
          category_id,
          this.state.activeBrand
        );
      });
    } else {
      let something = this.state.activeBrand.filter((x) => x !== label);
      this.setState({ activeBrand: something }, () => {
        this.handleFilterRadioCheckboxValueChanges(
          id,
          category_id,
          this.state.activeBrand
        );
      });
    }
  };

  brandEquals = (labelA, labelB) => {
    return labelA === labelB;
  };

  handleFilterSliderValueChanges = debounce(
    (e, filter_id, category_id, values) => {
      let tempCategoriesValues = Object.assign(
        {},
        this.state.filterCategories.find((x) => x.category_id === category_id)
      );

      let tempFilterValues = Object.assign(
        {},
        tempCategoriesValues.filters.find((x) => x.id === filter_id)
      );

      tempFilterValues.defaultMinMaxAlternatives =
        tempFilterValues.alternatives;
      tempFilterValues.alternatives = values;

      let exists = this.state.filtersForQuery.filter(
        (x) => x.id !== tempFilterValues.id
      );
      this.setState({ filtersForQuery: [...exists, tempFilterValues] }, () => {
        this.filtersForQueryCleaner(filter_id);
        this.props.handleActiveFiltersChanged(
          this.state.activeFilterCategories,
          this.state.filtersForQuery,
          this.state.activeBrand
        );
      });
    },
    20
  );

  handleFilterRadioCheckboxValueChanges = (filter_id, category_id, values) => {
    let tempCategoriesValues = Object.assign(
      {},
      this.state.filterCategories.find((x) => x.category_id === category_id)
    );

    let tempFilterValues = Object.assign(
      {},
      tempCategoriesValues.filters.find((x) => x.id === filter_id)
    );

    tempFilterValues.alternatives = values;

    let exists = this.state.filtersForQuery.filter(
      (x) => x.id !== tempFilterValues.id
    );
    this.setState({ filtersForQuery: [...exists, tempFilterValues] }, () => {
      this.filtersForQueryCleaner(filter_id);
      this.props.handleActiveFiltersChanged(
        this.state.activeFilterCategories,
        this.state.filtersForQuery,
        this.state.activeBrand
      );
    });
  };
  // To remove filters that have values that is nothing "" (empty array)
  filtersForQueryCleaner(id) {
    let copyOfFiltersForQuery = [...this.state.filtersForQuery];
    let tempFilter = copyOfFiltersForQuery.find((x) => x.id === id);

    let index = copyOfFiltersForQuery.findIndex((x) => x.id === id);
    if (tempFilter.alternatives.length === 0) {
      if (index !== -1) {
        copyOfFiltersForQuery.splice(index, 1);
        this.setState({ filtersForQuery: copyOfFiltersForQuery }, () => {
          this.props.handleActiveFiltersChanged(
            this.state.activeFilterCategories,
            this.state.filtersForQuery,
            this.state.activeBrand
          );
        });
      }
    }
  }

  filterEquals(categoryA, categoryB) {
    return categoryA.category_id === categoryB.category_id;
  }

  handleFilterTagClick = (categoryCriteria) => {
    let res = this.state.activeFilterCategories.filter((categoryA) =>
      this.filterEquals(categoryA, categoryCriteria)
    );
    if (res.length === 0) {
      this.setState(
        {
          activeFilterCategories: [
            ...this.state.activeFilterCategories,
            categoryCriteria,
          ],
        },
        () =>
          this.props.handleActiveFiltersChanged(
            this.state.activeFilterCategories,
            this.state.filtersForQuery,
            this.state.activeBrand
          )
      );
    } else {
      this.removeActiveFilter(res[0].category_id);
    }
    //Null sent since the function need e(in this case null) to function.
    this.handleClickFilterBox(null, categoryCriteria);
  };

  removeActiveFilter(category_id) {
    let filteredFilters = this.state.activeFilterCategories.filter(
      (x) => x.category_id !== category_id
    );
    this.setState({ activeFilterCategories: filteredFilters }, () =>
      this.props.handleActiveFiltersChanged(
        this.state.activeFilterCategories,
        this.state.filtersForQuery,
        this.state.activeBrand
      )
    );
  }

  render() {
    return (
      <div>
        {(!this.state.filtersLoading &&
          this.state.filterCategories.length === 0) ||
          (!this.state.filterCategories && (
            <div className="no-results">
              <p>Noe gikk feil last siden på nytt!</p>
            </div>
          ))}
        {
          <FiltersWithLoading
            isLoading={this.state.filtersLoading}
            filters={this.state.activeFilterCategories}
            filterCategories={this.state.activeFilterCategories}
            handleAddClick={this.handleAddFilterBoxClick}
            handleFilterClick={this.handleClickFilterBox}
            handleKillClick={this.handleKillClick}
            showFilterValues={this.state.showFilterValues}
            clickedCategory={this.state.clickedCategory}
            dropdownBox={this.state.dropdownBox}
            filtersForQuery={this.state.filtersForQuery}
            handleSliderValueChange={this.handleFilterSliderValueChanges}
            handleFilterRadioCheckboxValueChanges={
              this.handleFilterRadioCheckboxValueChanges
            }
            handleActiveBrands={this.handleActiveBrands}
            activeBrand={this.state.activeBrand}
          ></FiltersWithLoading>
        }
        <CSSTransition
          in={this.state.dropdownBox}
          timeout={300}
          classNames="boxWithAnimation"
          unmountOnExit
        >
          <div className="onboarding-box">
            <WhiteShell>
              <SearchField handleChange={this.handleSearchChange}></SearchField>
              <FilterTagDisplay
                showSearchFilters={this.state.showSearchFilters}
                activeFilterCategories={this.state.activeFilterCategories}
                handleClick={this.handleFilterTagClick}
              />
            </WhiteShell>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default MainFilterContainer;
