import React from 'react';
import './infoCategoryThumbnail.scss';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

function InfoCategoryThumbnail(props) {
  const isSelected = props.category.id === props.displayedCategoryId;
  return (
    <div
      className="box all-radius boop zoom-special"
      id="filterbox"
      tabIndex={0}
      onClick={() => {
        props.handleShowCategoryClick(props.category.id);

        if (isMobile) {
          window.scroll({
            top: 300,
            behavior: 'smooth'
          })
        }
      }}
      onKeyPress={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          props.handleShowCategoryClick(props.category.id);
        }
      }}
    >
      <div className="boxicons">
        <img
          src={'iconsResult/' + props.category.id + '.svg'}
          alt="icons"
          className="box-icon-result"
        />
        <div
          className={isSelected ? 'bolder-text ' : 'boxtitle-result botradius'}
        >
          {props.category.display_name}
        </div>
      </div>
      <div
        className={props.showArrow && isSelected ? 'chosen-arrow-down' : ''}
      ></div>
    </div>
  );
}

InfoCategoryThumbnail.propTypes = {
  category: PropTypes.object.isRequired,
};

export default InfoCategoryThumbnail;
