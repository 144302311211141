import React from 'react';
import CarDetails from './cardetails';
import * as carService from '../../services/CarService';
import WithLoading from '../../components/withLoading';
import DarkBlueContainer from '../../components/containers/DarkBlueContainer';
import ErrorMessage from '../../components/errorMessage';
import {
  getEquipmentData,
  getBatteryData,
  getTechnicalData,
} from '../../services/CarService';
class CarInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingMain: true,
      isLoadingTot: true,
      error: null,
      feedback: null,
      plusMinus: null,
      articles: null,
      tests: null,
      total_equipment: [],
      total_battery: [],
      total_technical: [],
      carNotFound: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const id = this.props.match.params.id;
    this.getData(id);
  }

  async getData(id) {
    try {
      let joinedData = await this.getAllJoinDataById(id);
      if (joinedData.status === 404 || joinedData.status === 400) {
        this.setState({ carNotFound: true });
        return;
      }
      await this.getSections(id);

      this.setState({ isLoadingMain: false });
    } catch (e) {
      console.log('Error: ', e);
      this.setState({ error: e });
      return e;
    }

    await this.getEquipment();
    await this.getBattery();
    await this.getTechnical();

    this.setState({ isLoadingTot: false });
  }

  getAllJoinDataById = async (id) => {
    let result = await carService.getAllJoinDataById(id);
    if (result instanceof Error) {
      this.setState({
        error: result,
      });
      return;
    }
    try {
      this.setState({
        carObjectSelected: result,
      });
      return result;
    } catch (error) {
      this.setState({
        error: error,
      });
    }
  };

  getSection = async (id, sectionName) => {
    let result = await carService.getSectionFromId(id, sectionName);

    if (result instanceof Error) {
      this.setState({
        error: result,
      });
      return;
    }
    return result;
  };

  getSections = async (id) => {
    let resultSummary = await this.getSection(id, 'summary');
    let resultFeedback = await this.getSection(id, 'feedback');
    let resultPlusMinus = await this.getSection(id, 'plusminus');
    let resultArticle = await this.getSection(id, 'articles');
    let resultTest = await this.getSection(id, 'tests');
    let car = this.state.carObjectSelected;
    car.summary = resultSummary;
    car.feedback = resultFeedback;
    car.plusMinus = resultPlusMinus;
    car.articles = resultArticle;
    car.tests = resultTest;

    try {
      this.setState({
        carObjectSelected: car,
        summary: resultSummary,
        feedback: resultFeedback,
        plusMinus: resultPlusMinus,
        articles: resultArticle,
        tests: resultTest,
      });
      return;
    } catch (error) {
      this.setState({
        error: error,
      });
    }
  };

  getEquipment = async () => {
    let equipment = await getEquipmentData();
    this.setState({
      total_equipment: equipment,
    });
  };

  getBattery = async () => {
    let battery = await getBatteryData();
    this.setState({
      total_battery: battery,
    });
  };

  getTechnical = async () => {
    let technical = await getTechnicalData();
    this.setState({
      total_technical: technical,
    });
  };

  render() {
    if (!this.state.error) {
      if (this.state.carNotFound) {
        return <h2>Fant ikke bil</h2>;
      } else {
        return (
          <CarDetailsWithLoading
            isLoading={this.state.isLoadingMain}
            isLoadingTot={this.state.isLoadingTot}
            carObject={this.state.carObjectSelected}
            total_equipment={this.state.total_equipment}
            total_battery={this.state.total_battery}
            total_technical={this.state.total_technical}
          ></CarDetailsWithLoading>
        );
      }
    } else {
      return (
        <DarkBlueContainer>
          <ErrorMessage error={this.state.error}></ErrorMessage>
        </DarkBlueContainer>
      );
    }
  }
}

const CarDetailsWithLoading = WithLoading(CarDetails);

export default CarInfoPage;
