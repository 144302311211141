import React from 'react';

import {
  Button,
  FormControl,
  Fab,
  Tooltip,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core';
import { CheckCircle, Delete } from '@material-ui/icons';
import '../../pages/adminPage/adminPage.scss';
import { EQUIPMENT_DATA_IDS_TO_BE_USED } from '../../constants';

class EquipmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      selectedCarObject: props.selectedCarObject,
      listOfEquipmentInfoToBePosted: [],
    };
  }
  myChangeHandler = (event) => {
    const selectedCarObject = { ...this.state.selectedCarObject };
    let foundObject;

    for (const key of Object.keys(selectedCarObject.carEquipmentData)) {
      foundObject = selectedCarObject.carEquipmentData[key].find(
        (item) => item.category_name === event.target.name
      );
      if (foundObject) {
        break;
      }
    }

    let equipment_category_id = this.getEquipmentCategoryIdFromName(
      event.target.name
    );

    // If car already contains information about this equipment type
    if (foundObject) {
      const selectedCarObject = { ...this.state.selectedCarObject };
      let foundObjectWithValue;

      for (const key of Object.keys(selectedCarObject.carEquipmentData)) {
        foundObjectWithValue = selectedCarObject.carEquipmentData[key].find(
          (item) => item.category_id === equipment_category_id
        );
        if (foundObjectWithValue) {
          foundObjectWithValue.standard = event.target.value;
          this.setState({ selectedCarObject });
          break;
        }
      }

      // If car does not contain information about this equipment type
    } else {
      let objectInlistOfEquipmentInfoToBePostedIndex = this.state.listOfEquipmentInfoToBePosted
        .map((item) => item.category_id)
        .indexOf(equipment_category_id);

      // If equipment type is not found in the list ready to be POSTed
      if (objectInlistOfEquipmentInfoToBePostedIndex < 0) {
        let equipment_object = {
          category_id: equipment_category_id,
          category_name: event.target.name,
          equipment_type_value: null,
          count: null,
          price_value: null,
          standard: event.target.value,
        };
        this.setState({
          listOfEquipmentInfoToBePosted: [
            ...this.state.listOfEquipmentInfoToBePosted,
            equipment_object,
          ],
        });
        // If equipment type is already ready to be POSTed
      } else {
        let items = [...this.state.listOfEquipmentInfoToBePosted];
        let item = { ...items[objectInlistOfEquipmentInfoToBePostedIndex] };
        item.standard = event.target.value;
        items[objectInlistOfEquipmentInfoToBePostedIndex] = item;
        this.setState({ listOfEquipmentInfoToBePosted: items });
      }
    }
  };

  processForm = async (event) => {
    event.preventDefault();
    let carId = this.state.selectedCarObject.car.car_id;

    event.preventDefault();
    let elementsToBePosted = [];
    for (const element of this.state.listOfEquipmentInfoToBePosted) {
      elementsToBePosted.push(element);
    }
    if (elementsToBePosted.length > 0) {
      await this.create(carId, elementsToBePosted);
    }

    let elementsToBePatched = [];
    for (const key of Object.keys(
      this.state.selectedCarObject.carEquipmentData
    )) {
      for (const item of this.state.selectedCarObject.carEquipmentData[
        key
      ].filter((item) =>
        EQUIPMENT_DATA_IDS_TO_BE_USED.includes(item.category_id)
      )) {
        elementsToBePatched.push(item);
      }
    }

    if (elementsToBePatched.length > 0) {
      await this.update(carId, elementsToBePatched);
    }

    this.setState({
      listOfEquipmentInfoToBePosted: [],
      submitted: true,
    });
    console.log('posted!');
  };

  create = async (carId, listOfElements) => {
    this.props.createClick(carId, listOfElements);
    this.setState({
      submitted: true,
    });
  };

  update = async (carId, listOfElements) => {
    this.props.updateClick(carId, listOfElements);
    this.setState({
      submitted: true,
    });
  };

  delete = async (equipmentId) => {
    let carId = this.state.selectedCarObject.car.car_id;
    this.props.deleteClick(carId, equipmentId);
    this.setState({
      deleted: true,
    });
  };

  getEquipmentInfoFromCar(category_id) {
    let finalFoundObject = null;
    let foundObject = null;
    for (const key of Object.keys(
      this.state.selectedCarObject.carEquipmentData
    )) {
      foundObject = this.state.selectedCarObject.carEquipmentData[key].find(
        (item) => item.category_id === category_id
      );
      if (foundObject) {
        finalFoundObject = foundObject.standard;
        break;
      }
    }
    if (finalFoundObject === null || finalFoundObject === undefined) {
      foundObject = this.state.listOfEquipmentInfoToBePosted.find(
        (item) => item.category_id === category_id
      );

      if (foundObject) {
        finalFoundObject = foundObject.standard;
      }
    }
    return finalFoundObject;
  }

  getEquipmentCategoryIdFromName(equipmentCategoryName) {
    let foundObject;
    for (const key of Object.keys(this.props.equipmentData)) {
      foundObject = this.props.equipmentData[key].find(
        (item) => item.category_name === equipmentCategoryName
      );
      if (foundObject) {
        return foundObject.category_id;
      }
    }
    return null;
  }

  render() {
    return (
      <div className="form-container all-radius">
        <form
          onSubmit={(e) => {
            this.processForm(e);
          }}
        >
          {Object.keys(this.props.equipmentData).map((item, index) => {
            return (
              <div key={index}>
                {this.props.equipmentData[item]
                  .filter((ed) =>
                    EQUIPMENT_DATA_IDS_TO_BE_USED.includes(ed.category_id)
                  )
                  .map((ed) => {
                    return (
                      <div
                        key={ed.category_id}
                        className="input-field-container"
                      >
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {ed.category_name}
                          </FormLabel>
                          <RadioGroup
                            aria-label={ed.category_name}
                            name={ed.category_name}
                            value={this.getEquipmentInfoFromCar(ed.category_id)}
                            onChange={this.myChangeHandler}
                            className="inline-radio"
                          >
                            <FormControlLabel
                              value="standard"
                              control={<Radio />}
                              label="Ja"
                            />
                            <FormControlLabel
                              value="extra"
                              control={<Radio />}
                              label="Ekstra"
                            />
                            <FormControlLabel
                              value="none"
                              control={<Radio />}
                              label="Nei"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Tooltip
                          title="Fjern informasjon på dette punktet"
                          aria-label="removeEquipment"
                        >
                          <Fab
                            size="small"
                            type="button"
                            color="secondary"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  'Er du sikker på at du fjerne informasjonen på dette feltet? Dette vil skjule/sette feltet som "ukjent" for brukerne. Du har fortsatt muligheten til å fylle ut feltet senere.'
                                )
                              )
                                this.delete(ed.category_id);
                            }}
                            className="small"
                          >
                            <Delete />
                          </Fab>
                        </Tooltip>
                      </div>
                    );
                  })}
              </div>
            );
          })}

          <Button variant="contained" color="primary" type="submit">
            Lagre
          </Button>
          {this.state.deleted && <p>Informasjonen ble slettet!</p>}
          {this.state.submitted && <CheckCircle />}
        </form>
      </div>
    );
  }
}

export default EquipmentForm;
