import React from 'react';
import { Fab, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Add, Done } from '@material-ui/icons';
import '../pages/indexPage/main-container.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d9d9d9',
    },
    secondary: {
      main: '#00bce4',
    },
  },
});

function FilterTag(props) {
  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    setIsActive(props.initiallyActive);
  }, [props.initiallyActive]);

  const onTagClick = () => {
    setIsActive(!isActive);
    props.handleClick(props.category);
  };

  return (
    <span className="filter-tag">
      {isActive === false ? (
        <MuiThemeProvider theme={theme}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => onTagClick()}
            className="filter-tag-fab inactive"
          >
            <Add className="filter-tag-icon" />
            {props.category.category_name}
          </Fab>
        </MuiThemeProvider>
      ) : (
        <MuiThemeProvider theme={theme}>
          <Fab
            variant="extended"
            size="medium"
            color="secondary"
            onClick={() => onTagClick()}
            className="filter-tag-fab active"
          >
              <Done className="filter-tag-icon" />
            {props.category.category_name}
          </Fab>
        </MuiThemeProvider>
      )}
    </span>
  );
}

export default FilterTag;
