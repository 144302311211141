import React from 'react';
import ConditionalSelector from './conditionalSelector';

class FilterItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterCategories: props.clickedCategory,
    };
  }

  //Check out this
  componentDidUpdate() {
    if (this.state.filterCategories !== this.props.clickedCategory) {
      this.setState({ filterCategories: this.props.clickedCategory });
    }
  }
  render() {
    return (
      <div
        role="form"
        aria-label={
          'Form for forskjellige ' +
          this.state.filterCategories.Name +
          ' filtere'
        }
      >
        <ConditionalSelector
          filterCategories={this.props.clickedCategory}
          onChange={this.handleChange}
          queryFilter={this.props.filtersForQuery}
          handleSliderValueChange={this.props.handleSliderValueChange}
          handleFilterRadioCheckboxValueChanges={
            this.props.handleFilterRadioCheckboxValueChanges
          }
          handleActiveBrands={this.props.handleActiveBrands}
          activeBrand={this.props.activeBrand}
        />
      </div>
    );
  }
  UpdateFilter(value) {}
}

export default FilterItem;
