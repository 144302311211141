import React from 'react';
import addicon from '../../assets/plus.svg';
import { isMobile } from 'react-device-detect';

function AddFilterBox(props) {
  return (
    <div
      className="box all-radius boop zoom-special"
      style={{ outline: isMobile ? 'none' : '' }}
      id="filterbox"
      onClick={e => props.handleAddClick(e)}
      tabIndex="0"
      aria-label="Legg til"
      role="button"
      onKeyPress={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          props.handleAddClick(e);
        }
      }}
    >
      <table className="box-table">
        <tbody>
          <tr style={{ height: '100%' }}>
            <td className="box-icon-cell-add">
              <img src={addicon} alt="Legg til ikon" className="box-icon-add" />
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="box-title">
              Legg til
            </td>
          </tr>
        </tbody>
      </table>
      <div className={props.dropdownBox ? 'chosen-arrow-down-fb' : ''}></div>
    </div>
  );
}

export default AddFilterBox;
