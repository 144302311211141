import React from 'react';
import { UNITS } from '../../../constants';
import ValueDisplayPipe from '../../carInfo/carSummaryList/valueDisplayPipe';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { isMobile } from 'react-device-detect';

function CarThumbnailDetailTable(props) {
  let range = getPreferredRange(props.car);
  if (props.car.range_wltp === null && props.car.range_nedc !== null && props.car.range_nedc > 0) {
    return (
      <table>
        <tbody>
          <tr>
            <td>Lanseringsår</td>
            <td className={props.car.concept_car ? 'highlighted' : ''}>
              {props.car.concept_car ? 'Konsept' : props.car.release_year}
            </td>

          </tr>
          <tr>
            <td>Pris fra</td>
            <td>
              {
                <ValueDisplayPipe
                  value={props.car.basic_model_price_nok}
                  unit={UNITS.money}
                />
              }
            </td>
          </tr>
          <tr>
            <td>Rekkevidde</td>
            <td>
              {
                <ValueDisplayPipe
                  value={range}
                  unit={UNITS.distance}
                />
              }
            </td>

            <td className="tooltip">
              <WarningRoundedIcon
                style={
                  isMobile
                    ? { fontSize: '18px', color: '#434343', marginTop: '5px' }
                    : { fontSize: '24px', color: '#434343'}
                }
              />
              <span className={isMobile ? 'tooltiptextMobile' : 'tooltiptext'}>
                {'Denne rekkeviden er basert på gammel standard, NEDC. Den vil være vesentlig kortere målt med dagens WLTP.'}
              </span>
            </td>
          </tr>
          <tr>
            <td>Størrelse</td>
            {/* antall dører, evt suv osv */}
            <td>
              {<ValueDisplayPipe value={props.car.door} unit={UNITS.doors} />}
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return (
      <table>
        <tbody>
          <tr>
            <td>Lanseringsår</td>
            <td className={props.car.concept_car ? 'highlighted' : ''}>
              {props.car.concept_car ? 'Konsept' : props.car.release_year}
            </td>
            
          </tr>
          <tr>
            <td>Pris fra</td>
            <td>
              {
                <ValueDisplayPipe
                  value={props.car.basic_model_price_nok}
                  unit={UNITS.money}
                />
              }
            </td>
          </tr>
          <tr>
            <td>Rekkevidde</td>
            <td>
              {
                <ValueDisplayPipe
                  value={range}
                  unit={UNITS.distance}
                />
              }
            </td>
          </tr>
          <tr>
            <td>Størrelse</td>
            {/* antall dører, evt suv osv */}
            <td>
              {<ValueDisplayPipe value={props.car.door} unit={UNITS.doors} />}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

function getPreferredRange(car) {
  if (car.range_wltp !== null && car.range_wltp > 0) return car.range_wltp
  else if (car.range_nedc !== null && car.range_nedc > 0) return car.range_nedc
  else if (car.range_summer !== null && car.range_summer > 0) return car.range_summer
  else if (car.range_winter !== null && car.range_winter > 0) return car.range_winter
  else return null
}

export default CarThumbnailDetailTable;
