import React from 'react';
import './carSummaryItem.scss';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

function Feedback(props) {
  let data;
  if (props.feedback.length !== 0) {
    data = [
      {
        name: 'Veldig misfornøyd',
        feedback: props.feedback[0].very_unsatisfied_percent,
      },
      { name: 'Misfornøyd', feedback: props.feedback[0].unsatisfied_percent },
      { name: 'Verken eller', feedback: props.feedback[0].either_or_percent },
      { name: 'Fornøyd', feedback: props.feedback[0].satisfied_percent },
      {
        name: 'Veldig fornøyd',
        feedback: props.feedback[0].very_satisfied_percent,
      },
    ];
  }

  const targetRef = React.useRef();
  const [dimensions, setDimensions] = React.useState({
    width: 300,
    height: 300,
  });

  let movement_timer = null;

  const RESET_TIMEOUT = 100;

  const test_dimensions = () => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  };

  React.useEffect(() => {
    test_dimensions();
  }, []);

  window.addEventListener('resize', () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
  });

  const SimpleBarChart = () => {
    return (
      <BarChart
        width={dimensions.width}
        height={dimensions.height}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tick={false} />
        <Bar dataKey="feedback" fill="#a2d2b6" />
      </BarChart>
    );
  };

  return (
    <div>
      {props.feedback.length !== 0 ? (
        <>
          <h1 className="h1-tag">Gjennomsnitt tilbakemeldinger</h1>
          <div>
            <h1 className="percentage-feedback">
              {props.feedback[0].very_satisfied_percent +
                props.feedback[0].satisfied_percent}
              %
            </h1>
            <h1 className="text-after-percent">positiv tilbakemelding</h1>
          </div>
          <div style={{ width: '100%', marginTop: '2em' }} ref={targetRef}>
            <SimpleBarChart />
          </div>
          <h1 style={{ marginTop: '1em' }}>
            Grafen viser hvor mange som er tilfredse med bilen, og tallene er
            hentet fra vår årlige undersøkelse. Tallene oppdateres en gang i
            året, og krever minimum 50 tilbakemeldinger før det blir lagt inn.
          </h1>
        </>
      ) : (
        <h1 className="h1-standard-text">
          Det er dessverre ingen tilbakemeldinger tilgjengelige på denne bilen.
        </h1>
      )}
    </div>
  );
}

export default Feedback;
