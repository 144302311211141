import React from 'react';
import '../../pages/adminPage/adminPage.scss';
import TestForm from './testForm';
import * as carService from '../../services/CarService';
import WithLoading from '../withLoading';

const TestFormWithLoading = WithLoading(TestForm);
class TestManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarObject: this.props.selectedCarObject,
      created: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCarObject !== this.props.selectedCarObject) {
      this.setState({ selectedCarObject: this.props.selectedCarObject });
    }
  }
  create = async (carId, article) => {
    this.setState({ isLoading: true });
    await carService.postCarFromIdAndAttribute(carId, 'tests', [article]);

    let chosenCar = await carService.getAllJoinDataById(carId);
    let carSections = await carService.getSections(carId);
    let fullCarInformation = { ...chosenCar, ...carSections };
    this.setState({
      selectedCarObject: fullCarInformation,
      created: true,
      isLoading: false,
    });
    return this.state.created;
  };

  update = async (carId, article) => {
    await carService.updateCarFromIdAndAttribute(carId, 'tests', [article]);
  };

  delete = async (carId, articleId) => {
    let result = await carService.deleteFromCarFromIdAndAttributeId(
      carId,
      'tests',
      [+articleId]
    );
    if (result instanceof Error) {
      return;
    } else {
      console.log('deleted!');
    }
  };

  render() {
    return (
      <div>
        <TestFormWithLoading
          isLoading={this.state.isLoading}
          selectedCarObject={this.state.selectedCarObject}
          createClick={(carId, article) => this.create(carId, article)}
        />
        {this.state.selectedCarObject.tests.map((test, index) => {
          return (
            <TestFormWithLoading
              key={index}
              selectedCarObject={this.state.selectedCarObject}
              chosenTest={test}
              updateClick={(carId, test) => this.update(carId, test)}
              deleteClick={(carId, testId) => this.delete(carId, testId)}
            />
          );
        })}
      </div>
    );
  }
}

export default TestManager;
