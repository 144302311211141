import React from 'react';
import deadBattery from '../assets/batteryDead.png';
import WhiteShell from './containers/whiteshell';

function NotFoundPage(props) {
  return (
    <div
      style={{
        maxWidth: '500px',
        margin: '50px auto',
      }}
    >
      <h1
        style={{
          fontSize: '2em',
        }}
      >
        404 Page not found
      </h1>
      <WhiteShell>
        <img src={deadBattery} alt="deadBattery" width="50%" height="70%" />
      </WhiteShell>
    </div>
  );
}

export default NotFoundPage;
