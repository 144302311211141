import React from 'react';
import './submitButton.scss';

function SubmitButton(props) {
  return (
    <button
      className="boop submit-button all-radius zoom-special-button"
      style={props.style}
      aria-label="Submit"
      onClick={props.handleSubmitClick}
    >
      {props.text}
    </button>
  );
}

export default SubmitButton;
