import React from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { CheckCircle, Delete } from '@material-ui/icons';
import '../../pages/adminPage/adminPage.scss';

class TestForm extends React.Component {
  keyNameMap = {
    very_unsatisfied_percent: 'Veldig misfornøyd',
    unsatisfied_percent: 'Misfornøyd',
    either_or_percent: 'Hverken eller',
    satisfied_percent: 'Fornøyd',
    very_satisfied_percent: 'Veldig fornøyd',
    dont_know_percent: 'Vet ikke',
  };

  constructor(props) {
    super(props);
    if (props.feedback.length === 0) {
      this.state = {
        submitted: false,
        deleted: false,
        feedback: {
          very_unsatisfied_percent: 0,
          unsatisfied_percent: 0,
          either_or_percent: 0,
          satisfied_percent: 0,
          very_satisfied_percent: 0,
          dont_know_percent: 0,
        },
      };
    } else {
      this.state = {
        submitted: false,
        deleted: false,
        feedback: props.feedback,
      };
    }
  }

  myChangeHandler = event => {
    const feedback = { ...this.state.feedback };
    feedback[event.target.name] = +event.target.value;
    this.setState({
      submitted: false,
      feedback: feedback,
    });
  };

  create = async (e, carId, feedback) => {
    e.preventDefault();
    this.props.createClick(carId, feedback);
    this.setState({
      submitted: true,
    });
  };

  update = async (e, carId, feedback) => {
    e.preventDefault();
    this.props.updateClick(carId, feedback);
    this.setState({
      submitted: true,
    });
  };

  delete = async carId => {
    this.props.deleteClick(carId);
    this.setState({
      deleted: true,
    });
  };

  render() {
    if (!this.state.deleted) {
      return (
        <div className="form-container all-radius">
          <form
            onSubmit={e =>
              this.props.feedback.length === 0
                ? this.create(
                    e,
                    this.props.selectedCarObject.car.car_id,
                    this.state.feedback
                  )
                : this.update(
                    e,
                    this.props.selectedCarObject.car.car_id,
                    this.state.feedback
                  )
            }
          >
            <div>
              {Object.keys(this.state.feedback).map(key => {
                return (
                  <FormControl key={key}>
                    <InputLabel htmlFor={key}>
                      {this.keyNameMap[key]}
                    </InputLabel>
                    <Input
                      id={key}
                      className="textfield"
                      type="number"
                      name={key}
                      label={key}
                      onChange={this.myChangeHandler}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      value={this.state.feedback[key]}
                      autoComplete="off"
                    />
                  </FormControl>
                );
              })}
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                this.state.feedback.length === 0 || this.state.submitted
              }
            >
              Lagre
            </Button>
            {this.state.submitted && <CheckCircle />}
            <Button
              variant="contained"
              color="secondary"
              onClick={e => {
                if (
                  window.confirm(
                    'Er du sikker på at du vil slette denne tilbakemeldingen?'
                  )
                )
                  this.delete(this.props.selectedCarObject.car.car_id);
              }}
              disabled={this.state.deleted && this.props.feedback}
            >
              Slett
            </Button>
            {this.state.deleted && (
              <div>
                <Delete /> Slettet!
              </div>
            )}
          </form>
        </div>
      );
    }
    return (
      <div className="form-container all-radius">
        <CheckCircle />
        <p>Slettet</p>
      </div>
    );
  }
}

export default TestForm;
