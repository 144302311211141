import NumberDisplay from '../../numberDisplayPipe';
import { lowerFirstLetter } from '../../../services/CarService';

function ValueDisplayPipe(props) {
  let printvalue = '';
  if (props.value !== undefined && props.value !== null) {
    if (typeof props.value === 'boolean') {
      return props.value ? 'Ja' : 'Nei';
    }
    if (typeof props.value === 'number') {
      printvalue += NumberDisplay(props.value);
    } else if (!isNaN(props.value)) {
      printvalue += NumberDisplay(+props.value);
    } else {
      printvalue += props.value.toString();
    }
  } else {
    return 'Ukjent';
  }
  if (props.unit) {
    let unit = lowerFirstLetter(props.unit);
    if (unit === 'timer' && +props.value === 1) {
      unit = 'time';
    }
    if (unit === 'minutter' && +props.value === 1) {
      unit = 'minutt';
    }
    printvalue += ' ' + props.unit;
  }
  return printvalue;
}

export default ValueDisplayPipe;
