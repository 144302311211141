import React from 'react';
import FilterBox from './filterBox/filterBox';
import * as utilityClass from '../utilityClass';
import AddFilterBox from './filterBox/addFilterBox';
import WhiteShell from './containers/whiteshell';
import FilterItem from './filterItems/filterItem';
import CSSTransition from 'react-transition-group/CSSTransition';
import '../pages/indexPage/main-container.scss';

function FiltersDisplay(props) {
  let sortedFilters = utilityClass.sortByMostPopular(props.filterCategories);
  let selectedFilterIndex = sortedFilters.findIndex(x => {
    return (
      props.showFilterValues &&
      props.clickedCategory.category_id === x.category_id
    );
  });

  const targetRef = React.useRef();
  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  let movement_timer = null;

  const RESET_TIMEOUT = 100;

  const test_dimensions = () => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  };

  const openCheckerOneRow = () => {
    if (
      selectedFilterIndex !== -1 &&
      divToInsertDetailsIn === rows * maxBoxesInRow - 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const openCheckerMultipleRows = index => {
    if (selectedFilterIndex !== -1 && index === divToInsertDetailsIn) {
      return true;
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    test_dimensions();
  }, []);

  window.addEventListener('resize', () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
  });

  //140 is the size of one single filterBox. 10 is margin. 20 is padding on big blue container.
  const maxBoxesInRow = Math.trunc((dimensions.width - 20) / (140 + 10));
  const witchRow = Math.trunc(selectedFilterIndex / maxBoxesInRow) + 1;
  const divToInsertDetailsIn = maxBoxesInRow * witchRow - 1;
  const rows = Math.ceil((sortedFilters.length + 1) / maxBoxesInRow);

  return (
    <div className="flex-content" ref={targetRef}>
      {sortedFilters.map((category, index) => (
        <React.Fragment key={index}>
          <FilterBox
            category={category}
            handleKillClick={(e, category) =>
              props.handleKillClick(e, category)
            }
            handleClick={props.handleFilterClick}
            showFilterValues={props.showFilterValues}
            clickedCategory={props.clickedCategory}
            filtersForQuery={props.filtersForQuery}
          />

          {/* <TransitionGroup style={{ width: twoRow ? '100%' : '' }}> */}
          <CSSTransition
            in={openCheckerMultipleRows(index)}
            key={props.clickedCategory.category_id}
            timeout={300}
            classNames="boxWithAnimation"
            unmountOnExit
          >
            <div className="onboarding-box">
              <WhiteShell>
                <FilterItem
                  clickedCategory={props.clickedCategory}
                  filtersForQuery={props.filtersForQuery}
                  handleSliderValueChange={props.handleSliderValueChange}
                  handleFilterRadioCheckboxValueChanges={
                    props.handleFilterRadioCheckboxValueChanges
                  }
                  handleActiveBrands={props.handleActiveBrands}
                  activeBrand={props.activeBrand}
                />
              </WhiteShell>
            </div>
          </CSSTransition>
          {/* </TransitionGroup> */}
        </React.Fragment>
      ))}
      <AddFilterBox
        handleAddClick={props.handleAddClick}
        dropdownBox={props.dropdownBox}
      />

      {/* <TransitionGroup style={{ width: oneRow ? '100%' : '' }}> */}
      <CSSTransition
        key={props.clickedCategory.category_id}
        in={openCheckerOneRow()}
        timeout={300}
        classNames="boxWithAnimation"
        unmountOnExit
      >
        <div className="onboarding-box">
          <WhiteShell>
            <FilterItem
              clickedCategory={props.clickedCategory}
              filtersForQuery={props.filtersForQuery}
              handleSliderValueChange={props.handleSliderValueChange}
              handleFilterRadioCheckboxValueChanges={
                props.handleFilterRadioCheckboxValueChanges
              }
              handleActiveBrands={props.handleActiveBrands}
              activeBrand={props.activeBrand}
            />
          </WhiteShell>
        </div>
      </CSSTransition>
      {/* </TransitionGroup> */}
    </div>
  );
}

export default FiltersDisplay;
