import React from 'react';
import './expansionPanel.scss';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import CSSTransition from 'react-transition-group/CSSTransition';

function ExpansionPanel(props) {
  const [isExpanded, setIsExpanded] = React.useState(false);

  //Used here to set the value of extra text in Filters block.
  let extraTitle = "";
  if (props.title === 'Visning')
    extraTitle = " ( Juster sortering og visning )"
  else if (props.title === 'Valg')
    extraTitle = " ( Juster filtrene dine )"

  React.useEffect(() => {
    if (isExpanded) {
      toggle();
    }
    // eslint-disable-next-line
  }, [props.expanded]);

  React.useEffect(() => {
    setIsExpanded(props.initiallyActive);
  }, [props.initiallyActive]);

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section>
      <div
        className="expansion-panel all-radius"
        onClick={toggle}
        tabIndex="0"
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggle();
          }
        }}
      >
        <h2>{props.title}</h2>
        <h3> {extraTitle} </h3>
        <span className="spacer"></span>
        <div className="expand-icon">
          {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </div>
      </div>
      <CSSTransition
        in={isExpanded}
        timeout={300}
        classNames="boxWithAnimation"
        unmountOnExit
      >
        <div className="expansion-panel-content">{props.children}</div>
      </CSSTransition>
    </section>
  );
}

export default ExpansionPanel;
