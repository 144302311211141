import React from 'react';
import { UNITS } from '../../../constants';
import ValueDisplayPipe from '../../carInfo/carSummaryList/valueDisplayPipe';

function ParentCarThumbnailDetailTable(props) {
  let range_from, range_to, year_from, year_to, priceFrom, size, size_from, size_to;

  function getDoorSize() {
    let total_doors = [];
    props.car.flatMap(item => {
      if (item.door) {
        //Splitting and returning both values when door value is set in format => '4-5'
        if (item.door.includes('-')) {
          let x = item.door.split('-');
          total_doors.push(parseInt(x[0]), parseInt(x[1]));
        }
        else total_doors.push(parseInt(item.door))
      }
    })
    return total_doors;
  }

  function getAllPrice() {
    return props.car.map(item => item.basic_model_price_nok)
  }

  function getReleaseYears() {
    return props.car.map(item => item.release_year)
  }

  function getRanges() {
    return props.car
      .map(item => {
        if (item.range_wltp > 0) return parseInt(item.range_wltp);
        if (item.range_nedc > 0) return parseInt(item.range_nedc);
        if (item.range_summer > 0) return parseInt(item.range_summer);
        if (item.range_winter > 0) return parseInt(item.range_winter);
        return 0;
      })
  }

  function getSizeString(size_from, size_to) {
    if(!Number.isFinite(size_to) && !Number.isFinite(size_from)) return 'ukjent'
    if(size_to === size_from) return size_from;
    return size_from + ' - ' + size_to;
  }
  
  size_from = Math.min(...getDoorSize());
  size_to = Math.max(...getDoorSize());
  size = getSizeString(size_from, size_to);
  year_from = Math.min(...getReleaseYears());
  year_to = Math.max(...getReleaseYears());
  priceFrom = Math.min(...getAllPrice());
  range_from = Math.min(...getRanges()) === 0 ? 'ukjent' : Math.min(...getRanges());
  range_to = Math.max(...getRanges());

    return (
      <table>
        <tbody>
          <tr>
            <td>Lanseringsår</td>
            <td className={props.car.concept_car ? 'highlighted' : ''}>
                {props.car.concept_car ? 'Konsept' : (year_from === year_to ? year_from : (year_from + ' - ' + year_to))}
            </td>
          </tr>
          <tr>
            <td>Pris fra</td>
            <td>
              {
                <ValueDisplayPipe
                  value={priceFrom}
                  unit={UNITS.money}
                />
              }
            </td>
          </tr>
          <tr>
            <td>Rekkevidde</td>
            <td>
                {range_from === range_to ? (range_from + ' ' + UNITS.distance) : (range_from + ' - ' + range_to + ' ' + UNITS.distance) }
            </td>
          </tr>
          <tr>
            <td>Størrelse</td>
            {/* antall dører, evt suv osv */}
            <td>
              {<ValueDisplayPipe value={size} unit={UNITS.doors} />}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

export default ParentCarThumbnailDetailTable;