import React from 'react';
import LightGreenShell from '../../components/containers/lightgreenshell';
import InfoCategoryThumbnailsDisplay from '../../components/infoCategoryThumbnail/infoCategoryThumbnailsDisplay';
import { CAR_INFO_CATEGORIES } from '../../constants';
import WhiteShell from '../../components/containers/whiteshell';
import CarSummaryList from '../../components/carInfo/carSummaryList/carSummaryList';
import ImageGallery from 'react-image-gallery';
import DarkBlueContainer from '../../components/containers/DarkBlueContainer';
import PlusMinus from '../../components/carInfo/carSummaryList/plusMinus';
import Feedback from '../../components/carInfo/carSummaryList/feedback';
import ArticleAndTest from '../../components/carInfo/carArticlesAndTests/articleAndTest';
import CarDetailsList from '../../components/carInfo/carDetailsList/carDetailsList';
import { getCarFullName } from '../../services/CarService';
import { isMobile } from 'react-device-detect';
import './carousel-customize.scss';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import WithLoading from '../../components/withLoading';
import * as carService from '../../services/CarService';

class CarDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedCategoryId: isMobile ? '' : 'summary',
      carObject: props.carObject,
      carDetails: [],
    };
  }

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    const modelName = this.state.carObject.car.model_name + ' ' + this.state.carObject.car.model_edition_name ?? '';
    dataLayer.push({ 'event': 'VirtualPageview', 'virtualPageURL': '/elbilvelgeren/bilValgt', 'virutalPageTitle': 'Elbilvelgeren | Bil valgt', 'merke': this.state.carObject.car.brand_name, 'modell': modelName });
    this.getDetails(this.state.carObject.car.car_id);
  }

  getDetails = async (carId) => {
    const carDetails = await carService.getSpecificCarDetails(carId);
    this.setState({
      carDetails: carDetails,
    });
  };

  handleShowCategoryClick(id) {
    this.displayedCategoryId = id;
    this.setState({
      displayedCategoryId: id,
    });
  }

  toCarouselElements = (image_urls) => {
    return image_urls.map((url, index) => {
      return {
        original: url,
        thumbnail: url,
        originalClass: 'all-radius-gallery-original',
        thumbnailClass: 'all-radius-gallery-thumbnail',
        originalAlt: this.getAlternativeText(index),
        thumbnailAlt: this.getAlternativeText(index),
      };
    });
  };

  getAlternativeText(index) {
    let label =
      'Bilde ' +
      (index + 1) +
      ' av ' +
      this.state.carObject.car.brand_name +
      ' ' +
      (this.state.carObject.car.model_name ??
        this.state.carObject.car.model_name) +
      ' ' +
      (this.state.carObject.car.model_edition_name ??
        this.state.carObject.car.model_name);
    return label;
  }

  render() {
    return (
      <section>
        {this.state.carObject.car && this.state.carObject.car.image_urls ? (
          <div
            role="img"
            tabIndex={0}
            className={
              isMobile
                ? 'image-gallery-container-mobile'
                : 'image-gallery-container'
            }
          >
            <ImageGallery
              items={this.toCarouselElements(
                this.state.carObject.car.image_urls.split(';')
              )}
              autoPlay={true}
            />
          </div>
        ) : (
          <DarkBlueContainer>
            <p>Ingen bilder</p>
          </DarkBlueContainer>
        )}
        <LightGreenShell>
          <h2>{getCarFullName(this.state.carObject.car)}</h2>
        </LightGreenShell>
        <InfoCategoryThumbnailsDisplay
          handleShowCategoryClick={this.handleShowCategoryClick.bind(this)}
          categories={CAR_INFO_CATEGORIES}
          displayedCategoryId={this.state.displayedCategoryId}
          carObject={this.state.carObject}
        />
        {this.state.displayedCategoryId &&
          (typeof this.state.carObject === 'object' &&
            this.state.carObject !== null ? (
            <div className="onboarding-box">
              {this.state.displayedCategoryId === 'summary' ||
                this.state.displayedCategoryId === 'plusminus' ||
                this.state.displayedCategoryId === 'feedback' ||
                this.state.displayedCategoryId === 'details' ? (
                <WhiteShell>
                  {this.state.displayedCategoryId === 'summary' && (
                    <CarSummaryList
                      summary={this.state.carObject}
                    ></CarSummaryList>
                  )}
                  {this.state.displayedCategoryId === 'plusminus' && (
                    <PlusMinus
                      plusminus={this.state.carObject.plusMinus}
                    ></PlusMinus>
                  )}
                  {this.state.displayedCategoryId === 'feedback' && (
                    <Feedback
                      feedback={this.state.carObject.feedback}
                    ></Feedback>
                  )}
                  {this.state.displayedCategoryId === 'details' && (
                    <CarDetailsListWithLoading
                      isLoading={this.props.isLoadingTot}
                      carDetails={this.state.carDetails}
                    ></CarDetailsListWithLoading>
                  )}
                </WhiteShell>
              ) : (
                <React.Fragment>
                  {this.state.displayedCategoryId === 'article' &&
                    (this.state.carObject.articles.length === 0 ? (
                      <WhiteShell>
                        <ArticleAndTest type={'article'} hasValue={false} />
                      </WhiteShell>
                    ) : (
                      this.state.carObject.articles.map((article, index) => {
                        return (
                          <WhiteShell
                            key={index}
                            style={{
                              marginBottom:
                                this.state.carObject.articles.length !==
                                  index + 1
                                  ? '20px'
                                  : '0px',
                            }}
                          >
                            <ArticleAndTest
                              hasValue={true}
                              type={'article'}
                              value={article}
                            />
                          </WhiteShell>
                        );
                      })
                    ))}

                  {this.state.displayedCategoryId === 'tests' &&
                    (this.state.carObject.tests.length === 0 ? (
                      <WhiteShell>
                        <ArticleAndTest type={'test'} hasValue={false} />
                      </WhiteShell>
                    ) : (
                      this.state.carObject.tests.map((test, index) => {
                        return (
                          <WhiteShell
                            key={index}
                            style={{
                              marginBottom:
                                this.state.carObject.tests.length !== index + 1
                                  ? '20px'
                                  : '0px',
                            }}
                          >
                            <ArticleAndTest
                              hasValue={true}
                              type={'test'}
                              value={test}
                            />
                          </WhiteShell>
                        );
                      })
                    ))}
                </React.Fragment>
              )}
            </div>
          ) : (
            <React.Fragment>
              <WhiteShell>
                <div>
                  <h1
                    className="h1-standard-text"
                    style={{ fontSize: ' 1.2em' }}
                  >
                    Noe gikk feil last siden på nytt!
                  </h1>
                </div>
              </WhiteShell>
            </React.Fragment>
          ))}
      </section>
    );
  }
}

const CarDetailsListWithLoading = WithLoading(CarDetailsList);

export default CarDetails;
