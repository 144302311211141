import React from 'react';
import './spinner.scss';
//import loadingGIF from '../assets/loadingLightning.gif';

function LoadingIndicator() {
  return (
    <div style={{ textAlign: 'center', fontSize: '1.5em' }}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h1 style={{ color: '#ffffff' }}>Loading...</h1>
      {/* <img src={loadingGIF} alt="loadingGIF" />
       */}
    </div>
  );
}

export default LoadingIndicator;
