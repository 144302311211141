import React from 'react';
import TechnicalDataForm from './technicalDataForm';
import { TECHNICAL_DATA_CATEGORIES_TO_BE_REMOVED } from '../../constants';

class TechnicalDataManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCarObject: this.props.selectedCarObject,
    };
  }
  getWantedTechnicalData() {
    let foundTechnicalData = { ...this.props.technicalData };
    TECHNICAL_DATA_CATEGORIES_TO_BE_REMOVED.forEach((e) => {
      if (foundTechnicalData[e]) {
        delete foundTechnicalData[e];
      }
    });

    return foundTechnicalData;
  }

  render() {
    return (
      <TechnicalDataForm
        selectedCarObject={this.state.selectedCarObject}
        technicalData={this.getWantedTechnicalData()}
      />
    );
  }
}

export default TechnicalDataManager;
