import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/';
import './filterItems.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ec6723',
    },
  },
});

function FilterCheckbox(props) {
  let isChecked;
  let defaultChecked;

  //Holding onto the individual filters
  let tempHolder = props.queryFilter.find((x) => x.id === props.filter.id);
  if (tempHolder) {
    defaultChecked = false;
    let arrRes = tempHolder.alternatives.find(
      (x) => x.toString() === props.value.toString()
    );
    if (arrRes === props.value.toString()) {
      defaultChecked = true;
    }
  } else {
    defaultChecked = false;
  }

  const handleCheckboxClick = (e) => {
    isChecked = !e.target.checked;
    props.onChange(e, props.filter.id);
  };
  return (
    // Finish work with checkboxes with material, new design added from default checkboxes
    <div className="checkBox-filter">
      <MuiThemeProvider theme={theme}>
        <FormControlLabel
          control={
            <Checkbox
              tabIndex="0"
              name={props.value}
              value={props.value}
              defaultChecked={defaultChecked}
              color="primary"
              checked={isChecked}
              onChange={(e) => handleCheckboxClick(e)}
              onKeyPress={(e) => {
                // Why no enter work?
                if (e.key === 'Enter' || e.key === ' ') {
                  handleCheckboxClick(e);
                }
              }}
            />
          }
          label={props.value}
        />
      </MuiThemeProvider>
    </div>
  );
}
export default FilterCheckbox;
