import React from 'react';
import FilterTag from './filterTag';
import * as utilityClass from '../utilityClass';

function FilterTagDisplay(props) {
  let sortedFilterCategories = utilityClass.sortByMostPopular(
    props.showSearchFilters
  );

  return (
    <div>
      {sortedFilterCategories.map((category, index) => {
        const arr = props.activeFilterCategories.filter(
          activeFilterCategory => {
            return activeFilterCategory.category_id === category.category_id;
          }
        );
        return (
          <FilterTag
            initiallyActive={arr.length > 0}
            key={index}
            category={category}
            handleClick={props.handleClick}
          />
        );
      })}
    </div>
  );
}

export default FilterTagDisplay;
