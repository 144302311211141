import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { ViewList, ViewColumn, BorderAll } from '@material-ui/icons';

function ViewAlternativesDisplay(props) {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#00bce4',
      },
    },
  });

  return (
    <div className="thumbnail-style-buttons hover-icons">
      <MuiThemeProvider theme={theme}>
        <ViewColumn
          tabIndex="0"
          style={{ fontSize: '50px', cursor: 'pointer' }}
          onClick={() => props.handleClick('column')}
          onKeyPress={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              props.handleClick('column');
            }
          }}
          color={props.thumbnailStyle === 'column' ? 'primary' : 'inherit'}
        />
        <ViewList
          tabIndex="0"
          style={{ fontSize: '50px', cursor: 'pointer' }}
          onClick={() => props.handleClick('list')}
          onKeyPress={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              props.handleClick('list');
            }
          }}
          color={props.thumbnailStyle === 'list' ? 'primary' : 'inherit'}
        />
        <BorderAll
          tabIndex="0"
          style={{ fontSize: '50px', cursor: 'pointer' }}
          onClick={() => props.handleClick('stream')}
          onKeyPress={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              props.handleClick('stream');
            }
          }}
          color={props.thumbnailStyle === 'stream' ? 'primary' : 'inherit'}
        />
      </MuiThemeProvider>
    </div>
  );
}

export default ViewAlternativesDisplay;
