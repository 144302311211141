import React from 'react';
import FilterSlider from './filterSlider';
import './filterItems.scss';
import { FormGroup, Grid } from '@material-ui/core';
function IntermediatSlider(props) {
  const setSliderDefaultValues = (filterID) => {
    let tempHolder = props.queryFilter.find((x) => x.id === filterID);
    let valueHolder;
    if (tempHolder) {
      valueHolder = tempHolder.alternatives;
    }
    return valueHolder;
  };

  return (
    <React.Fragment key={props.i}>
      <h1 className="header-fix">{props.filter.name}</h1>
      <FormGroup row>
        <FilterSlider
          filter={props.filter}
          category_id={props.filterCategories}
          queryFilter={props.queryFilter}
          defaultValues={setSliderDefaultValues(props.filter.id)}
          handleSliderValueChange={props.handleSliderValueChange}
        />
      </FormGroup>
      <Grid container spacing={2}>
        <Grid item xs={11}></Grid>
        <Grid item xs={1}></Grid>
        <>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            {props.filter.help_text}
          </Grid>
          <Grid item xs={1}></Grid>
        </>
      </Grid>
    </React.Fragment>
  );
}

export default IntermediatSlider;
