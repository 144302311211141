import React from 'react';
import LabelTag from './labelTag';

function LabelTagDisplay(props) {
  return props.filter.alternatives
    .filter(
      e => props.searchLabels.indexOf(e) > -1 || props.searchLabels.length === 0
    )
    .map((alternative, index) => {
      return (
        <LabelTag
          handleClick={label => props.handleClick(label)}
          alternative={alternative}
          key={index}
          isActive={
            props.activeLabels.filter(altA => altA === alternative).length > 0
          }
        />
      );
    });
}

export default LabelTagDisplay;
