import React from 'react';
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import '../../pages/adminPage/adminPage.scss';

class TestForm extends React.Component {
  constructor(props) {
    super(props);
    if (props.chosenPlusMinus) {
      this.state = {
        submitted: false,
        deleted: false,
        plusMinus: {
          id: props.chosenPlusMinus.id,
          comment: props.chosenPlusMinus.comment,
          plus_minus_type: props.plus_minus_type,
        },
      };
    } else {
      this.state = {
        submitted: false,
        plusMinus: {
          comment: '',
          plus_minus_type: '',
        },
      };
    }
  }
  myChangeHandler = (event) => {
    const plusMinus = { ...this.state.plusMinus };
    plusMinus[event.target.name] = event.target.value;
    this.setState({ submitted: false, plusMinus: plusMinus });
  };

  create = async (e, carId, plusMinus) => {
    e.preventDefault();
    let res = await this.props.createClick(carId, plusMinus);
    this.setState({
      submitted: true,
    });
    if (res) {
      this.clearForm();
    }
  };
  clearForm = () => {
    this.setState({
      submitted: false,
      plusMinus: {
        comment: '',
        plus_minus_type: '',
      },
    });
  };

  update = async (e, carId, plusMinus) => {
    e.preventDefault();
    this.props.updateClick(carId, plusMinus);
    this.setState({
      submitted: true,
    });
  };

  delete = async (carId, plusMinusId) => {
    this.props.deleteClick(carId, plusMinusId);
    this.setState({
      deleted: true,
    });
  };

  getClassName() {
    if (this.state.plusMinus.plus_minus_type === 'plus') {
      return 'positive';
    } else if (this.state.plusMinus.plus_minus_type === 'minus') {
      return 'negative';
    }
    return '';
  }

  render() {
    if (!this.state.deleted) {
      return (
        <div className={'form-container all-radius ' + this.getClassName()}>
          <form
            onSubmit={(e) =>
              !this.props.chosenPlusMinus
                ? this.create(
                    e,
                    this.props.selectedCarObject.car.car_id,
                    this.state.plusMinus
                  )
                : this.update(
                    e,
                    this.props.selectedCarObject.car.car_id,
                    this.state.plusMinus
                  )
            }
          >
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Pluss/minus</FormLabel>
                <RadioGroup
                  aria-label="plus_minus_type"
                  name="plus_minus_type"
                  value={this.state.plusMinus.plus_minus_type}
                  onChange={this.myChangeHandler}
                  className="inline-radio"
                >
                  <FormControlLabel
                    value="plus"
                    control={<Radio />}
                    label="Pluss"
                  />
                  <FormControlLabel
                    value="minus"
                    control={<Radio />}
                    label="Minus"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <TextField
                className="textfield"
                name="comment"
                label="Kommentar"
                onChange={this.myChangeHandler}
                value={this.state.plusMinus.comment}
                autoComplete="off"
                multiline={true}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                !this.state.plusMinus.plus_minus_type || this.state.submitted
              }
            >
              {this.props.chosenPlusMinus ? 'Lagre' : 'Opprett ny'}
            </Button>
            {this.state.submitted && <CheckCircle />}
            {this.props.chosenPlusMinus && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  if (window.confirm('Er du sikker på at du vil slette denne?'))
                    this.delete(
                      this.props.selectedCarObject.car.car_id,
                      this.state.plusMinus.id
                    );
                }}
              >
                Slett
              </Button>
            )}
          </form>
        </div>
      );
    }
    return (
      <div className="form-container all-radius">
        <CheckCircle />
        <p>Slettet</p>
      </div>
    );
  }
}

export default TestForm;
