import React from 'react';
import { Link } from 'react-router-dom';
import './carThumbnail.scss';
import PropTypes from 'prop-types';
import { getCarFullName } from '../../../services/CarService';
import CarThumbnailDetailTable from './carThumbnailDetailTable';
import unknown from '../../../assets/ukjent.png';
import { isMobile } from 'react-device-detect';
import FlipCameraAndroidRoundedIcon from '@material-ui/icons/FlipCameraAndroidRounded';
import { getCarNameWithParent } from '../../../services/CarService';
import { useState } from 'react';
import ParentCarThumbnailDetailTable from './parentCarThumbnailDetailTable';
import ChildCarThumbnailDetailTable from './childCarThumbnailDetailTable';

function WhiteDetailedCarThumbnail(props) {

  const [state, setState] = useState(true);
  const handleClick = () => setState(!state);

  const showParent = () => {
    if (props.car.length < 2)
      return (
        <Link
          to={{
            pathname: '/cars/' + props.car[0].car_id,
          }}
        >
          <div className="car-thumbnail-box all-radius thumbnail-list-style">
            <div className="header">
              <img
                alt=""
                style={{ objectFit: isMobile ? 'contain' : 'cover' }}
                src={props.car[0].main_img_url !== null ? props.car[0].main_img_url : unknown}
              ></img>
              <h3>{getCarFullName(props.car[0])}</h3>
            </div>
            <div className="table-view-white-detailed">
              <CarThumbnailDetailTable car={props.car[0]} />
            </div>
          </div>
        </Link>
      )
    //Implicit ELSE
    return(
      <div className="car-thumbnail-box all-radius thumbnail-list-style parent-animation">
        <div className="header">
          <FlipCameraAndroidRoundedIcon
           className="flip-round-icon"
           onClick={handleClick} 
          />
          <img
            alt=""
            style={{ objectFit: isMobile ? 'contain' : 'cover' }}
            src={props.car[0].main_img_url !== null ? props.car[0].main_img_url : unknown}
          ></img>
          <h3>{getCarNameWithParent(props.car[0])}</h3>
        </div>
        <div className="table-view-white-detailed">
          <ParentCarThumbnailDetailTable car={props.car} />
        </div>
      </div>
    )
  }

  const showChild = () => {
    return (
      <div
        className="car-thumbnail-box all-radius thumbnail-list-style child-animation"
        style={{width: "300px" }}
      >
        <div className="header">
          <FlipCameraAndroidRoundedIcon
            className="flip-round-icon"
            onClick={handleClick}
          />
          <h3>{getCarNameWithParent(props.car[0])}</h3>
        </div>
        <div
          className="table-view-white-detailed table-view-white-detailed-child"
          style={{ maxHeight: "300px", overflow: "hidden"}}
        >
          <ChildCarThumbnailDetailTable car={props.car} />
        </div>
      </div>
    )
  }
  return (
    state ? showParent() : showChild()
  );
}

WhiteDetailedCarThumbnail.propTypes = {
  car: PropTypes.array.isRequired,
};

export default WhiteDetailedCarThumbnail;
