import React from 'react';
import CarSummaryItem from './carSummaryItem';
import { CAR_VALUE_DISPLAY_NAMES, UNITS } from '../../../constants';
import paymenticon from '../../../assets/payment.png';
import rangeicon from '../../../assets/rangeicon.svg';
import chargeicon from '../../../assets/chargeicon.svg';
import trunkicon from '../../../assets/trunkicon.svg';
import othericon from '../../../assets/othericon.svg';
import ValueDisplayPipe from './valueDisplayPipe';

function CarSummaryList(props) {
  let car = props.summary;
  let summary = car.summary[0];

  function doNotReturnZero(value) {
    if (value > 0 || (typeof value === 'string' && value !== undefined && value !== "")) {
      return value;
    }
  }

  function donotReturnZeroForNull(trunk_space, frunk_space){
    if (trunk_space === null && frunk_space === null) return null;
    else return + trunk_space + + frunk_space;
  }

  function rangeValue() {
    if (summary.range_wltp) { return summary.range_wltp } 
    else if (summary.range_nedc) { return summary.range_nedc } 
    else if (summary.range_summer){return summary.range_summer} 
    else if (summary.range_winter){return summary.range_winter}
  }

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return `${hours}t${minutes > 0 ? ` ${minutes}m` : ''}`;
  }

  return (
    <div>
      {/* Price */}
      <CarSummaryItem
        icon={paymenticon}
        unit={UNITS.money}
        header={
          <ValueDisplayPipe
            value={summary.basic_model_price_nok > 0 ? summary.basic_model_price_nok : "Ukjent" }
            unit={summary.basic_model_price_nok > 0 ? UNITS.money : ''}
          />
        }
        description={CAR_VALUE_DISPLAY_NAMES['basic_model_price_nok']}
        tableInfo={
          [
            // {
            //   name: CAR_VALUE_DISPLAY_NAMES['basic_model_price_nok'],
            //   item: summary.basic_model_price_nok,
            // },
            // {
            //   name: CAR_VALUE_DISPLAY_NAMES['premium_model_price_nok'],
            //   item: doNotReturnZero(summary.premium_model_price_nok),
            // },
          ]
        }
      ></CarSummaryItem>
      {/* Range */}
      <CarSummaryItem
        icon={rangeicon}
        unit={UNITS.distance}
        header={<ValueDisplayPipe value={rangeValue()} unit={UNITS.distance} />}
        description="Rekkevidde"
        tableInfo={[
          {
            name: CAR_VALUE_DISPLAY_NAMES['range_in_km_wltp'],
            item: doNotReturnZero(summary.range_wltp),
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['range_in_km_nedc'],
            item: doNotReturnZero(summary.range_nedc),
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['range_estimate_km_summer'],
            item: doNotReturnZero(summary.range_summer),
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['range_estimate_km_winter'],
            item: doNotReturnZero(summary.range_winter),
          },
        ]}
      ></CarSummaryItem>
      {/* Charge time */}
      <CarSummaryItem
        icon={chargeicon}
        header={
          <ValueDisplayPipe
            value={toHoursAndMinutes(summary.fast_charge_time)}
          />
        }
        description = {CAR_VALUE_DISPLAY_NAMES['fast_charge_time']}
        tableInfo={[
          {
            name: CAR_VALUE_DISPLAY_NAMES['fast_charge_time'],
            item: toHoursAndMinutes(doNotReturnZero(summary.fast_charge_time)),
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['normal_charge_time'],
            item: toHoursAndMinutes(doNotReturnZero(summary.normal_charge_time)),
          }
        ]}
      ></CarSummaryItem>
      {/* Trunk room */}
      <CarSummaryItem
        icon={trunkicon}
        unit={UNITS.liquid}
        header={
          <ValueDisplayPipe
            value={donotReturnZeroForNull(summary.trunk_space, summary.frunk_space)}
            unit={UNITS.liquid}
          />
        }
        description="Bagasjerom"
        tableInfo={[
          {
            name: CAR_VALUE_DISPLAY_NAMES['frunk'],
            item: summary.frunk_space,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['trunk'],
            item: summary.trunk_space,
          },
        ]}
      ></CarSummaryItem>
      {/* Other */}
      <CarSummaryItem
        icon={othericon}
        header="Annet"
        description="Andre viktige egenskaper"
        tableInfo={[
          {
            name: CAR_VALUE_DISPLAY_NAMES['drivetrain_layout'],
            item: summary.drivetrain_layout,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['gross_battery_capacity'],
            item: summary.gross_battery_capacity,
            unit: UNITS.battery,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['active_temperature_control_battery_pack'],
            item: summary.active_temperature_control_battery_pack,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['heat_pump'],
            item: summary.heat_pump,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['charging_via_app'],
            item: summary.charging_via_app,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['preheat_via_app'],
            item: summary.preheat_via_app,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['towing_hitch'],
            item: summary.towing_hitch,
          },
          {
            name: CAR_VALUE_DISPLAY_NAMES['max_roof_load'],
            item: summary.max_roof_load,
            unit: UNITS.weight,
          }        
        ]}
      ></CarSummaryItem>
    </div>
  );
}

export default CarSummaryList;
