import React from 'react';
import '../../pages/adminPage/adminPage.scss';
import PlusMinusForm from './plusMinusForm';
import * as carService from '../../services/CarService';
import WithLoading from '../withLoading';
import Grid from '@material-ui/core/Grid';
const PlusMinusFormWithLoading = WithLoading(PlusMinusForm);

class PlusMinusManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarObject: this.props.selectedCarObject,
      created: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCarObject !== this.props.selectedCarObject) {
      this.setState({ selectedCarObject: this.props.selectedCarObject });
    }
  }
  create = async (carId, plusMinus) => {
    this.setState({ isLoading: true });
    await carService.postCarFromIdAndAttribute(carId, 'plusminus', [plusMinus]);

    let chosenCar = await carService.getAllJoinDataById(carId);
    let carSections = await carService.getSections(carId);
    let fullCarInformation = { ...chosenCar, ...carSections };
    this.setState({
      selectedCarObject: fullCarInformation,
      created: true,
      isLoading: false,
    });
    return this.state.created;
  };

  update = async (carId, plusMinus) => {
    await carService.updateCarFromIdAndAttribute(carId, 'plusminus', [
      plusMinus,
    ]);
  };

  delete = async (carId, plusMinusId) => {
    let result = await carService.deleteFromCarFromIdAndAttributeId(
      carId,
      'plusminus',
      [+plusMinusId]
    );
    if (result instanceof Error) {
      return;
    } else {
      console.log('deleted!');
    }
  };

  render() {
    return (
      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <div style={{ display: 'flex', maxWidth: '500px' }}>
            <PlusMinusFormWithLoading
              isLoading={this.state.isLoading}
              selectedCarObject={this.state.selectedCarObject}
              createClick={(carId, plusMinus) => this.create(carId, plusMinus)}
            />
          </div>
          <section className="plusMinusContainer">
            <div style={{ marginRight: '10px' }}>
              {this.state.selectedCarObject.plusMinus['plus'].map(
                (plus, index) => {
                  return (
                    <PlusMinusFormWithLoading
                      key={index}
                      selectedCarObject={this.state.selectedCarObject}
                      plus_minus_type="plus"
                      chosenPlusMinus={plus}
                      updateClick={(carId, plus) => this.update(carId, plus)}
                      deleteClick={(carId, plusId) =>
                        this.delete(carId, plusId)
                      }
                    />
                  );
                }
              )}
            </div>
            <div style={{ marginRight: '10px' }}>
              {this.state.selectedCarObject.plusMinus['minus'].map(
                (minus, index) => {
                  return (
                    <PlusMinusForm
                      key={index}
                      selectedCarObject={this.state.selectedCarObject}
                      plus_minus_type="minus"
                      chosenPlusMinus={minus}
                      updateClick={(carId, minus) => this.update(carId, minus)}
                      deleteClick={(carId, minusId) =>
                        this.delete(carId, minusId)
                      }
                    />
                  );
                }
              )}
            </div>
          </section>
        </div>
      </Grid>
    );
  }
}

export default PlusMinusManager;
