import React from 'react';

import './childCarThumbnail.scss';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { Select } from '@material-ui/core';

function ChildCarThumbnailDetailTable(props) {
  const [selectedState, setSelectedState] = React.useState('release_year');

  const handleRowClick = (car) => {
    const dataLayer = window.dataLayer || [];
    const modelName = car.model_name + ' ' + car.model_edition_name ?? '';
    dataLayer.push({
      event: 'merInformasjon',
      eventCallback: function () {
        window.location.href = '/cars/' + car.car_id;
      },
      merke: car.brand_name,
      modell: modelName,
    });
  };

  const [page, setPage] = React.useState(0);
  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function getRange(car) {
    if (car.range_wltp > 0) return car.range_wltp;
    if (car.range_nedc > 0) return car.range_nedc;
    if (car.range_summer > 0) return car.range_summer;
    if (car.range_winter > 0) return car.range_winter;
    return 'Ukjent';
  }

  function getSortedCars() {
    const sortValue = selectedState.replace('_desc', '');
    const sortedCars = [].concat(props.car);

    if (selectedState === 'range') {
      sortedCars.sort((a, b) => {
        if (getRange(a) > getRange(b)) return 1;
        if (getRange(a) < getRange(b)) return -1;
        return 0;
      });
    } else if (selectedState === 'range_desc') {
      sortedCars.sort((a, b) => {
        if (getRange(a) < getRange(b)) return 1;
        if (getRange(a) > getRange(b)) return -1;
        return 0;
      });
    } else if (selectedState.includes('_desc')) {
      sortedCars.sort((a, b) => {
        if (a[sortValue] < b[sortValue]) return 1;
        if (a[sortValue] > b[sortValue]) return -1;
        return 0;
      });
    } else {
      sortedCars.sort((a, b) => {
        if (a[sortValue] > b[sortValue]) return 1;
        if (a[sortValue] < b[sortValue]) return -1;
        return 0;
      });
    }

    return sortedCars;
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.car.length - page * rowsPerPage);

  const showChildTable = () => {
    return getSortedCars()
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((car) => {
        let range = getRange(car);
        return (
          <tr
            key={car.car_id}
            onClick={() => {
              handleRowClick(car);
            }}
          >
            <td className="td-car">
              {car.model_edition_name ? car.model_edition_name : car.model_name}
            </td>
            <td
              className="td-car"
              style={{ textAlign: 'right', color: 'black' }}
            >
              {selectedState.includes('range')
                ? range + ' km'
                : selectedState.includes('basic_model_price')
                ? car.basic_model_price_nok
                : car.release_year}
            </td>
          </tr>
        );
      });
  };

  return (
    <TableContainer>
      <table>
        <thead className="child-thead">
          <tr className="row-main">
            <td className="row-child-1"> Modellversjon </td>
            <td className="row-child-2">
              <Select
                name="carOptions"
                labelId="car_options"
                onChange={(e) => setSelectedState(e.target.value)}
                value={selectedState}
                className="selectfield"
                native={true}
              >
                <option value="release_year">Lanseringsår stigende</option>
                <option value="release_year_desc">Lanseringsår synkende</option>
                <option value="range">Rekkevidde stigende</option>
                <option value="range_desc">Rekkevidde synkende</option>
                <option value="basic_model_price_nok">Pris stigende</option>
                <option value="basic_model_price_nok_desc">
                  Pris synkende
                </option>
              </Select>
            </td>
          </tr>
        </thead>
        <tbody className="child-tbody">
          {showChildTable()}

          {
            //Used to insert empty rows at the end of table to maintain coherent size.
            emptyRows > 0 && (
              <tr style={{ height: 21 * emptyRows }}>
                <td colSpan={6} />
              </tr>
            )
          }
        </tbody>
      </table>

      <TablePagination
        className="child-pagination"
        component="div"
        rowsPerPageOptions={[]}
        count={props.car.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
      />
    </TableContainer>
  );
}

export default ChildCarThumbnailDetailTable;
