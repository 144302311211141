import React from 'react';
import { Link } from 'react-router-dom';
import './carThumbnail.scss';
import PropTypes from 'prop-types';
import { getCarFullName } from '../../../services/CarService';
import { getCarNameWithParent } from '../../../services/CarService';
import CarThumbnailDetailTable from './carThumbnailDetailTable';
import ParentCarThumbnailDetailTable from './parentCarThumbnailDetailTable';
import ChildCarThumbnailDetailTable from './childCarThumbnailDetailTable';
import unknown from '../../../assets/ukjent.png';
import { useState } from 'react';
import FlipCameraAndroidRoundedIcon from '@material-ui/icons/FlipCameraAndroidRounded';

function ColumnView(props) {
  const [parentView, setParentView] = useState(true);
  const handleClick = () => setParentView(!parentView);

  const showChild = () => (
    <div
      className="darkbluebackground thumbnail-list-style-square child-animation"
      style={{ paddingTop: '0px', minHeight: '274px' }}
    >
      <FlipCameraAndroidRoundedIcon
        className="flip-round-icon"
        onClick={handleClick}
      />
      <div className="whitebackground-cv">
        <h3
          className="h3-car-cv"
          style={{ padding: '25px', paddingBottom: '0px' }}
          onClick={handleClick}
        >
          {getCarNameWithParent(props.car[0])}
        </h3>
        <div
          className="table-view"
          style={{ height: '200px', overflow: 'hidden' }}
        >
          <ChildCarThumbnailDetailTable car={props.car} />
        </div>
      </div>
    </div>
  );

  const showParent = () => {
    if (props.car.length < 2)
      return (
        <Link
          to={{
            pathname: '/cars/' + props.car[0].car_id,
          }}
        >
          <div className="darkbluebackground-cv thumbnail-list-style-square">
            <img
              alt=""
              src={
                props.car[0].main_img_url !== null
                  ? props.car[0].main_img_url
                  : unknown
              }
              className="img-car-cv"
            />
            <div className="whitebackground-cv">
              <h3 className="h3-car-cv">{getCarFullName(props.car[0])}</h3>
              <div className="table-view-cv">
                {/* send in only 4 constants here, because of the transition */}
                <CarThumbnailDetailTable car={props.car[0]} />
              </div>
            </div>
          </div>
        </Link>
      );

    return (
      <div
        className="darkbluebackground-cv thumbnail-list-style-square parent-animation"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <FlipCameraAndroidRoundedIcon
          className="flip-round-icon"
          style={{ color: '#d3d8d5' }}
        />
        <img
          alt=""
          src={
            props.car[0].main_img_url !== null
              ? props.car[0].main_img_url
              : unknown
          }
          className="img-car-cv"
        />
        <div className="whitebackground-cv">
          <h3 className="h3-car-cv">{getCarNameWithParent(props.car[0])}</h3>
          <div className="table-view-cv">
            <ParentCarThumbnailDetailTable car={props.car} />
          </div>
        </div>
      </div>
    );
  };

  return parentView ? showParent() : showChild();
}

ColumnView.propTypes = {
  car: PropTypes.array.isRequired,
};

export default ColumnView;
