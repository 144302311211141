import React from 'react';
import './adminPage.scss';
import { getLocalStorageItem } from '../../helpers/localStorageHelpers';
import * as carService from '../../services/CarService';
import * as authService from '../../services/AuthService';
import ListOfAllCars from '../../components/adminPage/listOfAllCars';
import ArticleManager from '../../components/adminPage/articleManager';
import TestManager from '../../components/adminPage/testManager';
import CarBasicInfoManager from '../../components/adminPage/carBasicInfoManager';
import EquipmentManager from '../../components/adminPage/equipmentManager';
import BatteryManager from '../../components/adminPage/batteryManager';
import PlusMinusManager from '../../components/adminPage/plusMinusManager';
import FeedbackManager from '../../components/adminPage/feedbackManager';
import WithLoading from '../../components/withLoading';
import {
  TextField,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Fab,
  Tooltip,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TechnicalDataManager from '../../components/adminPage/technicalDataManager';
import CircularProgress from '@material-ui/core/CircularProgress';

class AdminPage extends React.Component {
  state = {
    hasAdminAccess: false,
    allCars: [],
    isLoading: true,
    isLoadingCar: true,
    allCarsAfterSearch: [],
    selectedCar: null,
    creatingCar: false,
    tabIndexSelected: 0,
    justDeletedCar: false,
    isCopying: false,
  };

  async componentDidMount() {
    const { history } = this.props;
    let adminAccess = await this.checkAdminAccess();
    this.setState({ hasAdminAccess: adminAccess });
    this.state.hasAdminAccess ? this.getData() : history.push('/login');
  }

  checkAdminAccess = async () => {
    let encpw = getLocalStorageItem('AuthEntry');
    if (encpw) {
      const encryptedPW = {
        password: encpw,
      };
      let response = await authService.verifyAdminAccess(encryptedPW);
      if (response.adminAccess) {
        return true;
      }
      return false;
    }
  };

  getData = async () => {
    const allCars = await this.getCars();
    const technicalData = await this.getTechnicalData();
    const batteryData = await this.getBatteryData();
    const equipmentData = await this.getEquipmentData();
    this.setState({
      allCars: allCars,
      technicalData: technicalData,
      batteryData: batteryData,
      equipmentData: equipmentData,
      isLoading: false,
      allCarsAfterSearch: allCars,
    });
  };

  getCars = async () => {
    let carResults = await carService.getCars();
    if (!carResults) {
      carResults = [];
    }
    return carResults;
  };

  getTechnicalData = async () => {
    let technicalData = await carService.getTechnicalData();
    if (!technicalData) {
      technicalData = [];
    }
    return technicalData;
  };

  getBatteryData = async () => {
    let batteryData = await carService.getBatteryData();
    if (!batteryData) {
      batteryData = [];
    }
    return batteryData;
  };
  getEquipmentData = async () => {
    let equipmentData = await carService.getEquipmentData();
    if (!equipmentData) {
      equipmentData = [];
    }
    return equipmentData;
  };

  updateSearch = (value) => {
    let relevantCars = this.state.allCars.filter((car) => {
      return (
        carService
          .getCarFullName(car)
          .toLowerCase()
          .indexOf(value.toLowerCase()) > -1
      );
    });
    this.setState({
      allCarsAfterSearch: relevantCars,
    });
  };

  selectCar = async (carId) => {
    this.setState({ isLoadingCar: true, creatingCar: false });
    let chosenCar = await carService.getAllJoinDataById(carId);
    let carSections = await carService.getSections(carId);
    let fullCarInformation = { ...chosenCar, ...carSections };
    this.setState({
      selectedCar: fullCarInformation,
      isLoadingCar: false,
    });
  };

  clickCreateCar = () => {
    this.setState({
      creatingCar: true,
      selectedCar: null,
    });
  };

  copySelectedCar = () => {
    this.setState({ isCopying: true }, () => {
      carService.copyCar(this.state.selectedCar.car.car_id).then((res) => {
        this.setState({ isCopying: false }, () => {
          if (res.status !== 500) {
            this.getData();
            this.selectCar(res);
          } else {
            console.log('Something went wrong while copying car! ', res);
          }
        });
      });
    });
  };

  clickDeleteCar = async (carId) => {
    await carService.deleteCar(carId);
    this.setState({
      justDeletedCar: true,
      selectedCar: null,
    });
    this.getData();
  };

  handleTabChange = (event, newValue) => {
    this.setState({
      tabIndexSelected: newValue,
    });
  };

  handleCopyCarButton = (params) => {
    if (this.state.selectedCar && this.state.isCopying) {
      return (
        <Tooltip
          title="Kopier laster"
          aria-label="copyCarLoading"
          style={{ margin: '5px' }}
        >
          <span>
            <Fab size="small" color="secondary" aria-label="copyLoading">
              <CircularProgress />
            </Fab>
          </span>
        </Tooltip>
      );
    } else if (this.state.selectedCar) {
      return (
        <Tooltip
          title="Kopier valgt bil"
          aria-label="copyCar"
          style={{ margin: '5px' }}
        >
          <span>
            <Fab
              size="small"
              color="primary"
              aria-label="copy"
              disabled={this.state.copyDisabled}
              onClick={this.copySelectedCar}
            >
              <FileCopyIcon />
            </Fab>
          </span>
        </Tooltip>
      );
    }
  };

  render() {
    return (
      <div className="master-container">
        <section className="carListContainer">
          <div className="search-and-add">
            <TextField
              id="outlined-basic"
              placeholder="Søk"
              variant="outlined"
              onChange={(e) => this.updateSearch(e.target.value)}
              className="visible-textfield"
            />
            <Tooltip title="Lag ny bil" aria-label="addCar">
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={this.clickCreateCar}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
            {this.state.selectedCar && this.handleCopyCarButton()}
          </div>
          <div>
            <ListOfAllCars
              cars={this.state.allCarsAfterSearch}
              selectCar={this.selectCar}
            />
          </div>
        </section>
        {this.state.selectedCar && (
          <section className="carCategoriesFormContainer">
            <AppBar position="static">
              <Tabs
                value={this.state.tabIndexSelected}
                onChange={(e, value) => this.handleTabChange(e, value)}
                aria-label="menu tabs"
                style={{ width: '100%' }}
              >
                <Tab style={tabWidthModifier} label="Basisinfo" />
                <Tab style={tabWidthModifier} label="Teknisk info" />
                <Tab style={tabWidthModifier} label="Utstyr" />
                <Tab style={tabWidthModifier} label="Batteri" />
                <Tab style={tabWidthModifier} label="Artikkel" />
                <Tab style={tabWidthModifier} label="Tester" />
                <Tab style={tabWidthModifier} label="Pluss/minus" />
                <Tab style={tabWidthModifier} label="Tilbakemelding" />
              </Tabs>
            </AppBar>
            <TabPanel value={this.state.tabIndexSelected} index={0}>
              <CarBasicInfoManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                isLoading={this.state.isLoadingCar}
              />
            </TabPanel>
            <TabPanel value={this.state.tabIndexSelected} index={1}>
              <TechnicalDataManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                technicalData={this.state.technicalData}
                isLoading={this.state.isLoadingCar}
              />
            </TabPanel>
            <TabPanel value={this.state.tabIndexSelected} index={2}>
              <EquipmentManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                isLoading={this.state.isLoadingCar}
                equipmentData={this.state.equipmentData}
              />
            </TabPanel>
            <TabPanel value={this.state.tabIndexSelected} index={3}>
              <BatteryManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                isLoading={this.state.isLoadingCar}
                batteryData={this.state.batteryData}
              />
            </TabPanel>
            <TabPanel value={this.state.tabIndexSelected} index={4}>
              <ArticleManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                isLoading={this.state.isLoadingCar}
              />
            </TabPanel>
            <TabPanel value={this.state.tabIndexSelected} index={5}>
              <TestManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                isLoading={this.state.isLoadingCar}
              />
            </TabPanel>

            <TabPanel value={this.state.tabIndexSelected} index={6}>
              <PlusMinusManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                isLoading={this.state.isLoadingCar}
              />
            </TabPanel>
            <TabPanel value={this.state.tabIndexSelected} index={7}>
              <FeedbackManagerWithLoading
                selectedCarObject={this.state.selectedCar}
                isLoading={this.state.isLoadingCar}
              />
            </TabPanel>

            <Button
              variant="contained"
              color="secondary"
              className="deleteButton"
              onClick={() => {
                if (
                  window.confirm(
                    'ADVARSEL: Er du sikker på at du vil slette denne bilen? Dette kan ikke angres!'
                  )
                )
                  this.clickDeleteCar(this.state.selectedCar.car.car_id);
              }}
            >
              Slett {carService.getCarFullName(this.state.selectedCar.car)}
            </Button>
          </section>
        )}
        {this.state.creatingCar && (
          <section>
            <CarBasicInfoManager />
          </section>
        )}
        {this.state.justDeletedCar && <section>Deleted car</section>}
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, car, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      car={car}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
const tabWidthModifier = {
  minWidth: `100px`,
};

const ArticleManagerWithLoading = WithLoading(ArticleManager);
const TestManagerWithLoading = WithLoading(TestManager);
const CarBasicInfoManagerWithLoading = WithLoading(CarBasicInfoManager);
const EquipmentManagerWithLoading = WithLoading(EquipmentManager);
const BatteryManagerWithLoading = WithLoading(BatteryManager);
const TechnicalDataManagerWithLoading = WithLoading(TechnicalDataManager);
const PlusMinusManagerWithLoading = WithLoading(PlusMinusManager);
const FeedbackManagerWithLoading = WithLoading(FeedbackManager);

export default AdminPage;
