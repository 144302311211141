import React from 'react';
import './carDetailsList.scss';
import DetailsToTable from './detailsToTable';
import ErrorIcon from '@material-ui/icons/Error';
import { isMobile } from 'react-device-detect';

function CarDetailsList(props) {
  const CAR_DETAILS_OBJECT = props.carDetails;
  return (
    <section className="details-section">
      {isMobile ? (
        <div className="disclaimerParrent">
          <ErrorIcon className="disclaimerIcon" style={{ fontSize: '45px' }} />
          <p className="disclaimerText">
            Vi minner om at informasjonen på bilene er basert på offisiell data,
            data fra bilmerkenes nettsider og egne tester.
          </p>
        </div>
      ) : (
        <div className="disclaimer">
          <ErrorIcon
            className="disclaimerIcon"
            style={{ fontSize: '55px', float: 'left', marginRight: '25px' }}
          />
          <div>
            Vi minner om at informasjonen på bilene er basert på offisiell data,
            data fra bilmerkenes nettsider og egne tester.
          </div>
        </div>
      )}

      <div>
        <DetailsToTable car_details={CAR_DETAILS_OBJECT} />
      </div>
    </section>
  );
}

export default CarDetailsList;
