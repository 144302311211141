import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Input } from '@material-ui/core';
import * as authService from './services/AuthService';
import { setLocalStoragePassword } from './helpers/localStorageHelpers';
import './App.scss';

class Login extends React.Component {
  state = {
    writtenPassword: '',
    formValErrorMessage: '',
  };

  setPassword = pw => {
    this.setState({
      writtenPassword: pw,
    });
  };
  handleSubmit = async event => {
    event.preventDefault();
    const { history } = this.props;

    const password = {
      password: this.state.writtenPassword,
    };

    try {
      let response = await authService.loginWithPassword(password);
      if (response.status === 403)
        throw new Error('Du har ingen tilgang. Feil passord');
      setLocalStoragePassword(response.encryptedPassword);
      history.push('/admin');
    } catch (e) {
      this.setState({
        formValErrorMessage: 'Feil passord',
      });
    }
  };


  render() {
    const redirectToReferrer = this.state.redirectToReferrer;

    if (redirectToReferrer) {
      return <Redirect to="/admin" />;
    }

    return (
      <div className="login">
        <div className="form-container all-radius">
          <form onSubmit={e => this.handleSubmit(e)}>
            <Input
              placeholder="Passord"
              value={this.state.password}
              onChange={e => this.setPassword(e.target.value)}
              type="password"
            />
            <br />
            <Button variant="contained" color="primary" type="submit">
              Login
            </Button>
            <br />
            <p>{this.state.formValErrorMessage}</p>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
