import React from 'react';
import './filterItems.scss';
import LabelTagDisplay from '../labelTagDisplay';
import SearchField from '../searchField';
import { Grid } from '@material-ui/core';
import IntermediatCheckbox from './intermediatCheckbox';
import IntermediatRadio from './intermediatRadio';
import IntermediatSlider from './intermediatSlider';

function ConditionalSelector(props) {
  const checkboxRadioChange = (e, filterID) => {
    let tempArray = [];

    let tempFilterHolder = props.queryFilter.find((x) => x.id === filterID);

    if (e.target.type === 'checkbox') {
      if (tempFilterHolder !== undefined) {
        tempArray = tempFilterHolder.alternatives;

        if (!e.target.checked) {
          tempArray = tempArray.filter((x) => x !== e.target.name);
          props.handleFilterRadioCheckboxValueChanges(
            filterID,
            props.filterCategories.category_id,
            tempArray
          );
        } else {
          tempArray.push(e.target.name);
          props.handleFilterRadioCheckboxValueChanges(
            filterID,
            props.filterCategories.category_id,
            tempArray
          );
        }
      } else {
        tempArray.push(e.target.name);
        props.handleFilterRadioCheckboxValueChanges(
          filterID,
          props.filterCategories.category_id,
          tempArray
        );
      }
    } else if (e.target.type === 'radio') {
      tempArray.push(e.target.name);
      props.handleFilterRadioCheckboxValueChanges(
        filterID,
        props.filterCategories.category_id,
        tempArray
      );
    }
  };

  const alt = props.filterCategories.filters[0].alternatives;
  const [searchLabels, setSearchLabels] = React.useState([]);
  React.useReducer(() => {
    setSearchLabels(props.filterCategories.filters[0].alternatives);
  }, [alt]);

  const handleSearchChange = (characters) => {
    let searchLabels = alt.filter(
      (label) => label.toLowerCase().indexOf(characters.toLowerCase()) > -1
    );
    setSearchLabels(searchLabels);
  };

  if (props.filterCategories.category_name.toLowerCase() === 'merke') {
    return (
      <React.Fragment>
        <h1 className="header-fix-label">
          {props.filterCategories.filters[0].name}
        </h1>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <SearchField
              handleChange={(characters) => handleSearchChange(characters)}
              isLabel={true}
            ></SearchField>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <LabelTagDisplay
          searchLabels={searchLabels}
          activeLabels={props.activeBrand}
          handleClick={(label) =>
            props.handleActiveBrands(
              label,
              props.filterCategories.filters[0].id,
              props.filterCategories.category_id
            )
          }
          filter={props.filterCategories.filters[0]}
        />
        <Grid container spacing={2}>
          <Grid item xs={11}></Grid>
          <Grid item xs={1}></Grid>
          <>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {props.filterCategories.filters[0].help_text}
            </Grid>
            <Grid item xs={1}></Grid>
          </>
        </Grid>
      </React.Fragment>
    );
  }
  return props.filterCategories.filters.map((filter, i) => {
    if (filter.type.toLowerCase() === 'checkbox') {
      return (
        <IntermediatCheckbox
          filter={filter}
          checkboxRadioChange={checkboxRadioChange}
          key={i}
          queryFilter={props.queryFilter}
        ></IntermediatCheckbox>
      );
    } else if (filter.type.toLowerCase() === 'radio') {
      return (
        <IntermediatRadio
          filter={filter}
          checkboxRadioChange={checkboxRadioChange}
          key={i}
          queryFilter={props.queryFilter}
          handleFilterRadioCheckboxValueChanges={
            props.handleFilterRadioCheckboxValueChanges
          }
        ></IntermediatRadio>
      );
    } else if (filter.type.toLowerCase() === 'slider') {
      return (
        <IntermediatSlider
          filter={filter}
          key={i}
          filterCategories={props.filterCategories.category_id}
          queryFilter={props.queryFilter}
          handleSliderValueChange={props.handleSliderValueChange}
        ></IntermediatSlider>
      );
    }
    // eslint-disable-next-line
    return;
  });
}

export default ConditionalSelector;
