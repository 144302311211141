import React from 'react';
import '../../pages/indexPage/main-container.scss';

function WhiteShell(props) {
  return (
    <div className="white-container" style={props.style}>
      {props.children}
    </div>
  );
}

export default WhiteShell;
