import React from 'react';
import {
  CARDETAILS_LIST_ORDER,
  CAR_STATUS_ALTERNATIVES,
  TIRE_DRIVE_VALUE_MAP,
  FAST_CHARGE_LOCATION
} from '../../../constants';

import DetailsDisclaimerHover from './detailsDisclaimerHover';

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}t${minutes > 0 ? ` ${minutes}m` : ''}`;
}

function getDisplayValue(item) {
  if (item.categoryName === 'Markedsstatus') {
    return CAR_STATUS_ALTERNATIVES
      .find(car_status_alternative => car_status_alternative.value === item.value)
      .display_value;
  }

  //Drivhjul total
  if(item.categoryId === 104) {
    return TIRE_DRIVE_VALUE_MAP[item.value]
  }

  //Hurtiglader ladekontakt
  if(item.categoryId === 1220) {
    return FAST_CHARGE_LOCATION.hasOwnProperty(item.value) ? FAST_CHARGE_LOCATION[item.value] : "Ukjent"
  }

  //Ladetid normallading, Ladetid hurtiglading
  if(item.categoryId === 1206 || item.categoryId === 1207) {
    return toHoursAndMinutes(item.value)
  };

  return item.value;
}

function DetailsToTable(props) {
  return CARDETAILS_LIST_ORDER.map((number, i) => (
    <div
      key={i}
      className="details-list-element contentimgdiv column allradius darkshadow"
    >
      <h2>{number}</h2>
      <div className="tableContainer">
        <table>
          <tbody>
            {props.car_details[number].map((item, index) => {
              return (
                <tr key={index}>
                  <td className="tableContent">{item.categoryName}</td>
                  <td className="tableContent">{getDisplayValue(item)}</td>
                  <DetailsDisclaimerHover
                    category_id={item.categoryId}
                  ></DetailsDisclaimerHover>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ));
}
export default DetailsToTable;
