// This service contains functions that you can use to connect to the API (for now created in Node).
// Function names should be self explanatory.

import * as baseService from './BaseService';
const resource = '/cars';
const resource_vehicle = '/chargetrip/vehicles';

export function getCars() {
  return baseService.get(resource);
}

export async function getSectionFromId(id, section) {
  return baseService.getSectionById(resource, id, section);
}

export async function getAllJoinDataById(id) {
  return baseService.getAllJoinDataById(resource, id);
}

export function getBrandsRegistered() {
  return baseService.get(resource + '/brands');
}

export function sendCarQueries(queries) {
  return baseService.post('/results', queries);
}

export function getCarsFromQueries(queries) {
  return baseService.get(resource + '/q?' + queries);
}

export function getTechnicalData() {
  return baseService.get(resource + '/technicaldata');
}

export function getBatteryData() {
  return baseService.get(resource + '/battery');
}

export function getEquipmentData() {
  return baseService.get(resource + '/equipment');
}
export function getSpecificCarDetails(carId) {
  return baseService.get(resource + '/' + carId + '/details');
}

/////////////////////////////
///////////POST//////////////
/////////////////////////////

export async function createCar(value) {
  return baseService.post(resource, value);
}

/////////////////////////////
///////////PUT//////////////
/////////////////////////////

export async function updateCarWithCtData(value) {
  return baseService.put(resource_vehicle + '/' + value);
}

// Can post things like articles, tests, etc
export async function postCarFromIdAndAttribute(carId, attribute, value) {
  return baseService.post(resource + '/' + carId + '/' + attribute, value);
}

export async function copyCar(carId) {
  return await baseService.post(resource + '/' + carId + '/copy');
}

/////////////////////////////
///////////PATCH//////////////
/////////////////////////////

export async function updateCar(value) {
  return baseService.patch(resource, value);
}

export async function updateCarFromIdAndAttribute(carId, attribute, value) {
  return baseService.patch(resource + '/' + carId + '/' + attribute, value);
}

/////////////////////////////
///////////DELETE//////////////
/////////////////////////////

export async function deleteCar(carId) {
  return baseService.destroy(resource + '/' + carId);
}

export async function deleteFromCarFromIdAndAttributeId(
  carId,
  attribute,
  attributeId
) {
  return baseService.destroyAttribute(
    resource + '/' + carId + '/' + attribute,
    attributeId
  );
}

/////////////////////////////
/////GENERAL FUNCTIONS///////
/////////////////////////////

export function getCarFullName(car) {
  let name = '';
  if (car.brand_name) {
    name += car.brand_name;
    if (car.model_name) {
      name += ' ' + car.model_name;
      if (car.model_edition_name) {
        name += ' ' + car.model_edition_name;
      }
    }
  }
  return name;
}

export function getCarNameWithParent(car) {
  let name = '';
  if (car.parent_car) {
    name += car.parent_car;
  }
  return name;
}

export function getObjectValue(propertyObject) {
  if (propertyObject) {
    if (propertyObject instanceof Object) {
      return propertyObject.value;
    } else {
      return propertyObject;
    }
  }
  return undefined;
}

export function getObjectUnit(propertyObject) {
  if (propertyObject) {
    if (propertyObject.unit) {
      let unit = lowerFirstLetter(propertyObject.unit);
      if (unit === 'timer' && +propertyObject.value === 1) {
        unit = 'time';
      }
      return unit;
    }
  }
  return undefined;
}

export async function getSection(id, sectionName) {
  let result = await getSectionFromId(id, sectionName);

  if (result instanceof Error) {
    return;
  }
  return result;
}

export async function getSections(id) {
  let resultFeedback = await getSection(id, 'feedback');
  let resultPlusMinus = await getSection(id, 'plusminus');
  let resultArticle = await getSection(id, 'articles');
  let resultTest = await getSection(id, 'tests');
  let car = {};
  car.feedback = resultFeedback;
  car.plusMinus = resultPlusMinus;
  car.articles = resultArticle;
  car.tests = resultTest;

  try {
    return car;
  } catch (error) {
    console.log(error);
  }
}

export function lowerFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

/////////////////////////////
/////POSSIBLY DEPRECATED/////
/////////////////////////////

export async function ofvFilterSearch(filter) {
  let queries = {
    user: {
      username: 'remote_elbil',
      password: '1809ed1b8fe34cf84b7983ecb84ebca1',
      ipAddress: '',
    },
    filter: filter,
    vehicleGroup: '101',
    data: true,
  };
  return baseService.postFullUrl(
    'https://test-remote.kjoretoydata.no/RemoteCustomer/FilterSearch?format=json',
    queries
  );
}

export async function ofvGetCar(carId) {
  let queries = {
    user: {
      username: 'remote_elbil',
      password: '1809ed1b8fe34cf84b7983ecb84ebca1',
      ipAddress: '',
    },
    variantId: +carId,
    vehicleGroup: '101',
  };
  return baseService.postFullUrl(
    'https://test-remote.kjoretoydata.no/RemoteCustomer/GetVehicle?format=json',
    queries
  );
}
