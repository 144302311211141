import React from 'react';
import '../../pages/adminPage/adminPage.scss';
import FeedbackForm from './feedbackForm';
import * as carService from '../../services/CarService';

class FeedbackManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarObject: this.props.selectedCarObject,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCarObject !== this.props.selectedCarObject) {
      this.setState({ selectedCarObject: this.props.selectedCarObject });
    }
  }
  create = async (carId, feedback) => {
    await carService.postCarFromIdAndAttribute(carId, 'feedback', feedback);
  };

  update = async (carId, feedback) => {
    await carService.updateCarFromIdAndAttribute(carId, 'feedback', feedback);
  };

  delete = async carId => {
    let result = await carService.deleteFromCarFromIdAndAttributeId(
      carId,
      'feedback'
    );
    if (result instanceof Error) {
      return;
    } else {
      console.log('deleted!');
    }
  };

  render() {
    return (
      <div>
        <FeedbackForm
          selectedCarObject={this.state.selectedCarObject}
          feedback={
            this.state.selectedCarObject.feedback[0]
              ? this.state.selectedCarObject.feedback[0]
              : []
          }
          createClick={(carId, feedback) => this.create(carId, feedback)}
          updateClick={(carId, feedback) => this.update(carId, feedback)}
          deleteClick={carId => this.delete(carId)}
        />
      </div>
    );
  }
}

export default FeedbackManager;
