
// On "oppdater"
export const createUpdateGoogleTagData = (filtersForQuery, prevQuery) => {
    if (!filtersForQuery) {
        return null;
    }
    let result = { 'event': 'oppdaterForslag' };
    const filtersWithCheckboxes = [...filtersForQuery].filter(filter => filter.type === 'checkbox' && filterIsUpdated(filter, prevQuery));
    const filtersWithoutCheckboxes = [...filtersForQuery].filter(filter => filter.type !== 'checkbox' && filterIsUpdated(filter, prevQuery));

    if (filtersWithCheckboxes.length === 0 && filtersWithoutCheckboxes.length === 0) {
        return null;
    }

    filtersWithoutCheckboxes.forEach(filter => {
        result[filter.name] = filter.alternatives.join('-')
    })
    filtersWithCheckboxes.forEach(filter => {
        result[filter.name] = filter.alternatives.join(',')
    })
    return result;
}

// On "Foreslå biler"
export const createSubmitGoogleTagData = (filtersForQuery) => {
    if (!filtersForQuery) {
        return null;
    }
    const kriterierValgt = filtersForQuery.map(filter => filter.name).join(',');
    let result = { 'event': 'foreslaBiler', 'kriterierValgt': kriterierValgt };
    const filtersWithCheckboxes = filtersForQuery.filter(filter => filter.type === 'checkbox')
    const filtersWithoutCheckboxes = filtersForQuery.filter(filter => filter.type !== 'checkbox')
    filtersWithoutCheckboxes.forEach(filter => {
        result[filter.name] = filter.alternatives.join('-')
    })
    filtersWithCheckboxes.forEach(filter => {
        result[filter.name] = filter.alternatives.join(',')
    })
    return result;
}

const filterIsUpdated = (filter, prevQuery) => {
    let result = false;
    prevQuery.forEach((query) => {
        if (filter.id === query.id) {
            if (
                query.alternatives.length !==
                filter.alternatives.length
            ) {
                result = true;
            } else {
                if (filter.alternatives.some((alternative) => !query.alternatives.includes(alternative))) {
                    result = true;
                }
            }
        }
    })
    return result;
}