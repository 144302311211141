export function getFileNameFromUrl(url) {
  let urlParts = url.split('/');
  var name = urlParts[urlParts.length - 1].split('.')[0];
  return name;
}

export function sortByMostPopular(filtersCategory) {
  if (filtersCategory.every(Category => Category.total_times_selected === 0)) {
    return filtersCategory;
  } else {
    return filtersCategory.sort(
      (a, b) => b.total_times_selected - a.total_times_selected
    );
  }
}
