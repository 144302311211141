import * as baseService from './BaseService';
const loginResource = '/login';
const adminResource = '/admin';

export async function loginWithPassword(password) {
    return baseService.post(loginResource, password);
}

export async function verifyAdminAccess(encryptedPassword) {
    return baseService.post(adminResource, encryptedPassword)
}