import React from 'react';
import '../../pages/adminPage/adminPage.scss';
import ArticleForm from './articleForm';
import * as carService from '../../services/CarService';
import WithLoading from '../withLoading';

const ArticleFormWithLoading = WithLoading(ArticleForm);
class ArticleManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarObject: this.props.selectedCarObject,
      created: false,
      isLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCarObject !== this.props.selectedCarObject) {
      this.setState({ selectedCarObject: this.props.selectedCarObject });
    }
  }
  create = async (carId, article) => {
    this.setState({ isLoading: true });
    await carService.postCarFromIdAndAttribute(carId, 'article', [article]);

    let chosenCar = await carService.getAllJoinDataById(carId);
    let carSections = await carService.getSections(carId);
    let fullCarInformation = { ...chosenCar, ...carSections };
    this.setState({
      selectedCarObject: fullCarInformation,
      created: true,
      isLoading: false,
    });
    return this.state.created;
  };

  update = async (carId, article) => {
    await carService.updateCarFromIdAndAttribute(carId, 'article', [article]);
  };

  delete = async (carId, articleId) => {
    let result = await carService.deleteFromCarFromIdAndAttributeId(
      carId,
      'article',
      [+articleId]
    );
    if (result instanceof Error) {
      return;
    } else {
      console.log('deleted!');
    }
  };

  render() {
    return (
      <div>
        <ArticleFormWithLoading
          isLoading={this.state.isLoading}
          selectedCarObject={this.state.selectedCarObject}
          createClick={(carId, article) => this.create(carId, article)}
        />
        {this.state.selectedCarObject.articles.map((article, index) => {
          return (
            <ArticleFormWithLoading
              key={index}
              selectedCarObject={this.state.selectedCarObject}
              chosenArticle={article}
              updateClick={(carId, article) => this.update(carId, article)}
              deleteClick={(carId, articleId) => this.delete(carId, articleId)}
              created={this.state.created}
            />
          );
        })}
      </div>
    );
  }
}

export default ArticleManager;
