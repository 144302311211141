export function setLocalStoragePassword(encryptedPassword) {
  // const pass ='';
  try {
    localStorage.setItem('AuthEntry', encryptedPassword);
  } catch (e) {
    if (isQuotaExceeded(e)) {
      throw new Error('Error writing to Local Storage.');
    }
  }
}

export function getLocalStorageContainer() {
  return localStorage.getItem('AuthEntry');
}

function getLocalStorageStoredValue() {
  return localStorage.getItem('AuthEntry');
}

export function getLocalStorageItem() {
  if (getLocalStorageContainer() !== null) {
    return getLocalStorageStoredValue();
  }
}

function isQuotaExceeded(e) {
  let quotaExceeded = false;
  if (e) {
    if (e.code) {
      switch (e.code) {
        case 22:
          quotaExceeded = true;
          break;
        case 1014:
          // Firefox
          if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            quotaExceeded = true;
          }
          break;
        default:
          // something
          // Need fix
          break;
      }
    } else if (e.number === -2147024882) {
      // Internet Explorer 8
      quotaExceeded = true;
    }
  }
  return quotaExceeded;
}
