import React, { Component } from 'react';
import './App.scss';
import MainContainer from './pages/indexPage/main-container.js';
import CarResultsPage from './pages/carResultsPage/carResultsPage.js';
import { Router, Route, Switch } from 'react-router-dom';
import BlueShell from './components/containers/blueshell';
import CarInfoPage from './pages/carInfoPage/carInfoPage.js';
import NotFoundPage from './components/notFoundPage.js';
import { initGTM } from './tracking';
import Login from './loginComponent.js';
import AdminPage from './pages/adminPage/adminPage';
import history from './helpers/history';

const tagManagerArgs = {
  gtmId: 'GTM-N8C4JBC',
};

export default class AppRoutes extends Component {
  componentDidMount() {
    initGTM(tagManagerArgs);
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={['/', '/results', '/results/:query', '/cars/:id']}>
            <section className="super-container">
              <BlueShell>
                <Route exact path="/" component={MainContainer}></Route>
                <Route exact path="/results" component={CarResultsPage}></Route>
                <Route
                  exact
                  path="/results/:query"
                  component={CarResultsPage}
                ></Route>
                <Route exact path="/cars/:id" component={CarInfoPage}></Route>
              </BlueShell>
            </section>
          </Route>

          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/admin" component={AdminPage}></Route>
          <Route component={NotFoundPage}></Route>
        </Switch>
      </Router>
    );
  }
}
