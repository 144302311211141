import React from 'react';
import { Add, Remove } from '@material-ui/icons';
import './carSummaryItem.scss';

function PlusMinus(props) {
  return (
    <div>
      <div className="plus-minus-l2">
        {props.plusminus.plus.length !== 0 ||
        props.plusminus.minus.length !== 0 ? (
          <>
            <span className="plus-minus-l3">
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Add fontSize="large" className="display-nice" />
                    </td>
                    <td className="td-text">
                      <h1 className="plus-minus-word">Pluss</h1>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul className="ul-style">
                {props.plusminus.plus.map((item, key) => {
                  return (
                    <li key={key} className="li-style">
                      {item.comment}
                    </li>
                  );
                })}
              </ul>
            </span>
            <span className="plus-minus-l3">
              <table>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: 'middle' }}>
                      <Remove fontSize="large" className="display-nice" />
                    </td>
                    <td className="td-text">
                      <h1 className="plus-minus-word">Minus</h1>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ul className="ul-style">
                {props.plusminus.minus.map((item, key) => {
                  return (
                    <li key={key} className="li-style">
                      {item.comment}
                    </li>
                  );
                })}
              </ul>
            </span>
          </>
        ) : (
          <h1 className="h1-standard-text">
            Vi har dessverre ikke fått vurdert denne bilen.
          </h1>
        )}
      </div>
      {props.plusminus.plus.length !== 0 ||
      props.plusminus.minus.length !== 0 ? (
        <div style={{ marginTop: '30px' }}>
          Disse punktene er basert på våre egne vurderinger.
        </div>
      ) : null}
    </div>
  );
}
export default PlusMinus;
