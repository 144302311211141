import React from 'react';
import { Fab, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Add, Done } from '@material-ui/icons';
import '../pages/indexPage/main-container.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d9d9d9',
    },
    secondary: {
      main: '#ec6723',
    },
  },
});

function LabelTag(props) {
  const onTagClick = () => {
    props.handleClick(props.alternative);
  };

  return (
    <span className="label-tag">
      {props.isActive ? (
        <MuiThemeProvider theme={theme}>
          <Fab
            variant="extended"
            size="medium"
            color="secondary"
            onClick={() => onTagClick()}
            className="filter-tag-fab active"
          >
            <Done className="filter-tag-icon" />
            {props.alternative}
          </Fab>
        </MuiThemeProvider>
      ) : (
        <MuiThemeProvider theme={theme}>
          <Fab
            variant="extended"
            size="medium"
            color="primary"
            onClick={() => onTagClick()}
            className="filter-tag-fab inactive"
          >
              <Add className="filter-tag-icon" />
            {props.alternative}
          </Fab>
        </MuiThemeProvider>
      )}
    </span>
  );
}

export default LabelTag;
