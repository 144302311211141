import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Badge,
} from '@material-ui/core';
import { SORT_OPTIONS, sortCarsByPreference } from '../../constants';
import * as carService from '../../services/CarService';
import '../../pages/adminPage/adminPage.scss';

function ListOfAllCars(props) {
  const [chosenCarId, setChosenCarId] = useState(0);
  let preferredSortOrder = SORT_OPTIONS.find(
    (opt) => opt.property === 'brand_name' && opt.order === 1
  );

  let selectCar = (car) => {
    setChosenCarId(car.car_id);
    props.selectCar(car);
  };

  function getNumberOfDays(created) {
    const currentDate = new Date();
    const createdDate = new Date(created);
    const diffInMinutes = Math.abs(createdDate - currentDate);

    return Math.floor(diffInMinutes / (1000 * 60 * 60 * 24));
  }

  return (
    <List>
      {sortCarsByPreference(props.cars, preferredSortOrder).map(
        (car, index) => (
          <ListItem
            key={index}
            className={
              'list-item' + (chosenCarId === car.car_id ? ' active' : '')
            }
            onClick={() => selectCar(car.car_id)}
          >
            <ListItemAvatar>
              {getNumberOfDays(car.created) <= 4 ? (
                <Badge color="secondary" variant="dot">
                  <Avatar src={car.main_img_url} />
                </Badge>
              ) : (
                <Avatar src={car.main_img_url} />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={carService.getCarFullName(car)}
              secondaryTypographyProps={{ component: 'div' }}
              secondary={
                <>
                  <p>{car.release_year}</p>
                  <p>
                    Sist oppdatert av CT: {car.last_updated_from_chargetrip}
                  </p>
                  <p>Sist oppdatert admin: {car.price_last_updated}</p>
                </>
              }
            />
          </ListItem>
        )
      )}
    </List>
  );
}

export default ListOfAllCars;
