import React from 'react';
import {
  makeStyles,
  Slider,
  Input,
  Grid,
  Paper,
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import './filterItems.scss';
import { isMobile } from 'react-device-detect';


function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const theme = createMuiTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: '#ec6723',
      },
      track: {
        color: '#ec6723',
      },
      rail: {
        color: 'gray',
      },
    },
  },
});
const useStyles = makeStyles({
  root: {
    width: 300,
  },
  paper: {
    textAlign: 'center',
  },
});

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    marginTop: -8,
    marginLeft: -12,
  },
  valueLabel: { fontSize: '0.49rem' },
})(Slider);

function valuetext(value) {
  return `${value}`;
}

function FilterSlider(props) {
  const alt = props.filter.alternatives;
  const minValue = parseInt(alt[0], 10);
  const maxValue = parseInt(alt[1], 10);
  const classes = useStyles();
  const [value, setValue] = React.useState([minValue, maxValue]);
  const [inputValueOne, setInputValueOne] = React.useState(minValue);
  const [inputValueTwo, setInputValueTwo] = React.useState(maxValue);
  const invalidChars = ['-', '+', 'e'];

  React.useEffect(() => {
    if (props.defaultValues !== undefined) {
      setValue([props.defaultValues[0], props.defaultValues[1]]);
      setInputValueOne(props.defaultValues[0]);
      setInputValueTwo(props.defaultValues[1]);
    }
    // doing this due to usecase of the useEffect, this isnt in general a good way of doing this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setInputValueOne(newValue[0]);
    setInputValueTwo(newValue[1]);

    props.handleSliderValueChange(
      event,
      props.filter.id,
      props.category_id,
      newValue
    );
  };

  const handleBlurOne = (event) => {
    if (event.target.value === '') {
      setInputValueOne(value[0]);
      return;
    }

    let number = parseInt(event.target.value, 10);

    number = number < minValue ? minValue : number;

    if (number > inputValueTwo) {
      let tempValues = [parseInt(inputValueOne), parseInt(inputValueTwo)];
      tempValues[0] = tempValues[0] > maxValue ? maxValue : tempValues[0];

      setInputValueOne(tempValues[1]);
      setInputValueTwo(tempValues[0]);
      setValue(tempValues);
      props.handleSliderValueChange(event, props.filter.id, props.category_id, [
        tempValues[1],
        tempValues[0],
      ]);
    } else {
      setValue([number, value[1]]);
      setInputValueOne(number);
      props.handleSliderValueChange(event, props.filter.id, props.category_id, [
        number,
        value[1],
      ]);
    }
  };

  const handleBlurTwo = (event) => {
    if (event.target.value === '') {
      setInputValueTwo(value[1]);
      return;
    }
    let number = parseInt(event.target.value, 10);
    number = number > maxValue ? maxValue : number;

    if (number < inputValueOne) {
      let tempValues = [parseInt(inputValueOne), parseInt(inputValueTwo)];
      tempValues[1] = tempValues[1] < minValue ? minValue : tempValues[1];

      setInputValueOne(tempValues[1]);
      setInputValueTwo(tempValues[0]);
      setValue(tempValues);
      props.handleSliderValueChange(event, props.filter.id, props.category_id, [
        tempValues[1],
        tempValues[0],
      ]);
    } else {
      setValue([value[0], number]);
      setInputValueTwo(number);
      props.handleSliderValueChange(event, props.filter.id, props.category_id, [
        value[0],
        number,
      ]);
    }
  };

  const handleInputChangeOne = (event) => {
    setInputValueOne(event.target.value);
  };

  const handleInputChangeTwo = (event) => {
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
    setInputValueTwo(event.target.value);
  };

  const getSteps = () => {
    let val = maxValue - minValue;
    if (props.filter.name === 'Strømforbruk') return 0.1;
    if (val < 20 || props.filter.name === 'År') return 1;
    if (val < 50) return 2;
    if (val < 100) return 5;
    if (val < 500) return 10;
    if (val < 1000) return 20;
    if (val < 10000) return 100;
    if (val < 100000) return 1000;
    return 10000;
  };

  return (
    <>
      {isMobile ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: '1em' }}>
              {props.filter.filter_info}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <div style={{ marginTop: '1em' }} className="inputIncludingInfo">
                <Paper className={classes.paper} elevation={3}>
                  <Input
                    className={classes.input}
                    value={inputValueOne}
                    margin="dense"
                    onChange={handleInputChangeOne}
                    onBlur={handleBlurOne}
                    disableUnderline
                    inputComponent={NumberFormatCustom}
                    inputProps={{
                      step: getSteps(),
                      // type: 'number',
                      // 'aria-labelledby': 'Input felt lav verdi',
                      style: { textAlign: 'center' },
                      tabIndex: '0',
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleBlurOne(event);
                      }
                      if (invalidChars.includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Paper>
                <div className="inputinfo-wrapper">
                  {minValue === inputValueOne &&
                    props.filter.name !== 'Tilbakemelding' &&
                    props.filter.name !== 'Lengde på bil' &&
                    props.filter.name !== 'Bredde på bil med speil' &&
                    props.filter.name !== 'Bredde på bil uten speil' &&
                    props.filter.name !== 'År' &&
                    props.filter.name !== 'Bagasjerom' &&
                    props.filter.name !== 'Akselavstand' &&
                    props.filter.name !== 'Tilhengervekt' && (
                      <div className="inputinfo">
                        <p>og lavere</p>
                      </div>
                    )}
                  {minValue === inputValueOne &&
                    (props.filter.name === 'Bredde på bil med speil' ||
                      props.filter.name === 'Bredde på bil uten speil') && (
                      <div className="inputinfo">
                        <p>og smalere</p>
                      </div>
                    )}
                  {minValue === inputValueOne &&
                    props.filter.name === 'Lengde på bil' && (
                      <div className="inputinfo">
                        <p>og kortere</p>
                      </div>
                    )}
                  {minValue === inputValueOne && props.filter.name === 'År' && (
                    <div className="inputinfo">
                      <p>og eldre</p>
                    </div>
                  )}
                  {minValue === inputValueOne &&
                    (props.filter.name === 'Bagasjerom' ||
                      props.filter.name === 'Akselavstand' ||
                      props.filter.name === 'Tilhengervekt') && (
                      <div>
                        <p> og mindre</p>
                      </div>
                    )}
                </div>
              </div>
            </Grid>
            <Grid item xs={5}>
              <div style={{ marginTop: '1em' }} className="inputIncludingInfo">
                <Paper className={(classes.paper, 'paper')} elevation={3}>
                  <Input
                    className={classes.input}
                    value={inputValueTwo}
                    margin="dense"
                    onChange={handleInputChangeTwo}
                    onBlur={handleBlurTwo}
                    disableUnderline
                    width="25%"
                    inputComponent={NumberFormatCustom}
                    inputProps={{
                      step: getSteps(),
                      // type: 'number',
                      // 'aria-labelledby': 'Input felt høy verdi',
                      style: { textAlign: 'center' },
                      tabIndex: '0',
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleBlurTwo(event);
                      }
                      if (invalidChars.includes(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Paper>

                <div className="inputinfo-wrapper">
                  {maxValue === inputValueTwo &&
                    props.filter.name !== 'Tilbakemelding' &&
                    props.filter.name !== 'Lengde på bil' &&
                    props.filter.name !== 'Bredde på bil med speil' &&
                    props.filter.name !== 'Bredde på bil uten speil' &&
                    props.filter.name !== 'År' &&
                    props.filter.name !== 'Bagasjerom' &&
                    props.filter.name !== 'Akselavstand' &&
                    props.filter.name !== 'Tilhengervekt' && (
                      <div className="inputinfo">
                        <p>og høyere</p>
                      </div>
                    )}
                  {maxValue === inputValueTwo &&
                    (props.filter.name === 'Bredde på bil med speil' ||
                      props.filter.name === 'Bredde på bil uten speil') && (
                      <div className="inputinfo">
                        <p>og bredere</p>
                      </div>
                    )}
                  {maxValue === inputValueTwo &&
                    props.filter.name === 'Lengde på bil' && (
                      <div className="inputinfo">
                        <p>og lengre</p>
                      </div>
                    )}
                  {maxValue === inputValueTwo && props.filter.name === 'År' && (
                    <div className="inputinfo">
                      <p>og nyere</p>
                    </div>
                  )}
                  {maxValue === inputValueTwo &&
                    (props.filter.name === 'Bagasjerom' ||
                      props.filter.name === 'Akselavstand' ||
                      props.filter.name === 'Tilhengervekt') && (
                      <div>
                        <p> og mer</p>
                      </div>
                    )}
                </div>
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid container spacing={2}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <MuiThemeProvider theme={theme}>
              <div className={classes.margin} />
              <PrettoSlider
                value={value}
                step={getSteps()}
                marks
                paddingtop="20px"
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="Rekkevide slider"
                getAriaValueText={valuetext}
                min={minValue}
                max={maxValue}
              />
            </MuiThemeProvider>
          </Grid>
          <Grid item xs={11}></Grid>
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: '1em' }} className="inputIncludingInfo">
              <Paper className={classes.paper} elevation={3}>
                <Input
                  className={classes.input}
                  value={inputValueOne}
                  margin="dense"
                  onChange={handleInputChangeOne}
                  onBlur={handleBlurOne}
                  disableUnderline
                  inputComponent={NumberFormatCustom}
                  inputProps={{
                    step: getSteps(),
                    // type: 'number',
                    // 'aria-labelledby': 'Inpup felt lav verdi',
                    style: { textAlign: 'center' },
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleBlurOne(event);
                    }
                    if (invalidChars.includes(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Paper>
              <div className="inputinfo-wrapper">
                {minValue === inputValueOne &&
                  props.filter.name !== 'Tilbakemelding' &&
                  props.filter.name !== 'Lengde på bil' &&
                  props.filter.name !== 'Bredde på bil med speil' &&
                  props.filter.name !== 'Bredde på bil uten speil' &&
                  props.filter.name !== 'År' &&
                  props.filter.name !== 'Bagasjerom' &&
                  props.filter.name !== 'Akselavstand' &&
                  props.filter.name !== 'Tilhengervekt' && (
                    <div className="inputinfo">
                      <p>og lavere</p>
                    </div>
                  )}
                {minValue === inputValueOne &&
                  (props.filter.name === 'Bredde på bil med speil' ||
                    props.filter.name === 'Bredde på bil uten speil') && (
                    <div className="inputinfo">
                      <p>og smalere</p>
                    </div>
                  )}
                {minValue === inputValueOne &&
                  props.filter.name === 'Lengde på bil' && (
                    <div className="inputinfo">
                      <p>og kortere</p>
                    </div>
                  )}
                {minValue === inputValueOne && props.filter.name === 'År' && (
                  <div className="inputinfo">
                    <p>og eldre</p>
                  </div>
                )}
                {minValue === inputValueOne &&
                  (props.filter.name === 'Bagasjerom' ||
                    props.filter.name === 'Akselavstand' ||
                    props.filter.name === 'Tilhengervekt') && (
                    <div>
                      <p> og mindre</p>
                    </div>
                  )}
              </div>
            </div>
          </Grid>
          <Grid item xs={4} style={{ marginTop: '1em' }}>
            {props.filter.filter_info}
          </Grid>
          <Grid item xs={3}>
            <div style={{ marginTop: '1em' }} className="inputIncludingInfo">
              <Paper className={(classes.paper, 'paper')} elevation={3}>
                <Input
                  className={classes.input}
                  value={inputValueTwo}
                  margin="dense"
                  onChange={handleInputChangeTwo}
                  onBlur={handleBlurTwo}
                  disableUnderline
                  width="25%"
                  inputComponent={NumberFormatCustom}
                  inputProps={{
                    step: getSteps(),
                    // type: 'number',
                    // 'aria-labelledby': 'Input felt høy verdi',
                    style: { textAlign: 'center' },
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      handleBlurTwo(event);
                    }
                    if (invalidChars.includes(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Paper>
              <div className="inputinfo-wrapper">
                {maxValue === inputValueTwo &&
                  props.filter.name !== 'Tilbakemelding' &&
                  props.filter.name !== 'Lengde på bil' &&
                  props.filter.name !== 'Bredde på bil med speil' &&
                  props.filter.name !== 'Bredde på bil uten speil' &&
                  props.filter.name !== 'År' &&
                  props.filter.name !== 'Bagasjerom' &&
                  props.filter.name !== 'Akselavstand' &&
                  props.filter.name !== 'Tilhengervekt' && (
                    <div className="inputinfo">
                      <p>og høyere</p>
                    </div>
                  )}
                {maxValue === inputValueTwo &&
                  (props.filter.name === 'Bredde på bil med speil' ||
                    props.filter.name === 'Bredde på bil uten speil') && (
                    <div className="inputinfo">
                      <p>og bredere</p>
                    </div>
                  )}
                {maxValue === inputValueTwo &&
                  props.filter.name === 'Lengde på bil' && (
                    <div className="inputinfo">
                      <p>og lengre</p>
                    </div>
                  )}
                {maxValue === inputValueTwo && props.filter.name === 'År' && (
                  <div className="inputinfo">
                    <p>og eldre</p>
                  </div>
                )}
                {maxValue === inputValueTwo &&
                  (props.filter.name === 'Bagasjerom' ||
                    props.filter.name === 'Akselavstand' ||
                    props.filter.name === 'Tilhengervekt') && (
                    <div>
                      <p> og mer</p>
                    </div>
                  )}
              </div>
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <MuiThemeProvider theme={theme}>
              <div className={classes.margin} />
              <PrettoSlider
                value={value}
                step={getSteps()}
                marks
                paddingtop="20px"
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="Rekkevidde slider"
                getAriaValueText={valuetext}
                min={minValue}
                max={maxValue}
              />
            </MuiThemeProvider>
          </Grid>
        </Grid>
      )}
    </>
  );
}
export default FilterSlider;
