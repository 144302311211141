import React from 'react';
import './carSummaryItem.scss';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import ValueDisplayPipe from './valueDisplayPipe';
import PropTypes from 'prop-types';
import { standardDisplayMap } from '../../../constants';

class CarSummaryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  toggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  render() {
    return (
      <div className="summary-item">
        <div
          className="header-toggle"
          onClick={this.props.tableInfo.length !== 0 ? this.toggle : null}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              this.toggle(e);
            }
          }}
        >
          <img
            className="header-icon"
            src={this.props.icon}
            alt={'Ikon for ' + this.props.description}
          ></img>
          <div className="header-info">
            <strong>{this.props.header}</strong>
            <br />
            <p>{this.props.description}</p>
          </div>
          <span className="spacer"></span>
          {this.props.tableInfo.length !== 0 ? (
            <div tabIndex={0}>
              {!this.state.isExpanded && <KeyboardArrowDown />}
              {this.state.isExpanded && <KeyboardArrowUp />}
            </div>
          ) : null}
        </div>

        {this.state.isExpanded && (
          <div className="table-container">
            <table>
              <tbody>
                {this.props.tableInfo.length !== 0
                  ? this.props.tableInfo.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{row.name}</td>
                          <td>
                            <ValueDisplayPipe
                              value={
                                row.item in standardDisplayMap
                                  ? standardDisplayMap[row.item]
                                  : row.item
                              }
                              unit={
                                this.props.unit
                                  ? this.props.unit
                                  : row.unit
                                  ? row.unit
                                  : null
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

CarSummaryItem.propTypes = {
  icon: PropTypes.string.isRequired,
  unit: PropTypes.string,
  description: PropTypes.string.isRequired,
  tableInfo: PropTypes.array,
};

export default CarSummaryItem;
