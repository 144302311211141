import React from 'react';
import '../../pages/adminPage/adminPage.scss';
import EquipmentForm from './equipmentForm';
import * as carService from '../../services/CarService';

class EquipmentManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarObject: this.props.selectedCarObject,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCarObject !== this.props.selectedCarObject) {
      this.setState({ selectedCarObject: this.props.selectedCarObject });
    }
  }

  create = async (carId, equipment) => {
    await carService.postCarFromIdAndAttribute(carId, 'equipment', equipment);
  };

  update = async (carId, equipment) => {
    await carService.updateCarFromIdAndAttribute(carId, 'equipment', equipment);
  };

  delete = async (carId, equipmentId) => {
    let result = await carService.deleteFromCarFromIdAndAttributeId(
      carId,
      'equipment',
      [equipmentId]
    );
    if (result instanceof Error) {
      return;
    } else {
      console.log('deleted!');
    }
  };

  render() {
    return (
      <div>
        <EquipmentForm
          selectedCarObject={this.state.selectedCarObject}
          equipmentData={this.props.equipmentData}
          createClick={(carId, equipment) => this.create(carId, equipment)}
          updateClick={(carId, equipment) => this.update(carId, equipment)}
          deleteClick={(carId, equipmentId) => this.delete(carId, equipmentId)}
        />
      </div>
    );
  }
}

export default EquipmentManager;
