import React from 'react';
import InfoCategoryThumbnail from './infoCategoryThumbnail.js';

function InfoCategoryThumbnailsDisplay(props) {
  const targetRef = React.useRef();
  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  let movement_timer = null;

  const RESET_TIMEOUT = 100;

  const test_dimensions = () => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  };

  React.useEffect(() => {
    test_dimensions();
  }, []);

  window.addEventListener('resize', () => {
    clearInterval(movement_timer);
    movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
  });

  const sixBoxesInRowWidth = 6 * (140 + 14);
  let isOneRow = dimensions.width >= sixBoxesInRowWidth;

  return (
    <section className="flex-content" ref={targetRef}>
      {props.categories.map((category, index) => {
        if (
          (category.id === 'feedback' &&
            props.carObject.feedback.length !== 0) ||
          category.id !== 'feedback'
        ) {
          return (
            <InfoCategoryThumbnail
              key={index}
              category={category}
              handleShowCategoryClick={props.handleShowCategoryClick}
              displayedCategoryId={props.displayedCategoryId}
              showArrow={isOneRow}
            />
          );
        } else {
          return null;
        }
      })}
    </section>
  );
}

export default InfoCategoryThumbnailsDisplay;
