import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import '../pages/indexPage/main-container.scss';
import { TextField, MuiThemeProvider, createMuiTheme } from '@material-ui/core';

const themeSearch = createMuiTheme({
  palette: {
    primary: {
      main: '#ec6723',
    },
  },
});

function searchField(props) {
  return (
    <span className="search-field">
      <MuiThemeProvider theme={themeSearch}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <SearchIcon
            style={{
              position: 'absolute',
              left: 2,
              top: 10,
              width: 20,
              height: 20,
            }}
          />
          <div style={{ width: 10 }}></div>
          <TextField
            className="text-field-special"
            style={{ textIndent: 10 }}
            label={
              props.isLabel == null
                ? 'Søk etter kriterier'
                : 'Søk etter bilmerker'
            }
            variant="outlined"
            size="small"
            color="primary"
            autoComplete="off"
            onChange={e => props.handleChange(e.target.value)}
          />
        </div>
      </MuiThemeProvider>
    </span>
  );
}
export default searchField;
