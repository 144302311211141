import React from 'react';
import './blueshell.scss';
import logo from '../../assets/Elbilforeningen-hvit.png';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

function BlueShell(props) {
  return (
    <div className="main-container">
      <header className="main-header mobile">
        <img
          src={logo}
          className="main-logo"
          alt="Norsk Elbilforening logo"
          onClick={() => (window.location.href = 'http://www.elbil.no/')}
        />
        <Link
          style={{ outline: isMobile ? 'none' : '' }}
          to={{
            pathname: '/',
          }}
        >
          <div className="main-logotext">
            <h1>
              <strong>Elbil</strong>
              velgeren
            </h1>
          </div>
        </Link>
      </header>

      <header className="main-header pc">
        <img
          tabIndex={0}
          src={logo}
          className="main-logo"
          alt="Norsk Elbilforening logo"
          onClick={() => (window.location.href = 'http://www.elbil.no/')}
        />

        <Link
          to={{
            pathname: '/',
          }}
        >
          <div className="main-logotext">
            <h1>
              <strong>Elbil</strong>
              velgeren
            </h1>
          </div>
        </Link>
      </header>

      {props.children}
    </div>
  );
}

export default BlueShell;
