import React from 'react';
import './filterBox.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { isMobile } from 'react-device-detect';

function FilterBox(props) {
  const isActive = props.filtersForQuery.some(
    (filt) => props.category.category_id === filt.category_id
  );

  return (
    <div
      className="box all-radius boop zoom-special"
      style={{ outline: isMobile ? 'none' : '' }}
      id="filterbox"
      role="button"
      aria-label={props.category.category_name}
      onClick={(e) => props.handleClick(e, props.category)}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          props.handleClick(e, props.category);
        }
      }}
      tabIndex="0"
    >
      <table className="box-table">
        <tbody>
          <tr>
            <td className="box-icon-cell">
              {isActive ? (
                <img
                  src={'iconImgColor/' + props.category.icon_name}
                  alt={'Ikon for ' + props.category.icon_name}
                  className="box-icon"
                />
              ) : (
                <img
                  src={'iconImg/' + props.category.icon_name}
                  alt={'Ikon for ' + props.category.icon_name}
                  className="box-icon"
                />
              )}
            </td>
            <td className="box-cross-cell">
              <div className="box-cross">
                <HighlightOffIcon
                  tabIndex="0"
                  aria-label="Kryss ut knapp"
                  style={{ outline: isMobile ? 'none' : '' }}
                  fontSize="small"
                  onClick={(e) => props.handleKillClick(e, props.category)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      props.handleKillClick(e, props.category);
                    }
                  }}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="box-title">
              {props.category.category_name}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className={
          props.showFilterValues &&
          props.clickedCategory.category_id === props.category.category_id
            ? 'chosen-arrow-down-fb'
            : ''
        }
      ></div>
    </div>
  );
}

export default FilterBox;
