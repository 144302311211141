import React from 'react';

function ErrorMessage(props) {
  return (
    <div>
      <div>{props.error.toString()}</div>
    </div>
  );
}

export default ErrorMessage;
