import React from 'react';
import {
  Button,
  InputLabel,
  FormControl,
  Fab,
  Input,
  InputAdornment,
  Tooltip,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Delete, CheckCircle, AccessTime } from '@material-ui/icons';
import { TECHNICAL_DATA_IDS_TO_BE_USED } from '../../constants';
import * as carService from '../../services/CarService';

class TechnicalDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSaved: false,
      selectedCarObject: props.selectedCarObject,
      listOfTechnicalDataToBePosted: [],
    };
  }

  myChangeHandler = event => {
    const selectedCarObject = { ...this.state.selectedCarObject };
    let foundObject;

    for (const key of Object.keys(selectedCarObject.carTechnicalData)) {
      foundObject = selectedCarObject.carTechnicalData[key].find(
        item => item.category_name === event.target.name
      );
      if (foundObject) {
        break;
      }
    }

    // If car already contains information about this technical data type
    if (foundObject) {
      const selectedCarObject = { ...this.state.selectedCarObject };
      let foundObjectWithValue;
      for (const key of Object.keys(selectedCarObject.carTechnicalData)) {
        foundObjectWithValue = selectedCarObject.carTechnicalData[key].find(
          item => item.category_name === event.target.name
        );
        if (foundObjectWithValue) {
          foundObjectWithValue.value = event.target.value;
          this.setState({ selectedCarObject });
          break;
        }
      }
      // If car does not contain information about this technical data type
    } else {
      let technical_category_id = this.getTechnicalCategoryIdFromName(
        event.target.name
      );
      let objectInlistOfTechnicalDataToBePostedIndex = this.state.listOfTechnicalDataToBePosted
        .map(item => item.category_id)
        .indexOf(technical_category_id);

      // If technical data type is not found in the list ready to be POSTed
      if (objectInlistOfTechnicalDataToBePostedIndex < 0) {
        let technical_object = {
          category_id: technical_category_id,
          value: event.target.value,
          value_alternative: null,
        };
        this.setState({
          listOfTechnicalDataToBePosted: [
            ...this.state.listOfTechnicalDataToBePosted,
            technical_object,
          ],
        });
        // If technical data type is already ready to be POSTed
      } else {
        let items = [...this.state.listOfTechnicalDataToBePosted];
        let item = { ...items[objectInlistOfTechnicalDataToBePostedIndex] };
        item.value = event.target.value;
        items[objectInlistOfTechnicalDataToBePostedIndex] = item;
        this.setState({ listOfTechnicalDataToBePosted: items });
      }
    }
  };

  getTechnicalInfoFromCar(category_id) {
    let finalFoundObject = null;
    let foundObject;
    for (const key of Object.keys(
      this.state.selectedCarObject.carTechnicalData
    )) {
      foundObject = this.state.selectedCarObject.carTechnicalData[key].find(
        item => item.category_id === category_id
      );
      if (foundObject) {
        finalFoundObject = foundObject.value;
        break;
      }
    }

    if (finalFoundObject === null || finalFoundObject === undefined) {
      foundObject = this.state.listOfTechnicalDataToBePosted.find(
        item => item.category_id === category_id
      );

      if (foundObject) {
        finalFoundObject = foundObject.value;
      }
    }
    return finalFoundObject;
  }

  getTechnicalCategoryIdFromName(technicalCategoryName) {
    let foundObject;
    for (const key of Object.keys(this.props.technicalData)) {
      foundObject = this.props.technicalData[key].find(
        item => item.category_name === technicalCategoryName
      );
      if (foundObject) {
        return foundObject.category_id;
      }
    }
    return null;
  }

  processForm = async event => {
    this.setState({ isLoading: true });
    let carId = this.state.selectedCarObject.car.car_id;
    event.preventDefault();
    for (const element of this.state.listOfTechnicalDataToBePosted) {
      await this.create(carId, element);
    }

    let listOfElementsToBeUpdated = [];
    for (const key of Object.keys(
      this.state.selectedCarObject.carTechnicalData
    )) {
      for (const item of this.state.selectedCarObject.carTechnicalData[key]) {
        listOfElementsToBeUpdated.push({
          category_id: item.category_id,
          value: item.value,
          value_alternative: item.value_alternative,
        });
      }
    }
    await this.update(carId, listOfElementsToBeUpdated);

    this.setState({
      listOfTechnicalDataToBePosted: [],
      isLoading: false,
      isSaved: true,
    });
  };

  create = async (carId, element) => {
    await carService.postCarFromIdAndAttribute(carId, 'technicaldata', [
      element,
    ]);
  };

  update = async (carId, listOfElements) => {
    await carService.updateCarFromIdAndAttribute(
      carId,
      'technicaldata',
      listOfElements
    );
  };

  delete = async technicalId => {
    let carId = this.state.selectedCarObject.car.car_id;

    await carService.deleteFromCarFromIdAndAttributeId(carId, 'technicaldata', [
      technicalId,
    ]);
    this.setState({
      deleted: true,
    });
  };

  render() {
    return (
      <div className="form-container all-radius">
        <form
          onSubmit={e => {
            this.processForm(e);
          }}
        >
          <div className="field-container">
            {Object.keys(this.props.technicalData).map((item, index) => {
              return (
                <div key={index}>
                  <h2>{item}</h2>
                  {this.props.technicalData[item]
                    .filter(td =>
                      TECHNICAL_DATA_IDS_TO_BE_USED.includes(td.category_id)
                    )
                    .map(td => {
                      // Used for 'Drivhjul'. We don't want 'delete' button with this option as we can
                      // set value to 'Ukjent', which is equivalent to NULL.
                      if (td.category_id === 104) {
                        return (
                          <div key={td.category_id}>
                            <div
                              key={td.category_id}
                              className="input-field-container"
                            >
                              <FormControl>
                                <InputLabel id={td.category_name}>
                                  {td.category_name}
                                </InputLabel>
                                <Select
                                  id={td.category_name}
                                  name={td.category_name}
                                  labelId={td.category_name}
                                  onChange={this.myChangeHandler}
                                  value={
                                    this.getTechnicalInfoFromCar(td.category_id) ?? "Ukjent"
                                  }
                                  className="selectfield"
                                >
                                  <MenuItem value="Ukjent">Ukjent</MenuItem>
                                  <MenuItem value="F">Forhjulstrekk</MenuItem>
                                  <MenuItem value="B">Bakhjulstrekk</MenuItem>
                                  <MenuItem value="A">Firehjulstrekk</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        );
                      }
                      else
                        return (
                          <div key={td.category_id}>
                            <div
                              key={td.category_id}
                              className="input-field-container"
                            >
                              <FormControl>
                                <InputLabel htmlFor={td.category_name}>
                                  {td.category_name}
                                </InputLabel>
                                <Input
                                  id={td.category_name}
                                  name={td.category_name}
                                  // type="number"
                                  onChange={this.myChangeHandler}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      {td.unit ? td.unit : ''}
                                    </InputAdornment>
                                  }
                                  value={this.getTechnicalInfoFromCar(
                                    td.category_id
                                  )}
                                  autoComplete="off"
                                />
                              </FormControl>
                              <Tooltip
                                title="Fjern informasjon på dette punktet"
                                aria-label="removeTechnicalData"
                              >
                                <Fab
                                  size="small"
                                  type="button"
                                  color="secondary"
                                  onClick={e => {
                                    if (
                                      window.confirm(
                                        'Er du sikker på at du fjerne informasjonen på dette feltet? Dette vil skjule/sette feltet som "ukjent" for brukerne. Du har fortsatt muligheten til å fylle ut feltet senere.'
                                      )
                                    )
                                      this.delete(td.category_id);
                                  }}
                                  className="small"
                                >
                                  <Delete />
                                </Fab>
                              </Tooltip>
                            </div>
                          </div>
                        );
                    })}
                </div>
              );
            })}
          </div>
          <div className="form-actions">
            {this.state.isLoading && (
              <div>
                <AccessTime /> <p>Lagrer...</p>
              </div>
            )}
            {!this.state.isLoading && (
              <Button variant="contained" color="primary" type="submit">
                Lagre
              </Button>
            )}
            {this.state.isSaved && (
              <div>
                <CheckCircle /> <p>Lagret!</p>
              </div>
            )}
            {this.state.deleted && <p>Informasjonen ble slettet!</p>}
          </div>
        </form>
      </div>
    );
  }
}

export default TechnicalDataForm;
