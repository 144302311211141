export const S3BUCKETURL = 'https://webforening.s3-eu-west-1.amazonaws.com/';

export const UNITS = {
  money: 'kr',
  distance: 'km',
  liquid: 'liter',
  battery: 'kWt',
  durationHours: 'timer',
  durationMinutes: 'minutter',
  doors: 'dørs',
  weight: 'kg',
};

export const CAR_VALUE_DISPLAY_NAMES = {
  basic_model_price_nok: 'Frapris',
  premium_model_price_nok: 'Premiummodell',
  range_in_km_nedc: 'Rekkevidde NEDC',
  range_in_km_wltp: 'Rekkevidde WLTP',
  normal_charge_time: 'Normallading',
  fast_charge_time: 'Ladetid hurtiglading',
  frunk: 'Bagasjerom (foran)',
  trunk: 'Bagasjerom (bak)',
  truck_storage_normal: 'Bagasjeromvolum normalt',
  truck_storage_max: 'Bagasjeromvolum maksimalt',
  gross_battery_capacity: 'Batteristørrelse',
  battery_type: 'Batteritype',
  charging_via_app: 'Apptilgang for lading',
  preheat_via_app: 'Apptilgang for forvarme',
  heat_pump: 'Varmepumpe',
  active_temperature_control_battery_pack:
    'Aktiv temperaturstyring batteripakke',
  key_via_app: 'Apptilgang for nøkkel',
  top_speed: 'Topphastighet',
  allowed_total_weight: 'Tillatt totalvekt',
  range_estimate_km_summer: 'Rekkeviddeanslag sommer',
  range_estimate_km_winter: 'Rekkeviddeanslag vinter',
  max_roof_load: 'Maksimal taklast',
  towing_hitch: 'Tilhengerfeste',
  drivetrain_layout: 'Drivhjul',
};

export const CAR_INFO_CATEGORIES = [
  { id: 'summary', display_name: 'Oppsummert' },
  { id: 'plusminus', display_name: 'Pluss/minus' },
  { id: 'feedback', display_name: 'Eiertilfredshet' },
  { id: 'article', display_name: 'Bilfakta' },
  { id: 'tests', display_name: 'Tester' },
  { id: 'details', display_name: 'Detaljer' },
];

export const CAR_CHASSIS_ALTERNATIVES = [
  'SUV/Crossover',
  'Flerbruksbil',
  'Varebil',
  'Pickup',
  'Cabriolet',
  'Coupé',
  'Kombi',
  'Sedan',
  'Stasjonsvogn',
];

export const CAR_STATUS_ALTERNATIVES = [
  { display_value: 'Kommende biler', value: 'UPCOMING_CAR' },
  { display_value: 'Bruktmarked', value: 'USED_MARKET' },
  { display_value: 'I salg', value: 'FOR_SALE' },
  { display_value: 'Konseptbil', value: 'CONCEPT_CAR' },
];

export const FILTER_QUERY_ALTERNATIVE_VALUE_MAP = {
  Minibiler: 'A',
  Småbiler: 'B',
  Kompaktbiler: 'C',
  Mellomklassebiler: 'D',
  'Store biler': 'E',
  Luksusbil: 'F',
  Flerbruksbil: 'M',
  Sportsbil: 'S',
  SUV: 'SUV',
  Firehjulstrekk: 'A',
  Bakhjulstrekk: 'B',
  Forhjulstrekk: 'F',
  Ukjent: 'Ukjent',
  Brukt: 'USED_MARKET',
  'I salg': 'FOR_SALE',
  Kommende: 'UPCOMING_CAR',
  Konseptbil: 'CONCEPT_CAR',
  'Med tilhengerfeste': true,
  'Uten tilhengerfeste': false,
  Ja: true,
  Nei: false,
};

export const TIRE_DRIVE_VALUE_MAP = {
  A: 'Firehjulstrekk',
  F: 'Forhjulstrekk',
  B: 'Bakhjulstrekk',
  Ukjent: 'Ukjent',
};

export const FAST_CHARGE_LOCATION = {
  'Left Side - Rear': 'Venstre side - Bak',
  'Left Side - Front': 'Venstre side - Foran',
  'Right Side - Rear': 'Høyre side - Bak',
  'Right Side - Front': 'Høyre side - Foran',
  Front: 'Foran',
  Rear: 'Bak',
  'Passenger Side - Front': 'Høyre side - Foran',
  "Driver's Side - Front": 'Venstre side - Foran',
  'Front - Middle': 'Midten - Foran',
  'Front Side - Middle': 'Midten - Foran',
  'Front - Left': 'Venstre - Foran',
  'Rear Side - Right': 'Høyre - Bak',
  'Left - Rear': 'Venstre - Bak',
  'Left - Front': 'Venstre - Foran',
  Ukjent: 'Ukjent',
};

export const CAR_BRANDS = [
  'Abarth',
  'Aiways',
  'Audi',
  'BMW',
  'BYD',
  'Byton',
  'Chevrolet',
  'Citroën',
  'CUPRA',
  'Dacia',
  'DFSK',
  'DS',
  'Elaris',
  'Fiat',
  'Fisker',
  'Ford',
  'Genesis',
  'Honda',
  'Hongqi',
  'Hyundai',
  'JAC',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lexus',
  'Lotus',
  'Lucid',
  'MAN',
  'Maserati',
  'Maxus',
  'Mazda',
  'Mercedes',
  'MG',
  'Mini',
  'Mitsubishi',
  'NIO',
  'Nissan',
  'Opel',
  'ORA',
  'Peugeot',
  'Polestar',
  'Porsche',
  'Renault',
  'Rivian',
  'Rolls-Royce',
  'SEAT',
  'Seres',
  'Skoda',
  'Smart',
  'Sono',
  'SsangYong',
  'Subaru',
  'Tesla',
  'Toyota',
  'VinFast',
  'Volkswagen',
  'Volvo',
  'Xpeng',
];

export const standardDisplayMap = {
  standard: 'Ja',
  extra: 'Ekstra',
  none: 'Nei',
};

export const FILTERS_THAT_NEED_MAPPED_VARIABLE_NAME = [
  'carSize',
  'drivetrainLayout',
  'carStatus',
  'towingHitch',
  'heatPump',
  'chargingViaApp',
  'preheatViaApp',
  'keyViaApp',
  'tireSize',
];

export const IDS_FOR_DISCLAIMER = [
  604, //Dekkdimensjon foran
  605, //Dekkdimensjon bak
  606, //Felgdimensjon foran
  607, //Felgdimensjon bak
  703, //Antall sitteplasser
  1206, //Ladetid normallading
  1207, //Ladetid hurtiglading
  1215, //Lading via app
  1216, //Forvarming via app
];

export const EQUIPMENT_DATA_IDS_TO_BE_USED = [1215, 1216, 1218, 95];

export const GENERAL_DATA_NAMES_TO_BE_USED = ['release_year'];
export const GENERAL_DATA_NAMES = { release_year: 'År' };

export const BATTERY_DATA_IDS_TO_BE_USED = [
  1211, 1212, 1202, 1203, 1214, 1206, 1207, 1219,
];

export const TECHNICAL_DATA_IDS_TO_BE_USED = [
  101, 102, 103, 1101, 1102, 1210, 1203, 323, 401, 402, 802, 702, 703, 704,
  705, 801, 802, 813, 803, 804, 807, 808, 1001, 1002, 1003, 1004, 1005, 1211,
  1212, 1450, 1451,
];

export const TECHNICAL_DATA_CATEGORIES_TO_BE_REMOVED = [
  'Miljødata',
  'Drivlinje',
  'Hjul/bremser',
];

export const TEST_CAR_RESULTS = [
  {
    battery_size_in_kwh: 42,
    range_in_km_winter: 250,
    has_app_access_for_preheating: true,
    has_app_access_for_charging: true,
    price_basic_model: 330000,
    truck_storage_space_in_litre_back: 400,
    charge_time_in_hours_fast_charge: 1,
    charge_time_in_hours_64kwh: 5,
    truck_storage_space_in_litre_front: 20,
    range_in_km_summer: 300,
    has_active_temperature_control_battery_pack: true,
    charge_time_in_hours_normal_charge: 11,
    has_heatpump: true,
    id: 1,
    price_premium_model: 400000,
  },
  {
    battery_size_in_kwh: 42,
    range_in_km_winter: 250,
    has_app_access_for_preheating: true,
    has_app_access_for_charging: true,
    price_basic_model: 250000,
    truck_storage_space_in_litre_back: 400,
    charge_time_in_hours_fast_charge: 1,
    charge_time_in_hours_64kwh: 2,
    truck_storage_space_in_litre_front: 20,
    range_in_km_summer: 300,
    has_active_temperature_control_battery_pack: true,
    charge_time_in_hours_normal_charge: 15,
    has_heatpump: true,
    id: 0,
    price_premium_model: 600000,
  },
];

export const CARDETAILS_LIST_ORDER = [
  'Praktisk informasjon',
  'Batteri',
  'Vekt',
  'Rekkevidde',
  'Mål',
  'Motordata',
  'Ytelser',
  'Bagasjerom',
  'Garanti',
  'Sikkerhet',
];

export const SORT_OPTIONS = [
  {
    display_name: 'Pris (Lav)',
    property: 'basic_model_price_nok',
    is_number: true,
    order: 1,
  },
  {
    display_name: 'Pris (Høy)',
    property: 'basic_model_price_nok',
    is_number: true,
    order: -1,
  },
  {
    display_name: 'År (Eldre)',
    property: 'release_year',
    is_number: true,
    order: 1,
  },
  {
    display_name: 'År (Ny)',
    property: 'release_year',
    is_number: true,
    order: -1,
  },
  {
    display_name: 'Merke (A-Z)',
    property: 'brand_name',
    is_number: false,
    order: 1,
  },
  {
    display_name: 'Merke (Z-A)',
    property: 'brand_name',
    is_number: false,
    order: -1,
  },
  {
    display_name: 'Rekkevidde (Lav)',
    property: 'range_wltp',
    is_number: true,
    order: 1,
  },
  {
    display_name: 'Rekkevidde (Høy)',
    property: 'range_wltp',
    is_number: true,
    order: -1,
  },
];

export function sortCarsByPreference(cars, orderObject) {
  cars.sort(dynamicSort(orderObject));

  //In case of sorting from low to high (price): Cars that have '0' price will
  //be moved to bottom of the list.
  if (
    orderObject.property === 'basic_model_price_nok' &&
    orderObject.order === 1
  ) {
    cars.sort((i, j) => {
      if (parseInt(j.basic_model_price_nok) === 0) return -1;
      else return 0;
    });
  }

  return cars;
}

function dynamicSort(orderObject) {
  let sortOrder = orderObject.order;

  return function (a, b) {
    if (!a[orderObject.property]) return 1;
    if (!b[orderObject.property]) return -1;

    if (orderObject.is_number) {
      if (sortOrder === -1) {
        return b[orderObject.property].localeCompare(
          a[orderObject.property].toString(),
          undefined,
          {
            numeric: true,
          }
        );
      } else {
        return a[orderObject.property]
          .toString()
          .localeCompare(b[orderObject.property].toString(), undefined, {
            numeric: true,
          });
      }
    } else {
      if (sortOrder === -1) {
        return b[orderObject.property]
          .toString()
          .localeCompare(a[orderObject.property].toString());
      } else {
        return a[orderObject.property]
          .toString()
          .localeCompare(b[orderObject.property].toString());
      }
    }
  };
}
