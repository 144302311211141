import React from 'react';

import {
  Input,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  Fab,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@material-ui/core';
import { CheckCircle, Delete } from '@material-ui/icons';
import '../../pages/adminPage/adminPage.scss';
import * as carService from '../../services/CarService';
import { BATTERY_DATA_IDS_TO_BE_USED } from '../../constants';

class BatteryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      selectedCarObject: props.selectedCarObject,
      listOfBatteryInfoToBePosted: [],
    };
  }
  myChangeHandler = (event) => {
    const selectedCarObject = { ...this.state.selectedCarObject };
    let foundObject = selectedCarObject.carBatteryData.find(
      (item) => item.category_name === event.target.name
    );

    // If car already contains information about this battery type
    if (foundObject) {
      const selectedCarObject = { ...this.state.selectedCarObject };
      selectedCarObject.carBatteryData.find(
        (item) => item.category_name === event.target.name
      ).value = event.target.value;
      this.setState({ selectedCarObject });
      // If car does not contain information about this battery type
    } else {
      let battery_category_id = this.getBatteryCategoryIdFromName(
        event.target.name
      );
      let objectInlistOfBatteryInfoToBePostedIndex = this.state.listOfBatteryInfoToBePosted
        .map((item) => item.battery_category_id)
        .indexOf(battery_category_id);

      // If battery type is already ready to be POSTed
      if (objectInlistOfBatteryInfoToBePostedIndex < 0) {
        let battery_object = {
          battery_category_id: battery_category_id,
          value: event.target.value,
          value_alternative: null,
        };
        this.setState({
          listOfBatteryInfoToBePosted: [
            ...this.state.listOfBatteryInfoToBePosted,
            battery_object,
          ],
        });
        // If battery type is not found in the list ready to be POSTed
      } else {
        let items = [...this.state.listOfBatteryInfoToBePosted];
        let item = { ...items[objectInlistOfBatteryInfoToBePostedIndex] };
        item.value = event.target.value;
        items[objectInlistOfBatteryInfoToBePostedIndex] = item;
        this.setState({ listOfBatteryInfoToBePosted: items });
      }
    }
  };

  processForm = async (event) => {
    let carId = this.state.selectedCarObject.car.car_id;

    event.preventDefault();
    for (const element of this.state.listOfBatteryInfoToBePosted) {
      await this.create(carId, element);
    }

    let listOfElementsToBeUpdated = [];
    for (const element of this.state.selectedCarObject.carBatteryData) {
      listOfElementsToBeUpdated.push({
        battery_category_id: element.category_id,
        value: element.value,
        value_alternative: element.value_alternative,
      });
    }

    await this.update(carId, listOfElementsToBeUpdated);

    this.setState({
      listOfBatteryInfoToBePosted: [],
      submitted: true,
    });
    console.log('posted!');
  };

  create = async (carId, element) => {
    await carService.postCarFromIdAndAttribute(carId, 'battery', [element]);
  };

  update = async (carId, listOfElements) => {
    await carService.updateCarFromIdAndAttribute(
      carId,
      'battery',
      listOfElements
    );
  };

  delete = async (batteryId) => {
    let carId = this.state.selectedCarObject.car.car_id;

    await carService.deleteFromCarFromIdAndAttributeId(carId, 'battery', [
      batteryId,
    ]);
    this.setState({
      deleted: true,
    });
  };

  getBatteryInfoFromCar(category_id) {
    let foundObject = this.state.selectedCarObject.carBatteryData.find(
      (item) => item.category_id === category_id
    );
    return foundObject ? foundObject.value : null;
  }

  getBatteryCategoryIdFromName(batteryCategoryName) {
    return this.props.batteryData.find(
      (item) => item.category_name === batteryCategoryName
    ).category_id;
  }

  render() {
    return (
      <div className="form-container all-radius">
        <form
          onSubmit={(e) => {
            this.processForm(e);
          }}
        >
          {this.props.batteryData
            .filter((bd) =>
              BATTERY_DATA_IDS_TO_BE_USED.includes(bd.category_id)
            )
            .map((bd) => {
              return (
                <div key={bd.category_id} className="input-field-container">
                  {bd.category_id === 1215 && (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {bd.category_name}
                      </FormLabel>
                      <RadioGroup
                        aria-label={bd.category_name}
                        name={bd.category_name}
                        value={this.getBatteryInfoFromCar(bd.category_id)}
                        onChange={this.myChangeHandler}
                        className="inline-radio"
                      >
                        <FormControlLabel
                          value="standard"
                          control={<Radio />}
                          label="Standard"
                        />
                        <FormControlLabel
                          value="extra"
                          control={<Radio />}
                          label="Ekstra"
                        />
                        <FormControlLabel
                          value="none"
                          control={<Radio />}
                          label="Finnes ikke"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  {bd.category_id !== 1215 && (
                    <FormControl>
                      <InputLabel htmlFor={bd.category_name}>
                        {bd.category_name}
                      </InputLabel>
                      <Input
                        id={bd.category_name}
                        name={bd.category_name}
                        onChange={this.myChangeHandler}
                        endAdornment={
                          <InputAdornment position="end">
                            {bd.unit}
                          </InputAdornment>
                        }
                        value={this.getBatteryInfoFromCar(bd.category_id)}
                        autoComplete="off"
                      />
                    </FormControl>
                  )}
                  <Tooltip
                    title="Fjern informasjon på dette punktet"
                    aria-label="removeBattery"
                  >
                    <Fab
                      size="small"
                      type="button"
                      color="secondary"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            'Er du sikker på at du fjerne informasjonen på dette feltet? Dette vil skjule/sette feltet som "ukjent" for brukerne. Du har fortsatt muligheten til å fylle ut feltet senere.'
                          )
                        )
                          this.delete(bd.category_id);
                      }}
                      className="small"
                    >
                      <Delete />
                    </Fab>
                  </Tooltip>
                </div>
              );
            })}
          <Button variant="contained" color="primary" type="submit">
            Lagre
          </Button>
          {this.state.deleted && <p>Informasjonen ble slettet!</p>}
          {this.state.submitted && <CheckCircle />}
        </form>
      </div>
    );
  }
}

export default BatteryForm;
