import React from 'react';
import FilterCheckbox from './filterCheckbox';
import './filterItems.scss';
import { FormGroup, Grid } from '@material-ui/core';
function IntermediatCheckbox(props) {
  return (
    <React.Fragment>
      <h1 className="header-fix">{props.filter.name}</h1>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        {props.filter.filter_info}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} className="checkBox-filter">
          <div style={{ margin: '1em auto auto auto' }}>
            <FormGroup row>
              {props.filter.alternatives.map((value, j) => (
                <FilterCheckbox
                  key={j}
                  value={value}
                  filter={props.filter}
                  onChange={props.checkboxRadioChange}
                  queryFilter={props.queryFilter}
                />
              ))}
            </FormGroup>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={11}></Grid>
        <Grid item xs={1}></Grid>
        <>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} key={props.filter.category_id + 123}>
            {props.filter.help_text}
          </Grid>
          <Grid item xs={1}></Grid>
        </>
      </Grid>
    </React.Fragment>
  );
}

export default IntermediatCheckbox;
