// This service contains functions that you can use to connect to the API (for now created in Node).
// Function names should be self explanatory.
const getOptions = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

const postOptions = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const putOptions = {
  method: 'PUT',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const patchOptions = {
  method: 'PATCH',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const deleteOptions = {
  method: 'DELETE',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export async function get(resource) {
  let response = await fetch(getUrlnameJava() + resource, getOptions);
  try {
    let data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
}

export async function put(resource) {
  return await fetch(getUrlnameJava() + resource, putOptions);
}

export async function getSectionById(resource, id, section) {
  let response = await fetch(
    getUrlnameJava() + `${resource}/${id}/${section}`,
    getOptions
  );
  try {
    let data = await response.json();
    return data;
  } catch (error) {
    console.log('error: ', error);
    return error;
  }
}

export async function getAllJoinDataById(resource, id) {
  let response = await fetch(
    getUrlnameJava() + `${resource}/${id}/all`,
    getOptions
  );
  try {
    let data = await response.json();
    return data;
  } catch (error) {
    console.log('error: ', error);
    return error;
  }
}

/////////////////////////////
///////////POST//////////////
/////////////////////////////

export async function post(resource, queries) {
  postOptions.body = JSON.stringify(queries);

  let response = await fetch(getUrlnameJava() + resource, postOptions);

  if (response.status === 403) {
    console.log('Forbidden');
    return response;
  }

  try {
    let data = await response.json();
    return data;
  } catch (error) {
    console.log('ERROR ', error);
    return error;
  }
}

export async function postFullUrl(resource, queries) {
  postOptions.body = JSON.stringify(queries);

  let res = await fetch(resource, postOptions);
  const data = await res.json();

  return data;
}

/////////////////////////////
///////////PATCH//////////////
/////////////////////////////

export async function patch(resource, queries) {
  patchOptions.body = JSON.stringify(queries);

  let response = await fetch(getUrlnameJava() + resource, patchOptions);

  try {
    let data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
}

/////////////////////////////
//////////DELETE/////////////
/////////////////////////////

export async function destroy(resource) {
  let response = await fetch(getUrlnameJava() + resource, deleteOptions);

  try {
    let data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
}

export async function destroyAttribute(resource, attributeId) {
  deleteOptions.body = JSON.stringify(attributeId);

  let response = await fetch(getUrlnameJava() + resource, deleteOptions);

  try {
    let data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
}

/////////////////////////////
///////////INFO//////////////
/////////////////////////////

export function getUrlname() {
  if ( process.env.REACT_APP_NODE_ENV === 'production' ) {
    return process.env.REACT_APP_BASE_API_URL;
  } else if ( process.env.REACT_APP_NODE_ENV === 'stage' ) {
    return process.env.REACT_APP_S3_URL;
  } else {
    return 'http://localhost:2000';
  }
}

export function getUrlnameJava() {
  if ( process.env.REACT_APP_NODE_ENV === 'production' ) {
    return process.env.REACT_APP_BASE_API_URL_ELBIL;
  } else if ( process.env.REACT_APP_NODE_ENV === 'stage' ) {
    return process.env.REACT_APP_STAGE_API_URL_BEANSTALK;
  } else {
    return process.env.REACT_APP_NODE_ENV.REACT_APP_BASE_API_URL_JAVA;
  } 
}
