import React from 'react';
import './main-container.scss';
import SubmitButton from '../../components/buttons/submitButton';
import WithLoading from '../../components/withLoading';
import { Link } from 'react-router-dom';
import MainFilterContainer from '../../components/mainFilterContainer';
import WarningIcon from '@material-ui/icons/Warning';

class MainContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilterCategories: [],
      filtersForQuery: [],
      activeBrand: [],
    };
  }

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ 'event': 'VirtualPageview', 'virtualPageURL': '/elbilvelgeren/velgKriterier', 'virtualPageTitle': 'Elbilvelgeren | Velg Kriterier' });
    window.scrollTo(0, 0);
    localStorage.removeItem('filtersForQuery');
    localStorage.removeItem('activeBrand');
  }

  handleActiveFiltersChanged = (
    newActiveFilters,
    filtersForQuery,
    activeBrand
  ) => {
    this.setState({
      activeFilterCategories: newActiveFilters,
      filtersForQuery: filtersForQuery,
      activeBrand: activeBrand,
    });
  };

  render() {
    return (
      <div>
        <div className="main-intro">
          <div className="onboarding-box all-radius pc">
            <ONBOARDINGTEXT1 />
            <ONBOARDINGTEXT2 />
            <ONBOARDINGTEXT3 />
          </div>
          <div className="onboarding-box all-radius mobile">
            <ONBOARDINGTEXTMOBILE />
          </div>
        </div>
        <MainFilterContainerWithLoading
          handleActiveFiltersChanged={this.handleActiveFiltersChanged}
          activeFilterCategories={this.state.activeFilterCategories}
          filtersForQuery={this.state.filtersForQuery}
        />
        <p>{this.state.value}</p>
        <div className="submit-button-container">
          <Link
            tabIndex="-1"
            to={{
              pathname: '/results',
              state: {
                filtersForQuery: this.state.filtersForQuery,
                activeFilterCategories: this.state.activeFilterCategories,
                activeBrand: this.state.activeBrand,
              },
            }}
          >
            <SubmitButton className="zoom-special" text="Foreslå biler" />
          </Link>
        </div>
      </div>
    );
  }
}

function ONBOARDINGTEXT1(props) {
  return (
    <p>
      Med <strong>Elbil</strong>velgeren kan du finne aktuelle biler for deg.
    </p>
  );
}

function ONBOARDINGTEXT2(props) {
  return (
    <p>
      Alt du trenger å gjøre er å velge filtrene du vil sortere etter, deretter
      trykker du på knappen for å foreslå biler.
    </p>
  );
}

function ONBOARDINGTEXT3(props) {
  return (
    <>
      <br />
      <WarningIcon
        size="small"
        style={{ display: 'inline', color: '#ec6723' }}
      />
      <p style={{ display: 'inline' }}>
        &nbsp; Informasjonen på bilene er basert på offisiell data, data fra
        bilmerkenes nettsider og egne tester.
      </p>
    </>
  );
}

function ONBOARDINGTEXTMOBILE(props) {
  return (
    <>
      <p>
        Med vår <strong>Elbil</strong>velger så kan du finne aktuelle biler for
        deg. Velg kriterier og trykk på knappen for å finne biler.
      </p>

      <br />
      <WarningIcon
        size="small"
        style={{ display: 'inline-block', color: '#ec6723' }}
      />
      <p style={{ display: 'inline-block', fontSize: 'smaller' }}>
        &nbsp; Informasjonen på bilene er basert på offisiell statistikk og egne
        tester, og kan variere noe fra virkeligheten.
      </p>
    </>
  );
}

const MainFilterContainerWithLoading = WithLoading(MainFilterContainer);

export default MainContainer;
