// This service contains functions that you can use to connect to the API (for now created in Node).
// Function names should be self explanatory.

import * as baseService from './BaseService';

export function getFilters() {
  return baseService.get('/filters');
}

export function getFilterIcons() {
  return baseService.get('/filtericons');
}

export async function getOfvFilters() {
  let queries = {
    user: {
      username: 'remote_elbil',
      password: '1809ed1b8fe34cf84b7983ecb84ebca1',
      ipAddress: '',
    },
    filter: 'filter',
    vehicleGroup: '101',
  };
  return baseService.postFullUrl(
    'https://test-remote.kjoretoydata.no/RemoteCustomer/GetAvailableFilters?format=json',
    queries
  );
}
