import React from 'react';
import {
  FormControlLabel,
  Radio,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ec6723',
    },
  },
});

function FilterRadio(props) {
  let isChecked;
  isChecked = props.default;

  const handleRadioClick = e => {
    props.onChange(e, props.filter.id);
    isChecked = !e.target.checked;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Radio
            color="primary"
            checked={isChecked}
            onClick={e => handleRadioClick(e)}
            onKeyPress={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleRadioClick(e);
              }
            }}
            value={props.value}
            name={props.value}
          />
        }
        //onClick={e => handleRadioClick(e)}
        label={props.value}
      />
    </MuiThemeProvider>
  );
}
export default FilterRadio;
