import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import '../../pages/adminPage/adminPage.scss';

class TestForm extends React.Component {
  constructor(props) {
    super(props);
    if (props.chosenTest) {
      this.state = {
        submitted: false,
        deleted: false,
        test: props.chosenTest,
      };
    } else {
      this.state = {
        submitted: false,
        test: {
          test_url: '',
          preview_header: '',
          preview_image_url: '',
          preview_text: '',
        },
      };
    }
  }
  myChangeHandler = (event) => {
    const test = { ...this.state.test };
    test[event.target.name] = event.target.value;
    this.setState({ submitted: false, test: test });
  };

  create = async (e, carId, test) => {
    e.preventDefault();
    let res = await this.props.createClick(carId, test);
    this.setState({
      submitted: true,
    });
    if (res) {
      this.clearForm();
    }
  };
  clearForm = () => {
    this.setState({
      submitted: false,
      test: {
        test_url: '',
        preview_header: '',
        preview_image_url: '',
        preview_text: '',
      },
    });
  };

  update = async (e, carId, test) => {
    e.preventDefault();
    this.props.updateClick(carId, test);
    this.setState({
      submitted: true,
    });
  };

  delete = async (carId, testId) => {
    this.props.deleteClick(carId, testId);
    this.setState({
      deleted: true,
    });
  };

  render() {
    if (!this.state.deleted) {
      return (
        <div className="form-container all-radius">
          <form
            onSubmit={(e) =>
              !this.props.chosenTest
                ? this.create(
                    e,
                    this.props.selectedCarObject.car.car_id,
                    this.state.test
                  )
                : this.update(
                    e,
                    this.props.selectedCarObject.car.car_id,
                    this.state.test
                  )
            }
          >
            <div>
              <TextField
                className="textfield"
                name="test_url"
                label="Link til test"
                onChange={this.myChangeHandler}
                value={this.state.test.test_url}
                autoComplete="off"
                multiline={true}
              />
            </div>
            <div>
              <TextField
                className="textfield"
                name="preview_image_url"
                label="Forhåndsvisning bilde-URL"
                onChange={this.myChangeHandler}
                value={this.state.test.preview_image_url}
                autoComplete="off"
                multiline={true}
              />
            </div>
            <div>
              <TextField
                className="textfield"
                name="preview_header"
                label="Forhåndsvisning overskrift"
                onChange={this.myChangeHandler}
                value={this.state.test.preview_header}
                autoComplete="off"
                multiline={true}
              />
            </div>
            <div>
              <TextField
                className="textfield"
                name="preview_text"
                label="Forhåndsvisning tekst"
                onChange={this.myChangeHandler}
                value={this.state.test.preview_text}
                autoComplete="off"
                multiline={true}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={this.state.submitted}
            >
              {this.props.chosenTest ? 'Lagre' : 'Opprett ny test'}
            </Button>
            {this.state.submitted && <CheckCircle />}
            {this.props.chosenTest && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  if (
                    window.confirm(
                      'Er du sikker på at du vil slette denne testen?'
                    )
                  )
                    this.delete(
                      this.props.selectedCarObject.car.car_id,
                      this.state.test.id
                    );
                }}
              >
                Slett
              </Button>
            )}
          </form>
        </div>
      );
    }
    return (
      <div className="form-container all-radius">
        <CheckCircle />
        <p>Slettet</p>
      </div>
    );
  }
}

export default TestForm;
