import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import './detailsDisclaimerHover.scss';
import { isMobile } from 'react-device-detect';
import { IDS_FOR_DISCLAIMER } from '../../../constants';

function DetailsDisclaimerHover(props) {
  const correctId = IDS_FOR_DISCLAIMER.some(
    (item) => props.category_id === item
  );

  const hovertext = (category_id) => {
    switch (category_id) {
      case 604:
        return 'Biler kan ha varierende dekkdimensjoner. Vi har basert oss på standard dimensjonen for denne modellen.';
      case 605:
        return 'Biler kan ha varierende dekkdimensjoner. Vi har basert oss på standard dimensjonen for denne modellen.';
      case 606:
        return 'Biler kan ha varierende felgdimensjoner. Vi har basert oss på standard dimensjonen for denne modellen.';
      case 607:
        return 'Biler kan ha varierende felgdimensjoner. Vi har basert oss på standard dimensjonen for denne modellen.';
      case 703:
        return 'Noen biler kommer med forskjellige sete-oppsett. Vi baserer oss på 5 seter der tilgjengelig.';
      case 1206:
        return 'Ladetid varierer ut ifra ladeeffekten du har tilgjengelig. Våre data er basert på det maksimale denne modellen kan yte.';
      case 1207:
        return 'Ladetid varierer ut ifra ladeeffekten du har tilgjengelig. Våre data er basert på det maksimale denne modellen kan yte, og at bilen lades fra 0-80%.';
      case 1215:
        return 'Dette punktet er utelukkende offisielle apper,  og tar ikke hensyn til hva tredjeparter har laget';
      case 1216:
        return 'Dette punktet er utelukkende offisielle apper,  og tar ikke hensyn til hva tredjeparter har laget';
      default:
        return 'Verdier kan variere utifra virkeligheten';
    }
  };

  if (correctId) {
    return (
      <td className="tooltip">
        <ErrorOutlineIcon
          style={
            isMobile
              ? { fontSize: '18px', color: '#ec6723' }
              : { fontSize: '24px', color: '#ec6723' }
          }
        />
        <span className={isMobile ? 'tooltiptextMobile' : 'tooltiptext'}>
          {hovertext(props.category_id)}
        </span>
      </td>
    );
  } else {
    return null;
  }
}
export default DetailsDisclaimerHover;
