import React from 'react';
import '../../pages/adminPage/adminPage.scss';
import BatteryForm from './batteryForm';

class BatteryManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCarObject: this.props.selectedCarObject,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCarObject !== this.props.selectedCarObject) {
      this.setState({ selectedCarObject: this.props.selectedCarObject });
    }
  }

  render() {
    return (
      <div>
        <BatteryForm
          selectedCarObject={this.state.selectedCarObject}
          batteryData={this.props.batteryData}
        />
      </div>
    );
  }
}

export default BatteryManager;
