import React from 'react';
import '../carSummaryList/carSummaryItem.scss';
import { Grid, Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

function ArticleAndTest(props) {
  if (props.hasValue) {
    return (
      <>
        {isMobile ? (
          <>
            <div>
              <img
                className="img-articleAndTest all-radius"
                style={{ verticalAlign: 'center' }}
                alt="complex"
                src={props.value.preview_image_url}
              />
            </div>
            <div>
              <Typography
                gutterBottom
                variant="h6"
                style={{ paddingTop: '1em' }}
              >
                {props.value.preview_header}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {props.value.preview_text}
              </Typography>
              <Typography gutterBottom>
                <a
                  href={
                    props.type === 'article'
                      ? props.value.article_url
                      : props.value.test_url
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <u>
                    {props.type === 'article'
                      ? 'Les hele artikkelen her!'
                      : 'Les hele testen her!'}
                  </u>
                </a>
              </Typography>
            </div>
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid
                item
                className="imageBox-articleAndTest"
                style={{ margin: 'auto 0' }}
              >
                <img
                  className="img-articleAndTest all-radius"
                  alt="complex"
                  src={props.value.preview_image_url}
                />
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="h6">
                      {props.value.preview_header}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {props.value.preview_text}
                    </Typography>
                    <Typography gutterBottom>
                      <a
                        href={
                          props.type === 'article'
                            ? props.value.article_url
                            : props.value.test_url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>
                          {props.type === 'article'
                            ? 'Les hele artikkelen her!'
                            : 'Les hele testen her!'}
                        </u>
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  } else {
    return (
      <div>
        <h1 className="h1-standard-text">
          Vi har dessverre ikke fått vurdert og testet denne bilen.
        </h1>
      </div>
    );
  }
}
export default ArticleAndTest;
