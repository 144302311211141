import React from 'react';
import { Link } from 'react-router-dom';
import './carThumbnail.scss';
import PropTypes from 'prop-types';
import { getCarNameWithParent } from '../../../services/CarService';
import unknown from '../../../assets/ukjent.png';
import ParentCarThumbnailDetailTable from './parentCarThumbnailDetailTable';
import ChildCarThumbnailDetailTable from './childCarThumbnailDetailTable';
import { useState } from 'react';
import FlipCameraAndroidRoundedIcon from '@material-ui/icons/FlipCameraAndroidRounded';
import { getCarFullName } from '../../../services/CarService';
import CarThumbnailDetailTable from './carThumbnailDetailTable';

function StreamView(props) {
  
  const [state, setState] = useState(true);
  const handleClick = () => setState(!state);
  
  const showParent = () => {
    if (props.car.length < 2)
      return (
        <Link
          to={{
            pathname: '/cars/' + props.car[0].car_id,
          }}
        >
          <div className="darkbluebackground thumbnail-list-style-square">
            <img
              alt=""
              src={props.car[0].main_img_url !== null ? props.car[0].main_img_url : unknown}
              className="img-car img-hover"
            />
            <div className="whitebackground">
              <h3 className="h3-car">{getCarFullName(props.car[0])}</h3>
              <div className="table-view">
                <CarThumbnailDetailTable car={props.car[0]} />
              </div>
            </div>
          </div>
        </Link>
      );
    //Implicit ELSE
    return (
      <div
        className="darkbluebackground thumbnail-list-style-square parent-animation"
        style={{cursor: "pointer"}}
        onClick={handleClick}
      >
        <FlipCameraAndroidRoundedIcon
          className="flip-round-icon"
          style={{ color: '#d3d8d5' }}
          />
        <img
          alt = ""
          src={props.car[0].main_img_url !== null ? props.car[0].main_img_url : unknown}
          className="img-car img-hover"
        />
        <div className="whitebackground">
          <h3 className="h3-car">{getCarNameWithParent(props.car[0])}</h3>
          <div className="table-view ">
            <ParentCarThumbnailDetailTable car={props.car} />
          </div>
        </div>
      </div>
    );
  };

  const showChild = () => {
    return (
      <div
        className="darkbluebackground thumbnail-list-style-square child-animation"
        style={{ paddingTop: "0px" , minHeight: "200px"}}
        >
        <FlipCameraAndroidRoundedIcon 
          className="flip-round-icon"
          onClick={handleClick}
        />
        <div className="whitebackground">
          <h3
            className="h3-car"
            style={{ padding: "25px", paddingBottom: "0px" }}
            >
            {getCarNameWithParent(props.car[0])}
          </h3>
          <div 
            className="table-view " 
            style={{ maxHeight: "300px", overflow: "hidden" }}
          >
            <ChildCarThumbnailDetailTable car={props.car} />
          </div>
        </div>
      </div>
    );
  };
  
  return (
    state ? showParent() : showChild()
  );
}

StreamView.propTypes = {
  car: PropTypes.array.isRequired,
};

export default StreamView;
