import React from 'react';
import CarResultsList from './carResultsList';
import * as carService from '../../services/CarService';
import WithLoading from '../../components/withLoading';
import SubmitButton from '../../components/buttons/submitButton';
import MainFilterContainer from '../../components/mainFilterContainer';
import ExpansionPanel from '../../components/expansionPanel';
import WhiteShell from '../../components/containers/whiteshell';
import ViewAlternativesDisplay from '../../components/carResultsPage/viewAlternativesDisplay/viewAlternativesDisplay';
import { createUpdateGoogleTagData } from './googleTag';
import { createSubmitGoogleTagData } from './googleTag';
import './carResults.scss';
import { isMobile } from 'react-device-detect';
import {
  SORT_OPTIONS,
  sortCarsByPreference,
  FILTER_QUERY_ALTERNATIVE_VALUE_MAP,
  FILTERS_THAT_NEED_MAPPED_VARIABLE_NAME,
} from '../../constants';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { Share, RotateLeft, Chat, Announcement } from '@material-ui/icons';
import searchIcon from '../../assets/searchicon.svg';
import WarningIcon from '@material-ui/icons/Warning';

const themeSort = createMuiTheme({
  palette: {
    primary: {
      main: '#ec6723',
    },
  },
});

class CarResultsPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      carResults: [],
      isLoading: true,
      activeFilterCategories:
        props.location.state && props.location.state.activeFilterCategories
          ? props.location.state.activeFilterCategories
          : [],
      thumbnailStyle: isMobile ? 'stream' : 'column',
      filtersForQuery:
        props.location.state && props.location.state.filtersForQuery
          ? props.location.state.filtersForQuery
          : [],
      activeBrand:
        props.location.state && props.location.state.activeBrand
          ? props.location.state.activeBrand
          : [],
      queries: props.location.state ? props.location.state.queries : {},
      chosenSortProperty: SORT_OPTIONS[0],
      fullQuery: '',
      copySuccess: '',
      filterExpantion: false,
      gottenFromUrl: false,
      queryUpdated: false,
      prevQuery: [],
    };
    this.handleChangeSort = this.handleChangeSort.bind(this);
  }

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ 'event': 'VirtualPageview', 'virtualPageURL': '/elbilvelgeren/velgBil', 'virutalPageTitle': 'Elbilvelgeren | Velg Bil' });
    dataLayer.push(createSubmitGoogleTagData(this.state.filtersForQuery));

    window.scrollTo(0, 0);
    this._isMounted = true;
    if (
      this._isMounted &&
      localStorage.getItem('filtersForQuery') &&
      localStorage.getItem('activeBrand')
    ) {
      const rehydrateFilters = JSON.parse(
        localStorage.getItem('filtersForQuery')
      );
      const rehydrateBrand = JSON.parse(localStorage.getItem('activeBrand'));
      this.setState(
        {
          filtersForQuery: rehydrateFilters,
          activeBrand: rehydrateBrand,
        },
        () => {
          this.getData(this.state.filtersForQuery);
        },
        () => {
          this.getData(this.state.activeBrand);
        }
      );
    } else {
      this.getData(this.state.filtersForQuery);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    localStorage.setItem(
      'filtersForQuery',
      JSON.stringify(this.state.filtersForQuery)
    );
    localStorage.setItem('activeBrand', JSON.stringify(this.state.activeBrand));
  }

  submitNewFilters = () => {
    const dataLayer = window.dataLayer || [];
    const tagData = createUpdateGoogleTagData(this.state.filtersForQuery, this.state.prevQuery);
    if (tagData) {
      dataLayer.push(tagData);
    }
    this.getData(this.state.filtersForQuery);
  };

  async getData(queryFilters) {
    let queryString = this.createQuery(queryFilters);

    let carResults = [];
    if (this.props.match.params.query && !this.state.gottenFromUrl) {
      this.setState({ gottenFromUrl: true });
      carResults = await this.getCarsFromQueries(this.props.match.params.query);
    } else {
      if (queryString === '') {
        carResults = await this.getCars();
      } else {
        carResults = await this.getCarsFromQueries(queryString);
      }
    }

    await this.setState({
      carResults: carResults,
      isLoading: false,
      fullQuery: queryString,
      copySuccess: '',
      queryUpdated: false,
      prevQuery:
        this.state.filtersForQuery.length === 0
          ? []
          : this.state.filtersForQuery,
    });
  }

  getCars = async () => {
    let carResults = await carService.getCars();
    if (!carResults) {
      carResults = [];
    }
    carResults = sortCarsByPreference(
      carResults,
      this.state.chosenSortProperty
    );

    const carResultsVisibleToUsers = carResults.filter(
      (car) => car.show_to_user !== false
    );
    return carResultsVisibleToUsers;
  };

  getCarsFromQueries = async (queries) => {
    let relevantCars = await carService.getCarsFromQueries(queries);
    if (relevantCars && !(relevantCars instanceof Error)) {
      try {
        relevantCars = relevantCars.filter((e) => e.show_to_user > 0);
      } catch (err) {
        relevantCars = [];
      }
    }
    let carResults = sortCarsByPreference(
      relevantCars,
      this.state.chosenSortProperty
    );
    return carResults;
  };

  changeThumbnailStyle = (type) => {
    this.setState({
      thumbnailStyle: type,
    });
  };

  handleActiveFiltersChanged = (
    activeFilterCategories,
    filtersForQuery,
    activeBrand
  ) => {
    this.setState({
      activeFilterCategories: activeFilterCategories,
      filtersForQuery: filtersForQuery,
      activeBrand: activeBrand,
      queryUpdated: this.compareFilter(filtersForQuery),
    });
  };

  compareFilter = (filtersForQuery) => {
    let filterIsUpdated = false;

    if (filtersForQuery.length !== this.state.prevQuery.length) {
      filterIsUpdated = true;
    }

    if (
      filtersForQuery.length !== 0 &&
      filtersForQuery.length === this.state.prevQuery.length
    ) {
      filtersForQuery.forEach((filterCategoryItem, filterItemIndex) => {
        if (
          this.state.prevQuery[filterItemIndex].alternatives.length !==
          filterCategoryItem.alternatives.length
        ) {
          filterIsUpdated = true;
        } else {
          filterCategoryItem.alternatives.forEach((item) => {
            if (
              !this.state.prevQuery[filterItemIndex].alternatives.includes(item)
            ) {
              filterIsUpdated = true;
            } else {
              filterIsUpdated = true;
            }
          });
        }
      });
    }
    return filterIsUpdated;
  };

  handleChangeSort = (event) => {
    let sortedList = sortCarsByPreference(
      this.state.carResults,
      event.target.value
    );
    /*If condition is true it means user wants sorting according to Range, and we should further sort
    the range here according to 'wltp > nedc > summer > winter' preference.*/
    if (event.target.value.property === 'range_wltp') {
      this.sortNedcRange(sortedList, event.target.value);
    }

    this.setState({
      carResults: sortedList,
      chosenSortProperty: event.target.value,
    });
  };

  sortNedcRange(sortedList, eventTargetValue) {
    //Sorting for Descending (Higher to Lower) Order
    sortedList.sort((i, j) => {
      if (i.range_wltp !== null) return 0;
      if (j.range_wltp !== null) return 0;

      //At this point WLTP values already are sorted, and further in the list WLTP valuse will be null.
      if (i.range_nedc > j.range_nedc) return -1;
      if (i.range_nedc < j.range_nedc) return 1;

      if (i.range_nedc !== null) return 0;
      if (j.range_nedc !== null) return 0;

      //At this point NEDC values are sorted, and further in the list NEDC valuse are null.
      if (i.range_summer > j.range_summer) return -1;
      if (i.range_summer < j.range_summer) return 1;

      if (i.range_summer !== null) return 0;
      if (j.range_summer !== null) return 0;

      //At this point SUMMER values are already sorted, and further in the list SUMMER valuse are null.
      if (i.range_winter > j.range_winter) return -1;
      if (i.range_winter < j.range_winter) return 1;

      return 0;
    });

    //Sorting again only for Ascending (Lower to Higher) Order
    if (eventTargetValue.order === 1) {
      sortedList.sort((i, j) => {
        if (i.range_wltp !== null) return 0;
        if (j.range_wltp !== null) return 0;

        if (i.range_nedc !== null && i.range_nedc >= j.range_nedc) return 1;
        if (i.range_nedc !== null && i.range_nedc < j.range_nedc) return -1;

        //NEDC range has been already sorted above.
        if (j.range_nedc !== null) return 0;

        if (i.range_summer !== null && i.range_summer >= j.range_summer)
          return 1;
        if (i.range_summer !== null && i.range_summer < j.range_summer)
          return -1;

        //SUMMER range has been already sorted above.
        if (i.range_summer !== null) return 0;
        if (j.range_summer !== null) return 0;

        if (i.range_winter >= j.range_winter) return 1;
        if (i.range_winter < j.range_winter) return -1;

        return 0;
      });
    }
  }

  createQuery(rawQuery) {
    let fullQuery = '';
    if (rawQuery.length > 0) {
      rawQuery.forEach((filter, index) => {
        let substring = '';
        if (index > 0) {
          substring += '&';
        }
        if (filter.type === 'slider') {
          if (filter.alternatives[0] !== +filter.defaultMinMaxAlternatives[0]) {
            substring +=
              'from' + filter.variable_name + '=' + filter.alternatives[0];
          }
          if (filter.alternatives[1] !== +filter.defaultMinMaxAlternatives[1]) {
            if (
              filter.alternatives[0] !== +filter.defaultMinMaxAlternatives[0]
            ) {
              substring += '&';
            }
            substring +=
              'to' + filter.variable_name + '=' + filter.alternatives[1];
          }
        } else {
          if (
            FILTERS_THAT_NEED_MAPPED_VARIABLE_NAME.includes(
              filter.variable_name
            )
          ) {
            // Tire size filter contains the string " tommer", which we don't need for querystring
            if (filter.variable_name === 'tireSize') {
              substring +=
                filter.variable_name +
                '=' +
                filter.alternatives
                  .map((alt) => alt.replace(' tommer', ''))
                  .toString();
            } else {
              let alternatives = filter.alternatives
                .map((alt) => {
                  return FILTER_QUERY_ALTERNATIVE_VALUE_MAP[alt];
                })
                .toString();
              substring += filter.variable_name + '=' + alternatives;
            }
          } else {
            substring +=
              filter.variable_name + '=' + filter.alternatives.toString();
          }
        }
        fullQuery += substring;
      });
    }
    this.setState({
      fullQuery: fullQuery,
    });
    return fullQuery;
  }

  copyToClipboard = (e) => {
    let query = this.getCarsUrlNameJava() + this.state.fullQuery;
    navigator.clipboard
      .writeText(query)
      .then(() => {
        navigator.clipboard.writeText(query);
        this.setState({ copySuccess: 'Kopiert!' });
      })
      .catch((err) => {
        // This can happen if the user denies clipboard permissions:
        console.error('Could not copy text: ', err);
      });
  };

  getCarsUrlNameJava = () => {
    return process.env.REACT_APP_PRODUCTION_URL + '/results/';
  };

  resetFilters = () => {
    this.setState(
      {
        activeFilterCategories: [],
        filtersForQuery: [],
        activeBrand: [],
        filterExpantion: !this.state.filterExpantion,
        queryUpdated: false,
      },
      () => {
        this.submitNewFilters();
      }
    );
  };

  render() {
    const isUpdated = this.state.queryUpdated;
    let showUpdatedButton;
    if (isUpdated) {
      showUpdatedButton = (
        <div className="submit-button-container submit-button-container-animation">
          <SubmitButton
            style={{ backgroundColor: '#ec6723', color: '#fff' }}
            className="zoom-special"
            text="Oppdater forslagene"
            handleSubmitClick={this.submitNewFilters}
          />
        </div>
      );
    }
    return (
      <section>
        <section>
          <section className="iconContainer">
            <>
              <Tooltip title="Nullstill filtre" aria-label="reset">
                <RotateLeft
                  style={{
                    fontSize: '30px',
                    cursor: 'pointer',
                    color: 'white',
                  }}
                  onClick={() => {
                    this.resetFilters();
                  }}
                ></RotateLeft>
              </Tooltip>
            </>
            {this.state.fullQuery && this.state.fullQuery !== '' && (
              <>
                <Tooltip title="Kopier link til listen" aria-label="copy">
                  <Share
                    style={{
                      fontSize: '30px',
                      cursor: 'pointer',
                      color: 'white',
                    }}
                    onClick={() => {
                      this.copyToClipboard();
                    }}
                  />
                </Tooltip>
                <p>{this.state.copySuccess}</p>
              </>
            )}
            <>
              <Tooltip title="Feedback" aria-label="feedback">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=B7KJXOAwYkSNTKILASRaX8eK1tgvCx5Dv7DazbCLOhVUMUE4VDBHTjQ5MFE3R0dMVFpZMVdWVjNWTC4u"
                >
                  <Announcement
                    style={{
                      fontSize: '30px',
                      cursor: 'pointer',
                      color: 'white',
                    }}
                  ></Announcement>
                </a>
              </Tooltip>
            </>
            <>
              <Tooltip title="Ta kontakt" aria-label="contact">
                <a
                  target="_top"
                  rel="noopener noreferrer"
                  href="mailto:elbilvelgeren-kontakt@elbil.no"
                >
                  <Chat
                    style={{
                      fontSize: '30px',
                      cursor: 'pointer',
                      color: 'white',
                    }}
                  ></Chat>
                </a>
              </Tooltip>
            </>
          </section>

          <section className="customize-section">
            <ExpansionPanel
              expanded={this.state.filterExpantion}
              initiallyActive={false}
              title="Valg"
            >
              <div className="filterExpantionContent">
                <MainFilterContainer
                  handleActiveFiltersChanged={this.handleActiveFiltersChanged}
                  activeFilterCategories={this.state.activeFilterCategories}
                  filtersForQuery={this.state.filtersForQuery}
                  activeBrand={this.state.activeBrand}
                />
                {isUpdated ? (
                  <div
                    className="submit-button-container-animation"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '10px 0px',
                    }}
                  >
                    <WarningIcon
                      size="large"
                      style={{ color: '#fff', padding: '0px 10px' }}
                    />
                    <p style={{ color: '#fff' }}>
                      Du har justert filtret, men ikke oppdatert forslagene
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {showUpdatedButton}
              </div>
            </ExpansionPanel>
          </section>

          <section className="customize-section">
            <ExpansionPanel initiallyActive={false} title="Visning">
              <div className="viewMobile">
                <WhiteShell id="my-whiteshell">
                  <div className="search-selector">
                    <FormControl className="formcontrol">
                      <MuiThemeProvider theme={themeSort}>
                        <InputLabel
                          id="resultsSort-simple-label"
                          color="primary"
                        >
                          Resultatene sorteres etter
                        </InputLabel>
                        <Select
                          labelId="resultsSort-simple-label"
                          value={this.state.chosenSortProperty}
                          onChange={this.handleChangeSort}
                          color="primary"
                        >
                          {SORT_OPTIONS.map((option, index) => {
                            return (
                              <MenuItem value={option} key={index}>
                                {option.display_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </MuiThemeProvider>
                    </FormControl>
                  </div>
                  <ViewAlternativesDisplay
                    handleClick={this.changeThumbnailStyle}
                    thumbnailStyle={this.state.thumbnailStyle}
                  />
                </WhiteShell>
              </div>
            </ExpansionPanel>
          </section>
          {!this.state.isLoading && !this.state.carResults && (
            <div className="no-results">
              <p>Vi fant dessverre ingen biler med disse kriteriene</p>
            </div>
          )}
          {!this.state.isLoading && this.state.carResults.length === 0 && (
            <div className="no-results">
              <img src={searchIcon} alt="noResults" />
              <p>Vi fant dessverre ingen biler med disse kriteriene</p>
            </div>
          )}
          {this.state.carResults.length > 0 && (
            <CarResultsListWithLoading
              isLoading={this.state.isLoading}
              carResults={this.state.carResults}
              thumbnailStyle={this.state.thumbnailStyle}
            ></CarResultsListWithLoading>
          )}
        </section>
      </section>
    );
  }
}

const CarResultsListWithLoading = WithLoading(CarResultsList);

export default CarResultsPage;
