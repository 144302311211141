import React from 'react';
import {
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Fab,
  Switch
} from '@material-ui/core';
import { Add, Delete, CheckCircle } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import {
  CAR_STATUS_ALTERNATIVES,
  CAR_CHASSIS_ALTERNATIVES,
  CAR_BRANDS,
} from '../../constants';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import * as carService from '../../services/CarService';

class CarBasicInfoManager extends React.Component {
  constructor(props) {
    super(props);
    let car_state;

    if (props.selectedCarObject) {
      car_state = props.selectedCarObject.car;
    } else {
      car_state = {
        basic_model_price_nok: '',
        brand_id: '',
        brand_name: '',
        brand_url: '',
        car_id: '',
        car_status: CAR_STATUS_ALTERNATIVES[0].value,
        chassis_name: CAR_CHASSIS_ALTERNATIVES[0],
        gear_type_name: 'Automat',
        image_urls: [],
        main_img_url: null,
        model_edition_name: '',
        model_id: '',
        model_name: '',
        ncap_data_img_url: '',
        //premium_model_price_nok: '',
        release_year: '',
        show_to_user: true,
        is_locked: false,
      };
    }
    this.state = {
      car: car_state,
      show_to_user: false,
      is_locked: false,
      registeredBrands: [],
      current_image_urls: [],
      writing_custom_brand: false,
      car_has_been_created: false,
      car_has_been_updated: false,
      car_update_status_code_ct: 0,
      feedback_message: '',
      feedback_message_ct: '',
    };
  }

  componentDidMount() {
    let current_image_urls = [];
    if (this.state.car.image_urls && this.state.car.image_urls.length > 0) {
      current_image_urls = this.state.car.image_urls.split(';');
    }
    this.setState({
      current_image_urls: current_image_urls,
      car_has_been_created: false,
    });
    this.getData();
  }

  getData = async () => {
    await this.getBrands();
  };

  getBrands = async () => {
    // let brands = await carService.getBrandsRegistered();
    // brands = brands.map(brand => Object.values(brand).toString());
    let brands = CAR_BRANDS;
    this.setState({
      registeredBrands: brands.sort(),
    });
  };

  myChangeHandler = (event) => {
    const car = { ...this.state.car };
    if (event.target.name === 'brand_name' && event.target.value === 'other') {
      this.setState({ writing_custom_brand: true });
      return;
    }
    if (event.target.name === 'show_to_user') {
      car['show_to_user'] = event.target.checked;
      this.setState({ car });
      return;
    }
    if (event.target.name === 'is_locked') {
      car['is_locked'] = event.target.checked;
      this.setState({ car });
      return;
    }

    car[event.target.name] = event.target.value;
    this.setState({ car });
  };

  handleImageUrlChange = (id) => (evt) => {
    const newImageUrls = this.state.current_image_urls.map((image, sidx) => {
      if (id !== sidx) return image;
      return evt.target.value;
    });

    this.setState({ current_image_urls: newImageUrls });
  };

  handleAddImageUrl = () => {
    this.setState({
      current_image_urls: this.state.current_image_urls.concat(['']),
    });
  };

  handleRemoveImageUrl = (id) => {
    this.setState({
      current_image_urls: this.state.current_image_urls.filter(
        (s, sidx) => id !== sidx
      ),
    });
  };

  getUrlname = () => {
    return process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PRODUCTION_URL
      : 'http://localhost:3000';
  };

  create = async (event) => {
    event.preventDefault();
    const car = { ...this.state.car };
    car['image_urls'] = this.state.current_image_urls.join(';');

    await carService.createCar(car);
    this.setState({
      car_has_been_created: true,
      feedback_message: 'Bil er nå lagt til',
    });
  };

  update = async (event) => {
    event.preventDefault();
    const car = { ...this.state.car };
    car['image_urls'] = this.state.current_image_urls.join(';');

    let result = await carService.updateCar(car);
    if (result instanceof Error) {
      this.setState({
        feedback_message: result,
      });
      return;
    } else {
      this.setState({
        car: result,
        car_has_been_updated: true,
        feedback_message: 'Bil er oppdatert.',
      });
    }
  };

  alertMessage = (statusCode) => {
    if (statusCode !== 0) {
      return <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Alert severity={statusCode === 200 ? "success" : "error"}>
          {this.state.feedback_message_ct}
        </Alert>
      </div>
    }
  }

  handleUpdateCarClick = async () => {
    const car = { ...this.state.car };
    let response = await carService.updateCarWithCtData(car.car_id);

    if (response.status === 200) {
      this.setState({
        car_update_status_code_ct: 200,
        feedback_message_ct: 'Bil er oppdatert med data fra Charge trip. Vennligst refresh siden.'
      });
    } else {
      let data = await response.json();
      this.setState({
        car_update_status_code_ct: data.status,
        feedback_message_ct: "Failed to fetch data. " + data.message
      });
    }
  }

  render() {
    if (this.state.car_has_been_created) {
      return (
        <div className="tabitem form-container all-radius">
          <CheckCircle /> {this.state.feedback_message}
        </div>
      );
    }
    return (
      <div className="tabitem form-container all-radius">
        <p>{this.state.feedback_message}</p>
        <form
          onSubmit={(e) =>
            !this.props.selectedCarObject ? this.create(e) : this.update(e)
          }
        >
          <div className="mini-form-container">
            <FormControl>
              <TextField
                className="textfield"
                name="basic_model_price_nok"
                label="Pris basismodell"
                type="number"
                onChange={this.myChangeHandler}
                value={this.state.car.basic_model_price_nok}
                autoComplete="off"
              />
            </FormControl>
            {/* <FormControl>
              <TextField
                className="textfield"
                name="premium_model_price_nok"
                label="Pris premiummodell"
                type="number"
                onChange={this.myChangeHandler}
                value={this.state.car.premium_model_price_nok}
                autoComplete="off"
              />
            </FormControl> */}
            {!this.state.writing_custom_brand && (
              <FormControl>
                <InputLabel id="brand">Merke</InputLabel>
                <Select
                  name="brand_name"
                  labelId="brand"
                  value={this.state.car.brand_name}
                  onChange={this.myChangeHandler}
                  className="selectfield"
                >
                  {this.state.registeredBrands.map((brand, index) => {
                    return (
                      <MenuItem value={brand} key={index}>
                        {brand}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value="other">- Skriv egen -</MenuItem>
                </Select>
              </FormControl>
            )}
            {this.state.writing_custom_brand && (
              <FormControl>
                <TextField
                  className="textfield"
                  name="brand_name"
                  label="Merke"
                  onChange={this.myChangeHandler}
                  value={this.state.car.brand_name}
                  autoComplete="off"
                  multiline={true}
                />
              </FormControl>
            )}

            <FormControl>
              <InputLabel id="car_status">Bilstatus</InputLabel>
              <Select
                name="car_status"
                labelId="car_status"
                value={this.state.car.car_status}
                onChange={this.myChangeHandler}
                className="selectfield"
              >
                {CAR_STATUS_ALTERNATIVES.map((status, index) => {
                  return (
                    <MenuItem value={status.value} key={index}>
                      {status.display_value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="chassis_name">Karosseriformat</InputLabel>
              <Select
                name="chassis_name"
                labelId="chassis_name"
                onChange={this.myChangeHandler}
                value={this.state.car.chassis_name}
                className="selectfield"
              >
                {CAR_CHASSIS_ALTERNATIVES.map((chassis, index) => {
                  return (
                    <MenuItem value={chassis} key={index}>
                      {chassis}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="gear_type">Girtype</InputLabel>
              <Select
                name="gear_type_name"
                labelId="gear_type"
                value={this.state.car.gear_type_name}
                onChange={this.myChangeHandler}
                className="selectfield"
              >
                <MenuItem value="Automat">Automat</MenuItem>
                <MenuItem value="Manuell">Manuell</MenuItem>
              </Select>
            </FormControl>
            <div>
              {this.state.current_image_urls.map((image, index) => {
                return (
                  <div className="image_url" key={index}>
                    <FormControl>
                      <TextField
                        className="textfield"
                        name="image_urls"
                        label={`Bilde-URL #${index + 1}`}
                        onChange={this.handleImageUrlChange(index)}
                        value={image}
                        autoComplete="off"
                        multiline={true}
                      />
                    </FormControl>
                    <Tooltip
                      title="Fjern bilde-URL"
                      aria-label="removeImageUrl"
                    >
                      <Fab
                        size="small"
                        type="button"
                        color="secondary"
                        onClick={() => this.handleRemoveImageUrl(index)}
                        className="small"
                      >
                        <Delete />
                      </Fab>
                    </Tooltip>
                  </div>
                );
              })}
              <Tooltip title="Legg til nytt bilde-URL" aria-label="addImageUrl">
                <Fab
                  size="small"
                  color="primary"
                  aria-label="add"
                  onClick={() => this.handleAddImageUrl()}
                >
                  <Add />
                </Fab>
              </Tooltip>
            </div>
            <FormControl>
              <TextField
                className="textfield"
                name="main_img_url"
                label="Thumbnail i resultatsiden"
                onChange={this.myChangeHandler}
                value={this.state.car.main_img_url}
                autoComplete="off"
                multiline={true}
              />
            </FormControl>
            <FormControl>
              <TextField
                className="textfield"
                name="model_name"
                label="Modell"
                onChange={this.myChangeHandler}
                value={this.state.car.model_name}
                autoComplete="off"
                multiline={true}
              />
            </FormControl>
            <FormControl style={{ display: 'block' }}>
              <TextField
                style={{ maxWidth: '305px' }}
                className="textfield"
                name="parent_car"
                label="Parent"
                onChange={this.myChangeHandler}
                value={this.state.car.parent_car}
                autoComplete="off"
                multiline={true}
              />
              <Tooltip title="Fjern Parent Bil" aria-label="removeParentCar">
                <Fab
                  size="small"
                  type="button"
                  color="secondary"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        'Er du sikker på at du fjerne informasjonen på dette feltet? Trykk på "LAGRE" etter at du har slettet.'
                      )
                    )
                      // eslint-disable-next-line react/no-direct-mutation-state
                      this.state.car.parent_car = '';
                  }}
                  className="small"
                >
                  <Delete />
                </Fab>
              </Tooltip>
            </FormControl>
            <FormControl>
              <TextField
                className="textfield"
                name="model_edition_name"
                label="Modellversjon"
                onChange={this.myChangeHandler}
                value={this.state.car.model_edition_name}
                autoComplete="off"
                multiline={true}
              />
            </FormControl>
            <FormControl>
              <TextField
                className="textfield"
                name="release_year"
                label="Utgivelsesår"
                type="number"
                onChange={this.myChangeHandler}
                value={this.state.car.release_year}
                autoComplete="off"
              />
            </FormControl>

            {/* <FormControl>
              <InputLabel id="segment_name">Bilklasse</InputLabel>
              <Select
                name="segment_name"
                labelId="segment_name"
                onChange={this.myChangeHandler}
                value={this.state.car.segment_name}
                defaultValue={CAR_SEGMENT_ALTERNATIVES[0]}
                className="selectfield"
              >
                {CAR_SEGMENT_ALTERNATIVES.map((segment, index) => {
                  return (
                    <MenuItem value={segment} key={index}>
                      {segment}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}

            <FormControlLabel
              control={
                <Checkbox
                  name="show_to_user"
                  checked={this.state.car.show_to_user}
                  onChange={this.myChangeHandler}
                  value={this.state.car.car_status}
                  inputProps={{ 'aria-label': 'Synlig for bruker' }}
                />
              }
              label="Synlig for bruker"
            />
            <FormControlLabel
              control={
                <Switch
                  name="is_locked"
                  checked={this.state.car.is_locked}
                  onChange={this.myChangeHandler}
                  value={this.state.car.is_locked}
                  inputProps={{ 'aria-label': 'Lock Car' }}
                />
              }
              label="Car Lock"
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={this.state.car.submitted}
            >
              {this.props.selectedCarObject ? 'Lagre' : 'Opprett bil'}
            </Button>
            {this.state.car_has_been_updated && (
              <div style={{widows: 'fit-content', display: 'flex', marginTop: '10px'}}>
                <Alert severity="success">
                  {this.state.feedback_message}
                </Alert>
              </div>
            )}
          </div>
        </form>
        {this.state.car.car_id && (
          <div style={{ marginTop: '1em', textAlign: 'center' }}>
            <small>
              <a
                style={{ color: '#3F51B4' }}
                target="_blank"
                rel="noopener noreferrer"
                href={this.getUrlname() + '/cars/' + this.state.car.car_id}
              >
                {this.getUrlname() + '/cars/' + this.state.car.car_id}
              </a>
            </small>
          </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<GetAppRoundedIcon />}
            onClick={this.handleUpdateCarClick}
          >
            Oppdater bil med ChargeTrip data
          </Button>
          {
            this.alertMessage(this.state.car_update_status_code_ct)
          }
        </div>
      </div>
    );
  }
}

export default CarBasicInfoManager;
