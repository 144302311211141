import React from 'react';
import './carResults.scss';
import WhiteDetailedCarThumbnail from '../../components/carResultsPage/carThumbnails/whiteDetailedCarThumbnail';
import StreamView from '../../components/carResultsPage/carThumbnails/streamView';
import ColumnView from '../../components/carResultsPage/carThumbnails/columnView';

class CarResultsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carResults: props.carResults
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.carResults !== this.props.carResults) {
      this.setState({ carResults: this.props.carResults });
    }
  }

  render() {
    var parent_result = []
    this.props.carResults.forEach((car) => {
      if (!parent_result[car.parent_car]) {
        parent_result[car.parent_car] = [];
      }
      parent_result[car.parent_car].push(car);
    });
    
    return (
      <div className="car-result-list">
        {
        Object.keys(parent_result)
        .sort((i,j) => ( (i.range_wltp !== null || i.range_nedc !== null || i.range_summer !== null || i.range_winter !== null) && (j.range_wltp === null && j.range_nedc === null && j.range_summer === null && j.range_winter === null) ) ? -1 : 1 )
        .map((carParentKey, index) => {
          return (
            <div key={index}>
              <div className="show-expandable">
                {this.props.thumbnailStyle === 'column' && (
                  <ColumnView key={parent_result[carParentKey][0].brand_name} car={parent_result[carParentKey]} />
                )}
              </div>
              
              <div className="show-two-wide">
                {this.props.thumbnailStyle === 'list' && (
                  <WhiteDetailedCarThumbnail key={parent_result[carParentKey][0].brand_name} car={parent_result[carParentKey]} />
                )}
              </div>

              <div className="show-expandable">
                {this.props.thumbnailStyle === 'stream' && (
                  <StreamView key={parent_result[carParentKey][0].brand_name} car={parent_result[carParentKey]} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default CarResultsList;
